import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'AverageDuration',
  viewBox: '0 0 112 112',
  path: (
    <>
      <path
        d="M95.9836 73.024C95.8716 73.024 95.6476 73.024 95.5356 72.912C94.9756 72.688 94.7516 72.016 94.9756 71.456C96.0956 68.88 96.9916 66.304 97.5516 63.616C97.6636 63.056 98.2236 62.608 98.8956 62.72C99.4556 62.832 99.9036 63.392 99.7916 64.064C99.2316 66.976 98.2236 69.776 96.9916 72.464C96.8796 72.8 96.4316 73.024 95.9836 73.024ZM99.4556 58.576C98.7836 58.576 98.3356 58.016 98.3356 57.456C98.3356 56.896 98.3356 56.336 98.3356 55.664C98.3356 53.424 98.1116 51.296 97.7756 49.168C97.6636 48.608 98.1116 47.936 98.6716 47.824C99.2316 47.712 99.9036 48.16 100.016 48.72C100.464 50.96 100.576 53.312 100.576 55.664C100.576 56.336 100.576 56.896 100.576 57.568C100.576 58.128 100.128 58.576 99.4556 58.576ZM97.3276 43.792C96.8796 43.792 96.4316 43.456 96.3196 43.008C95.4236 40.432 94.1916 37.856 92.7356 35.504C92.3996 34.944 92.6236 34.272 93.0716 33.936C93.6316 33.6 94.3036 33.824 94.6396 34.272C96.2076 36.736 97.4396 39.424 98.4476 42.224C98.6716 42.784 98.3356 43.456 97.7756 43.68C97.5516 43.792 97.4396 43.792 97.3276 43.792ZM89.8236 30.912C89.4876 30.912 89.1516 30.8 88.9276 30.576C87.1356 28.448 85.0076 26.544 82.7676 24.976C82.3196 24.64 82.2076 23.856 82.5436 23.408C82.8796 22.96 83.6636 22.848 84.1116 23.184C86.4636 24.976 88.7036 26.88 90.6076 29.12C91.0556 29.568 90.9436 30.24 90.4956 30.688C90.3836 30.8 90.0476 30.912 89.8236 30.912ZM78.0636 21.728C77.8396 21.728 77.7276 21.728 77.5036 21.616C75.0396 20.384 72.4636 19.376 69.7756 18.704C69.2156 18.592 68.7676 17.92 68.9916 17.36C69.1036 16.8 69.7756 16.352 70.3356 16.576C73.1356 17.248 75.9356 18.368 78.6236 19.712C79.1836 19.936 79.4076 20.72 79.0716 21.168C78.8476 21.504 78.5116 21.728 78.0636 21.728Z"
        fill="#FF6C4A"
      />
      <path
        d="M63.6156 17.696C62.0476 17.584 60.5916 17.472 59.1356 17.584C58.5756 17.584 58.0156 17.136 58.0156 16.464C58.0156 15.792 58.4636 15.344 59.1356 15.344C60.7036 15.344 62.2716 15.344 63.7276 15.456C64.3996 15.456 64.8476 16.016 64.7356 16.688C64.7356 17.248 64.2876 17.696 63.6156 17.696Z"
        fill="#FF6C4A"
      />
      <path
        d="M84.0008 60.928H59.1368C58.4648 60.928 58.0168 60.48 58.0168 59.808V34.944C58.0168 34.272 58.4648 33.824 59.1368 33.824C59.8088 33.824 60.2568 34.272 60.2568 34.944V58.688H84.0008C84.6728 58.688 85.1208 59.136 85.1208 59.808C85.1208 60.48 84.5608 60.928 84.0008 60.928Z"
        fill="#2A2A2A"
      />
      <path
        d="M93.5208 76.608C92.9608 76.272 92.2888 76.384 91.9528 76.944C91.1688 78.176 90.2728 79.296 89.2648 80.416C81.9848 88.928 71.4568 93.744 60.2568 93.744C39.2008 93.744 22.1768 76.608 22.1768 55.664C22.1768 50.848 23.0728 46.256 24.7528 41.776C24.7528 41.664 24.8648 41.44 24.8648 41.328L31.0248 52.864C31.2488 53.2 31.5848 53.424 32.0328 53.424C32.2568 53.424 32.3688 53.424 32.5928 53.312C33.1528 52.976 33.3768 52.304 33.0408 51.744L25.9848 38.752C25.8728 38.528 25.6488 38.304 25.3128 38.192C24.9768 38.08 24.7528 38.192 24.4168 38.304L11.6488 45.248C11.0888 45.584 10.8648 46.256 11.2008 46.816C11.5368 47.376 12.2088 47.6 12.7688 47.264L22.2888 42.112C20.7208 46.48 19.9368 50.96 19.9368 55.664C19.9368 77.952 38.0808 95.984 60.2568 95.984C72.1288 95.984 83.2168 90.832 90.9448 81.872C91.9528 80.752 92.8488 79.52 93.7448 78.176C94.0808 77.728 93.9688 76.944 93.5208 76.608Z"
        fill="#2A2A2A"
      />
    </>
  ),
})

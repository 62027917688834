import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Project',
  viewBox: '0 0 112 112',
  path: (
    <>
      <path
        d="M68.3192 50.848H12.3192C11.6472 50.848 11.1992 50.4 11.1992 49.728V34.16C11.1992 33.488 11.6472 33.04 12.3192 33.04H68.3192C68.9912 33.04 69.4392 33.488 69.4392 34.16V49.728C69.4392 50.288 68.8792 50.848 68.3192 50.848ZM13.4392 48.608H67.1992V35.28H13.4392V48.608Z"
        fill="#2A2A2A"
      />
      <path
        d="M85.9052 66.416H29.9052C29.2332 66.416 28.7852 65.968 28.7852 65.296V49.728C28.7852 49.056 29.2332 48.608 29.9052 48.608H85.9052C86.5772 48.608 87.0252 49.056 87.0252 49.728V65.296C87.0252 65.856 86.4652 66.416 85.9052 66.416ZM31.0252 64.176H84.7852V50.848H31.0252V64.176Z"
        fill="#2A2A2A"
      />
      <path
        d="M99.6786 81.984H43.6786C43.0066 81.984 42.5586 81.536 42.5586 80.864V65.296C42.5586 64.624 43.0066 64.176 43.6786 64.176H99.6786C100.351 64.176 100.799 64.624 100.799 65.296V80.864C100.799 81.424 100.351 81.984 99.6786 81.984ZM44.7986 79.744H98.5586V66.416H44.7986V79.744Z"
        fill="#2A2A2A"
      />
      <path
        d="M30.8001 43.008H21.6161C20.9441 43.008 20.4961 42.56 20.4961 41.888C20.4961 41.216 20.9441 40.768 21.6161 40.768H30.8001C31.4721 40.768 31.9201 41.216 31.9201 41.888C31.9201 42.56 31.3601 43.008 30.8001 43.008Z"
        fill="#FF6C4A"
      />
      <path
        d="M48.2721 58.576H39.0881C38.4161 58.576 37.9681 58.128 37.9681 57.456C37.9681 56.784 38.4161 56.336 39.0881 56.336H48.2721C48.9441 56.336 49.3921 56.784 49.3921 57.456C49.3921 58.128 48.9441 58.576 48.2721 58.576Z"
        fill="#FF6C4A"
      />
      <path
        d="M62.1601 74.144H52.9761C52.3041 74.144 51.8561 73.696 51.8561 73.024C51.8561 72.352 52.3041 71.904 52.9761 71.904H62.1601C62.8321 71.904 63.2801 72.352 63.2801 73.024C63.2801 73.696 62.8321 74.144 62.1601 74.144Z"
        fill="#FF6C4A"
      />
    </>
  ),
})

import { Box, BoxProps, Flex, Text } from '@chakra-ui/react'
import { FC } from 'react'

import { Close, type IconName, type IconSize } from '@miimosa/design-system'
import { Icon } from '@miimosa/design-system'

interface Props {
  title: string
  onClick?: () => void
  dotColor?: string
  onClear?: () => void
  round?: boolean
  iconName?: IconName
  iconSize?: IconSize
  iconColor?: string
  textSize?: string
}

const Tag: FC<Props & BoxProps> = ({
  onClick,
  round = false,
  title,
  dotColor,
  onClear,
  iconName,
  iconSize = 'sm',
  iconColor = 'white',
  textSize = 'xs',
  ...props
}) => {
  return (
    <Flex
      align="center"
      justify="center"
      border="solid 0.5px"
      borderColor={dotColor ? dotColor : 'border'}
      borderRadius={round ? '26px' : '4px'}
      p="2"
      width="fit-content"
      whiteSpace="pre"
      h="26px"
      {...props}
    >
      {dotColor && <Box width="6px" height="6px" mr="1" bgColor={dotColor} borderRadius="12px" />}
      <Text size={textSize} h="fit-content" {...(onClick && { as: 'button', onClick: onClick })}>
        {title}
      </Text>
      {iconName && <Icon ml="1" name={iconName} size={iconSize} color={iconColor} />}
      {onClear && <Close ml="1" width="12px" height="12px" as="button" onClick={onClear} />}
    </Flex>
  )
}

export default Tag

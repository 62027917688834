import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'TadaInno',
  viewBox: '0 0 65 44',
  path: (
    <>
      <path
        d="M13.1151 14.7479C14.0771 14.2051 15.297 14.5449 15.8398 15.5069C17.1081 17.7549 18.5433 19.9655 20.0239 22.2458L20.0269 22.2505L20.027 22.2505C21.4946 24.5108 23.0063 26.839 24.3619 29.2416C24.9047 30.2036 24.5648 31.4234 23.6028 31.9662C22.6408 32.509 21.4209 32.1692 20.8781 31.2072C19.5929 28.9294 18.149 26.7034 16.6691 24.4241L16.6384 24.377L16.6384 24.3769C15.1802 22.1311 13.6868 19.8311 12.356 17.4726C11.8132 16.5106 12.1531 15.2907 13.1151 14.7479Z"
        fill="currentColor"
      />
      <path
        d="M1.03443 33.734C1.57549 32.771 2.79476 32.429 3.75773 32.9701C6.00797 34.2344 6.83749 34.5372 9.28593 35.7191L9.29097 35.7215C11.718 36.8931 14.2179 38.0998 16.6229 39.4511C17.5858 39.9922 17.9279 41.2114 17.3868 42.1744C16.8457 43.1374 15.6265 43.4794 14.6635 42.9383C12.3834 41.6572 9.99442 40.5027 7.54705 39.3213L7.49643 39.2969C5.08496 38.1328 4.15931 37.7838 1.79837 36.4573C0.835392 35.9162 0.493365 34.697 1.03443 33.734Z"
        fill="currentColor"
      />
      <path
        d="M34.7255 33.6773C33.7258 33.2075 33.2963 32.0162 33.7662 31.0166L33.8497 30.8388C36.7882 24.5871 38.2415 21.4952 40.3555 14.1012C40.6592 13.0392 41.7663 12.4244 42.8283 12.728C43.8903 13.0317 44.5051 14.1388 44.2014 15.2008C41.9988 22.9048 40.4386 26.2241 37.4681 32.544L37.3863 32.718C36.9164 33.7177 35.7251 34.1472 34.7255 33.6773Z"
        fill="currentColor"
      />
    </>
  ),
})

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Pin',
  viewBox: '0 0 70 94',
  path: (
    <>
      <path
        d="M35 0.333344C25.6667 0.333344 17.2667 3.60001 10.7333 9.66668C3.73333 16.6667 0 26 0 37.6667C0 54 22.4 81.0667 32.2 92.2667C33.1333 93.2 34.0667 93.6667 35 93.6667C35.9333 93.6667 36.8667 93.2 37.8 92.2667C47.6 81.5334 70 54.4667 70 37.6667C70 12.4667 51.8 0.333344 35 0.333344ZM35 88.5333C15.8667 67.0667 4.66667 47.9333 4.66667 38.1333C4.66667 27.8667 7.93333 19.4667 14 13.4C19.6 8.26668 27.0667 5.00001 35 5.00001C49.9333 5.00001 65.3333 15.2667 65.3333 37.6667C65.3333 48.4 54.1333 67.0667 35 88.5333Z"
        fill="currentColor"
      />
      <path
        d="M34.5333 19.9334C26.6 19.9334 20.5333 26 20.5333 33.9334C20.5333 41.8667 26.6 47.9334 34.5333 47.9334C42.4667 47.9334 48.5333 41.8667 48.5333 33.9334C48.5333 26 42.4667 19.9334 34.5333 19.9334ZM34.5333 43.2667C29.4 43.2667 25.2 39.0667 25.2 33.9334C25.2 28.8 29.4 24.6 34.5333 24.6C39.6667 24.6 43.8667 28.8 43.8667 33.9334C43.8667 39.0667 40.1333 43.2667 34.5333 43.2667Z"
        fill="currentColor"
      />
    </>
  ),
})

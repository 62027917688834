import { Container, Text, Flex, Grid, BoxProps, GridItem } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

import type { FrontCollectTypeType, FrontSearchProjectType } from '@miimosa/common/types'

import { ProjectCard } from '@components'

interface Props {
  projects: FrontSearchProjectType[]
  collectType?: FrontCollectTypeType | null
}

const ProjectsGrid: FC<Props & BoxProps> = ({ projects }) => {
  const { t: tCommon } = useTranslation()

  if (!projects.length)
    return (
      <Container variant="full" bgColor="creamy">
        <Container variant="boxed" py="8" mb="16">
          <Text textAlign="center" size="xl" my="5">
            {tCommon('no_projects_results')}
          </Text>
        </Container>
      </Container>
    )

  return (
    <Container variant="full" bgColor="creamy">
      <Container variant="boxed" py="8" mb="16">
        <Grid
          width="100%"
          templateRows={{ base: '1fr', md: 'repeat(2, 1fr)' }}
          templateColumns="repeat(auto-fit, minmax(324px, 1fr))"
          rowGap={8}
        >
          {projects?.map((project, index) => {
            return (
              <GridItem key={index} colSpan={1}>
                <Flex px="2">
                  <ProjectCard
                    imageFileName={project.imageFileName}
                    interestsRatio={project.collect?.interestsRatio || 0}
                    slug={project.slug}
                    title={project.title}
                    teasing={project.teasing}
                    goalAmount={project.collect?.goalAmount || 0}
                    place={project.place}
                    endsAt={project.collect?.endsAt}
                    goalType={project.collect?.goalType || 'amount'}
                    collectType={project.collect?.type || 'donation'}
                    collectedAmount={project.collect?.collectedAmount || 0}
                    userFullName={project.userFullName}
                    avatarFileName={project.avatarFileName}
                    progress={project.collect?.progress || 0}
                    state={project.state}
                    labels={project.labels}
                    goalQuantity={project.collect?.goalQuantity || 0}
                    collectedQuantity={project.collect?.collectedQuantity || 0}
                  />
                </Flex>
              </GridItem>
            )
          })}
        </Grid>
      </Container>
    </Container>
  )
}

export default ProjectsGrid

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Arrow',
  viewBox: '0 0 20 11',
  path: (
    <>
      <path
        d="M10.0001 10.4999C9.93518 10.5026 9.87042 10.4916 9.8101 10.4674C9.74977 10.4433 9.69524 10.4067 9.6501 10.3599L0.650096 1.35994C0.603232 1.31346 0.566035 1.25816 0.540651 1.19723C0.515266 1.1363 0.502197 1.07095 0.502197 1.00494C0.502197 0.938935 0.515266 0.873582 0.540651 0.812653C0.566035 0.751723 0.603232 0.696422 0.650096 0.64994C0.745246 0.557691 0.872569 0.506104 1.0051 0.506104C1.13762 0.506104 1.26495 0.557691 1.3601 0.64994L10.0001 9.29994L18.6501 0.64994C18.7436 0.558327 18.8692 0.507012 19.0001 0.507012C19.131 0.507012 19.2566 0.558327 19.3501 0.64994C19.397 0.696422 19.4342 0.751723 19.4595 0.812653C19.4849 0.873582 19.498 0.938935 19.498 1.00494C19.498 1.07095 19.4849 1.1363 19.4595 1.19723C19.4342 1.25816 19.397 1.31346 19.3501 1.35994L10.3501 10.3599C10.305 10.4067 10.2504 10.4433 10.1901 10.4674C10.1298 10.4916 10.065 10.5026 10.0001 10.4999Z"
        fill="#003F2C"
      />
    </>
  ),
})

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Messenger',
  viewBox: '0 0 16 16',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 7.76C0 3.30039 3.49333 0 8 0C12.5067 0 16 3.30039 16 7.76C16 12.2196 12.5067 15.52 8 15.52C7.19059 15.52 6.41412 15.4133 5.68471 15.2125C5.54353 15.1733 5.39294 15.1843 5.25804 15.2439L3.67059 15.9451C3.2549 16.1286 2.78588 15.8322 2.77176 15.3788L2.72784 13.9561C2.72314 13.7804 2.64314 13.6173 2.51294 13.4996C0.955294 12.1082 0 10.0925 0 7.76ZM5.5451 6.30118L3.19529 10.0298C2.96941 10.3875 3.40863 10.7906 3.74588 10.5349L6.2698 8.61961C6.44078 8.48941 6.67765 8.48941 6.84863 8.61804L8.71843 10.0204C9.27843 10.4408 10.08 10.2933 10.4533 9.70039L12.8031 5.97176C13.029 5.61412 12.5898 5.21098 12.2525 5.46667L9.72863 7.38196C9.55765 7.51216 9.32078 7.51216 9.1498 7.38353L7.28 5.98118C6.72 5.56078 5.91843 5.70823 5.5451 6.30118Z"
      fill="currentColor"
    />
  ),
})

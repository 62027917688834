import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'RenewableEnergy',
  viewBox: '0 0 14 20',
  path: (
    <>
      <path
        d="M13.5001 5.1C13.2001 3 11.6001 1.1 9.40007 0.3C8.70007 0.1 8.00007 0 7.20007 0C7.00007 0 6.80007 0 6.60007 0C3.60007 0.3 1.30007 2.6 1.00007 5.4C0.900067 7.2 1.70007 9 3.20007 10.2C3.70007 10.6 4.00007 11.2 4.00007 12V13.6C4.00007 13.7 4.00007 13.7 4.10007 13.8C5.20007 14.2 6.20007 14.3 7.10007 14.3C8.90007 14.3 10.2001 13.8 10.3001 13.8C10.4001 13.8 10.4001 13.7 10.4001 13.6V12C10.4001 11.3 10.7001 10.6 11.3001 10.2C12.9001 8.9 13.7001 7.1 13.5001 5.1ZM10.8001 9.4C10.0001 10 9.50007 11 9.50007 12V13C9.00007 13.1 8.10007 13.3 7.20007 13.3C6.50007 13.3 5.70007 13.2 5.10007 13V12C5.10007 11 4.70007 10.1 3.90007 9.5C2.60007 8.5 1.90007 7 2.10007 5.5C2.20007 3.2 4.20007 1.3 6.70007 1C6.80007 1 7.00007 1 7.20007 1C7.80007 1 8.50007 1.1 9.00007 1.3C10.9001 1.9 12.2001 3.5 12.5001 5.3C12.7001 6.8 12.1001 8.4 10.8001 9.4Z"
        fill="currentColor"
      />
      <path
        d="M10.2001 14.3C9.90007 14.3 9.70007 14.5 9.70007 14.8C9.20007 15 8.30007 15.2 7.10007 15.2C6.30007 15.2 5.40007 15.1 4.70007 14.8C4.70007 14.5 4.50007 14.3 4.20007 14.3C3.90007 14.3 3.70007 14.5 3.70007 14.8V15C3.70007 15.3 3.80007 15.5 4.10007 15.6C5.00007 16 6.00007 16.2 7.10007 16.2C8.60007 16.2 9.80007 15.8 10.3001 15.6C10.5001 15.5 10.7001 15.2 10.7001 15V14.8C10.7001 14.5 10.5001 14.3 10.2001 14.3Z"
        fill="currentColor"
      />
      <path
        d="M9.90007 16.2C9.60007 16.2 9.40007 16.4 9.40007 16.7C9.00007 16.9 8.20007 17.1 7.20007 17.1C6.50007 17.1 5.80007 17 5.20007 16.7C5.20007 16.4 5.00007 16.2 4.70007 16.2C4.40007 16.2 4.20007 16.4 4.20007 16.7V16.9C4.20007 17.1 4.30007 17.4 4.60007 17.5C5.40007 17.9 6.30007 18.1 7.20007 18.1C8.50007 18.1 9.60007 17.7 10.0001 17.5C10.2001 17.4 10.3001 17.2 10.3001 16.9V16.7C10.4001 16.4 10.1001 16.2 9.90007 16.2Z"
        fill="currentColor"
      />
      <path
        d="M9.50007 18.1C9.20007 18.1 9.00007 18.3 9.00007 18.6C8.60007 18.8 8.00007 19 7.20007 19C6.60007 19 6.00007 18.9 5.50007 18.6V18.5C5.50007 18.2 5.30007 18 5.00007 18C4.70007 18 4.50007 18.2 4.50007 18.5V18.7C4.50007 18.9 4.60007 19.1 4.80007 19.3C5.60007 19.8 6.40007 20 7.20007 20C8.40007 20 9.30007 19.6 9.70007 19.4C9.90007 19.3 10.0001 19.1 10.0001 18.8V18.6C10.0001 18.4 9.80007 18.1 9.50007 18.1Z"
        fill="currentColor"
      />
    </>
  ),
})

import React, { FC } from 'react'

interface Props {
  condition: boolean
  wrap: (children: JSX.Element) => JSX.Element
  children: JSX.Element
}

const ConditionalWrapper: FC<Props> = ({ condition, children, wrap }) =>
  condition ? React.cloneElement(wrap(children)) : children

export default ConditionalWrapper

import { Box, Flex, BoxProps, Text } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'

import Icon, { IconName, IconSize } from '../Icon'

interface Props extends BoxProps {
  iconName?: IconName
  iconSize?: IconSize
  numberSize?: string
  number?: number
  isCentered?: boolean
  children: ReactNode
  icon?: ReactNode
  tada?: ReactNode
  locale?: string
  numberColor?: string
  isHorizontal?: boolean
}

const TextIconBloc: FC<Props> = ({
  icon,
  iconName,
  iconSize,
  numberSize,
  number,
  isCentered = true,
  children,
  tada,
  locale,
  numberColor,
  isHorizontal = false,
  ...props
}) => {
  return (
    <Flex
      direction={isHorizontal ? 'row' : 'column'}
      align={isCentered ? 'center' : 'flex-start'}
      justifyContent="center"
      {...props}
    >
      {icon}
      {iconName && <Icon name={iconName} size={iconSize} />}
      {!!number && (
        <Text
          color={numberColor}
          fontWeight="extrabold"
          {...(numberSize ? { fontSize: numberSize } : { size: 'xl' })}
          mb={isHorizontal ? 0 : 3}
        >
          {number.toLocaleString(locale, { maximumFractionDigits: 0 })}
        </Text>
      )}
      <Box position="relative">
        {tada}
        {children}
      </Box>
    </Flex>
  )
}

export default TextIconBloc

import { type BoxProps as ChakraBoxProps, Box, AspectRatio } from '@chakra-ui/react'
import { default as NextImage, type ImageProps as NextImageProps } from 'next/image'
import { FC } from 'react'

import { s3ImageURL } from '@miimosa/common'

const myLoader = ({ src }: { src: string; width: number; quality?: number }) => src

type ImageProps = Pick<
  NextImageProps,
  'alt' | 'width' | 'height' | 'quality' | 'className' | 'priority' | 'fill' | 'style' | 'sizes'
>

type BoxProps = Omit<ChakraBoxProps, 'objectFit' | 'width' | 'height' | 'objectPosition' | 'zIndex' | 'fill' | 'style'>

interface Props extends BoxProps, ImageProps {
  src: string
  aspectRatio?: number
  zIndex?: number | string
  boxed?: boolean
  s3?: boolean
}

const Image: FC<Props & ImageProps & BoxProps> = ({
  // Next Image props are here
  src,
  alt,
  width,
  height,
  quality,
  position,
  className,
  priority,
  zIndex,
  fill,
  style,
  sizes,
  // Custom props
  aspectRatio,
  s3,
  boxed,
  ...props // Props are leftover box props
}) => {
  if (s3) {
    src = s3ImageURL(src)
  }
  const imgProps = {
    src,
    alt,
    quality,
    position,
    className,
    priority,
    fill,
    style,
    width,
    height,
    sizes,
    loader: myLoader,
    unoptimized: true,
  }
  if (!boxed && !aspectRatio) {
    return <NextImage {...imgProps} />
  }

  const boxProps: ChakraBoxProps = { position: 'relative', width, height, zIndex, ...props }

  if (aspectRatio) {
    return (
      <AspectRatio ratio={aspectRatio} {...boxProps}>
        <NextImage {...imgProps} />
      </AspectRatio>
    )
  }

  return (
    <Box {...boxProps}>
      <NextImage {...imgProps} />
    </Box>
  )
}

export default Image

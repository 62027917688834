import { Heading, Flex, Text } from '@chakra-ui/react'
import { useState } from 'react'

import { s3ImageURL } from '@miimosa/common'

import Image from '@components/Image'

const Partners = () => {
  return (
    <Flex direction="column" w="full" alignItems="center">
      <Heading mb={14}>Les partenaires</Heading>
      <Flex direction={{ base: 'column', lg: 'row' }} w="full" columnGap={8} mb={8}>
        <Flex
          direction="column"
          w={{ base: 'full', lg: '50%' }}
          h="317px"
          bgColor="green"
          alignItems="center"
          rowGap={8}
          px={10}
          pt={8}
        >
          <Flex w="full" h="75px" justifyContent="center" alignItems="center">
            <Flex position="relative" w="170px" h="51px">
              <Image
                src={s3ImageURL('miimosa-white-logo.png')}
                fill
                style={{ objectFit: 'cover' }}
                alt="miimosa logo"
                priority={false}
              />
            </Flex>
          </Flex>
          <Text color="white" maxW="485px">
            MiiMOSA est la plateforme de financement leader en Europe sur les thématiques agricoles et alimentaires. À
            travers deux offres de financement, le don avec contrepartie et le prêt participatif, nous avons déjà
            accompagné 6 000 agriculteurs, producteurs et entrepreneurs dans la réalisation de leurs projets.
          </Text>
        </Flex>
        <Flex
          direction="column"
          w={{ base: 'full', lg: '50%' }}
          h="317px"
          bgColor="pink"
          alignItems="center"
          rowGap={8}
          px={10}
          pt={8}
        >
          <Flex w="full" h="75px" justifyContent="center" alignItems="center">
            <Flex position="relative" w="72px" h="57px">
              <Image src={s3ImageURL('m6-pub-logo.png')} fill style={{ objectFit: 'cover' }} alt="m6 logo" />
            </Flex>
          </Flex>
          <Text maxW="485px">
            M6 Publicité a pour volonté, à travers ce dispositif, de soutenir des projets défendant une agriculture
            durable et engagée. Cet engagement s’inscrit dans la nouvelle feuille de route RSE du Groupe M6 et démontre
            l’attachement historique de ce dernier au secteur agricole.
          </Text>
        </Flex>
      </Flex>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        w="full"
        justifyContent="space-evenly"
        alignItems={{ base: 'center', md: 'normal' }}
        rowGap={{ base: 8, md: 0 }}
      >
        <PartnerLogo
          imageName={'LPB-logo.png'}
          text={'<strong>La Provençale</strong>, soin bio et maquillage naturel d&apos;exception.'}
          imageW="250px"
          imageH="106px"
          link="https://www.laprovencale.bio/"
        />
        <PartnerLogo
          imageName={'harrys-logo-high.png'}
          text={
            '<strong>Harrys</strong>, marque française de pain de mie et viennoiserie, s’engage pour une filière blé plus responsable.'
          }
          imageW="165px"
          imageH="165px"
          link="https://www.harrys.fr/"
        />
        <PartnerLogo
          imageName={'GRDF-logo-high.png'}
          text={'<strong>GRDF</strong>, engagé pour l’avenir avec les gaz verts.'}
          imageW="166px"
          imageH="84px"
          link="https://www.grdf.fr/"
        />
      </Flex>
    </Flex>
  )
}

const PartnerLogo = ({
  imageName,
  imageW,
  imageH,
  text,
  link,
}: {
  imageName: string
  imageW: string
  imageH: string
  text: string
  link: string
}) => {
  const [displayText, setDisplayText] = useState(false)

  return (
    <Flex
      as="a"
      h="153px"
      w={{ base: 'full', md: '278px' }}
      border="1px solid rgba(1, 65, 41, 0.07)"
      borderRadius={5}
      justifyContent="center"
      alignItems="center"
      onMouseEnter={() => setDisplayText(true)}
      onMouseLeave={() => setDisplayText(false)}
      href={link}
      target="_blank"
    >
      {displayText ? (
        <Text maxW="200px" dangerouslySetInnerHTML={{ __html: text }} />
      ) : (
        <Flex position="relative" h={imageH} w={imageW}>
          <Image src={s3ImageURL(imageName)} fill style={{ objectFit: 'cover' }} alt="partner logo" />
        </Flex>
      )}
    </Flex>
  )
}

export default Partners

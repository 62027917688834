import { Flex, Button } from '@chakra-ui/react'

import { Icon } from '../../../components'

const DropdownIndicator = ({
  isFocused,
  onClick,
  disabled,
}: {
  isFocused?: boolean
  onClick?: () => void
  disabled?: boolean
}) => {
  const bgColor = isFocused ? 'dark_green' : 'transparent'
  const color = isFocused ? 'white' : 'dark_green'

  return (
    <Flex bgColor={bgColor} align="center" borderRadius="100%" justify="center" p="12px" mr="4px" mb="2px">
      <Button variant="ghost" onClick={onClick} disabled={disabled} isDisabled={disabled}>
        <Icon name="Search" size="4xs" color={color} />
      </Button>
    </Flex>
  )
}

export default DropdownIndicator

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'WaterMousline',
  viewBox: '0 0 512 512',
  path: (
    <>
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
        <path
          fill="currentColor"
          d="M2349,5107c-8-7-323-469-699-1027S928,3009,882,2940c-247-363-362-774-332-1189c11-158,21-219,61-369
		C786,715,1342,190,2025,44c323-68,640-51,963,52c121,39,327,136,432,204c223,145,446,374,584,602c198,326,298,735,267,1094l-10,119
		l48,50c128,132,203,279,242,470c32,160,0,382-79,540c-13,28-184,287-378,576c-240,356-362,529-379,537c-70,32-93,11-250-222
		c-77-114-142-206-145-206c-3,1-185,269-405,597s-413,611-429,630c-24,29-34,33-75,33C2386,5120,2358,5114,2349,5107z M2805,4269
		c215-319,391-583,393-588c1-4-61-102-138-217c-176-260-192-288-229-400c-146-434,70-916,489-1095c211-90,481-93,682-9
		c37,15,69,25,73,22c9-9,6-191-5-287c-44-382-200-711-464-984c-279-287-622-455-1021-501c-299-34-641,27-912,163
		c-327,165-615,456-767,777c-128,270-183,569-157,853c29,331,117,561,338,887c65,97,388,577,718,1068c330,490,602,891,605,891
		S2591,4588,2805,4269z M3982,3565c162-242,305-459,316-482c40-78,65-191,65-298c0-194-63-345-203-486c-73-73-100-94-180-132
		c-123-58-182-72-310-71c-169,1-298,45-427,149c-238,190-324,507-215,789c20,51,116,203,336,530c170,252,312,454,316,449
		C3684,4009,3820,3807,3982,3565z"
        />
        <path
          fill="currentColor"
          d="M1273,2690c-31-12-101-110-156-218c-166-321-198-710-86-1055c125-385,405-685,794-852c113-48,163-51,202-12
		c21,20,28,37,28,66c0,52-31,85-99,107c-70,22-213,95-289,145c-92,63-246,221-311,321c-259,398-260,890-3,1292c31,50,57,100,57,112
		c0,36-19,69-50,87C1329,2702,1306,2703,1273,2690z"
        />
        <path
          fill="currentColor"
          d="M2234,631c-32-14-64-60-64-93c0-21,11-41,34-64c44-44,83-45,128-5c27,24,33,36,33,70
		C2365,606,2294,656,2234,631z"
        />
        <path
          fill="currentColor"
          d="M3209,3076c-53-30-96-186-86-309c14-166,110-331,234-404c78-45,153-7,153,78c0,35-7,46-73,112
		c-57,56-79,85-95,130c-26,69-29,162-7,233c21,69,19,108-7,139C3300,3087,3245,3096,3209,3076z"
        />
        <path
          fill="currentColor"
          d="M3635,2436c-91-40-60-186,40-186c51,0,95,44,95,95c0,49-15,74-55,91C3674,2453,3673,2453,3635,2436z"
        />
      </g>
    </>
  ),
})

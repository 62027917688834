import { getRegionsFR } from '@miimosa/common'

const getFilters = (t: (key: string) => string) => {
  const region = getRegionsFR(t).map(({ slug, title }) => ({ value: slug, label: title as string }))
  const collectsType = [
    { value: 'donation', label: t('donation_label') },
    { value: 'lending', label: t('lending_label') },
  ]
  const order = [
    { value: 'newest', label: t('queryFilter.sort.newest') },
    { value: 'soon_finished', label: t('queryFilter.sort.soon_finished') },
    { value: 'popular', label: t('queryFilter.sort.popular') },
  ]

  return [
    {
      id: 'region',
      title: t('queryFilter.region'),
      filters: region,
      isRadio: false,
    },
    {
      id: 'type',
      title: t('queryFilter.collect_type'),
      filters: collectsType,
      isRadio: false,
    },
    {
      id: 'order',
      title: t('queryFilter.sort_by'),
      filters: order,
      isRadio: true,
    },
  ]
}

export const formatQueryFilters = (
  { region, type, order }: Record<string, string | string[] | undefined>,
  platform: string
) => {
  return {
    ...(platform === 'fr' && { region: region ? (Array.isArray(region) ? region : [region]) : [] }),
    type: type ? (Array.isArray(type) ? type : [type]) : [],
    order: order ? (Array.isArray(order) ? order : [order]) : [],
  }
}

export default getFilters

import { Box, Button, Image } from '@chakra-ui/react'
import { useRef } from 'react'

import { s3ImageURL } from '@miimosa/common'
import type { FrontCollectTypeType, Coordinates } from '@miimosa/common/types'
interface MapPointProps {
  map: google.maps.Map
  projectId: number
  collectType: FrontCollectTypeType
  longitude: number
  latitude: number
  fromHome: boolean
  visibleCoordinates?: { id: number } & Coordinates
  onSelectPoint: (coordinates: Coordinates, id: number) => void
  propsPinMapUrl?: string
}

const defaultPinImageUrl = s3ImageURL('pinMapDefaultMii.svg', 'miimosa')
const donationPinImageUrl = s3ImageURL('pinMapSecondMii.svg', 'miimosa')
const activePinImageUrl = s3ImageURL('pinMapActiveMii.svg', 'miimosa')

const MapPoint = (props: MapPointProps) => {
  const { projectId, collectType, longitude, latitude, visibleCoordinates, onSelectPoint, propsPinMapUrl } = props

  const handleOnClick = () => {
    onSelectPoint({ lat: latitude, lng: longitude }, projectId)
  }

  const containerRef = useRef<HTMLDivElement>(null)

  // map?.addListener('zoom_changed', () => {
  //   setIsOpened(false)
  // })

  // hide cards when clicking outside
  // useEffect(() => {
  //   function handleClickOutside(this: Document, event: MouseEvent) {
  //     if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
  //       setIsOpened(false)
  //     }
  //   }
  //   document.addEventListener('mousedown', handleClickOutside)
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside)
  //   }
  // }, [containerRef])

  const isSelected = visibleCoordinates && visibleCoordinates.id === projectId
  const isDonation = collectType === 'donation'
  const pinMapUrl =
    propsPinMapUrl || (isSelected && activePinImageUrl) || (isDonation && donationPinImageUrl) || defaultPinImageUrl

  return (
    <Box ref={containerRef} position="relative" w="100%" h="100%">
      <Button
        variant="ghost"
        position="relative"
        w="100%"
        h="100%"
        zIndex="0"
        onClick={handleOnClick}
        transform={isSelected ? 'scale(1.2, 1.2)' : undefined}
      >
        <Image w="100%" h="100%" src={pinMapUrl} alt="" />
      </Button>
    </Box>
  )
}

export default MapPoint

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Leave',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d="M7.5 5.5C7.8 5.5 8 5.3 8 5C8 4.7 7.8 4.5 7.5 4.5H5.5C5.2 4.5 5 4.7 5 5V19C5 19.3 5.2 19.5 5.5 19.5H7.5C7.8 19.5 8 19.3 8 19C8 18.7 7.8 18.5 7.5 18.5H6V5.5H7.5Z"
        fill="#1F7A38"
      />
      <path
        d="M19 11.9C19 11.8 18.9 11.8 18.9 11.7L15.6 8.4C15.4 8.2 15.1 8.2 14.9 8.4C14.7 8.6 14.7 8.9 14.9 9.1L17.4 11.6H8.9C8.6 11.6 8.4 11.8 8.4 12.1C8.4 12.4 8.6 12.6 8.9 12.6H17.3L14.8 15.1C14.6 15.3 14.6 15.6 14.8 15.8C14.9 15.9 15 15.9 15.2 15.9C15.4 15.9 15.5 15.9 15.6 15.8L18.9 12.5C18.9 12.5 19 12.4 19 12.3C19 12.1 19 12 19 11.9Z"
        fill="#1F7A38"
      />
    </>
  ),
})

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'ClimatBig',
  viewBox: '0 0 100 100',
  path: (
    <>
      <path
        d="M38.5 24.6C39.1 24.6 39.5 24.2 39.5 23.6V14.5C39.5 13.9 39.1 13.5 38.5 13.5C37.9 13.5 37.5 13.9 37.5 14.5V23.6C37.5 24.2 38 24.6 38.5 24.6Z"
        fill="black"
      />
      <path
        d="M23.9 30C24.1 30.2 24.4001 30.3 24.6 30.3C24.8 30.3 25.1 30.2 25.3 30C25.7 29.6 25.7 29 25.3 28.6L18.9 22.2C18.5 21.8 17.9 21.8 17.5 22.2C17.1 22.6 17.1 23.2 17.5 23.6L23.9 30Z"
        fill="black"
      />
      <path
        d="M23.8 54.5L17.4 60.9C17 61.3 17 61.9 17.4 62.3C17.6 62.5 17.9001 62.6 18.1 62.6C18.3 62.6 18.6 62.5 18.8 62.3L25.2001 55.9C25.6 55.5 25.6 54.9 25.2001 54.5C24.8001 54.1 24.2 54.1 23.8 54.5Z"
        fill="black"
      />
      <path
        d="M53.7001 31.6C54 31.6 54.2001 31.5 54.4001 31.3L60.8 24.9C61.2001 24.5 61.2001 23.9 60.8 23.5C60.4 23.1 59.8001 23.1 59.4001 23.5L53 29.8C52.6 30.2 52.6 30.8 53 31.2C53.2001 31.5 53.5 31.6 53.7001 31.6Z"
        fill="black"
      />
      <path
        d="M20.9 42.2C20.9 41.6 20.5 41.2 19.9 41.2H10.8C10.2 41.2 9.80005 41.6 9.80005 42.2C9.80005 42.8 10.2 43.2 10.8 43.2H19.9C20.5 43.2 20.9 42.7 20.9 42.2Z"
        fill="black"
      />
      <path
        d="M89.9001 57.4C89.3001 51.1 84.4 45.8 78.2 44.8C76 44.4 73.8 44.6 71.8 45.1C68.5 39.5 62.5 36.1 55.9 36.1C55 36.1 54.1 36.2 53.3 36.3C50.8 30.1 44.4001 25.8 37.2001 26.4C29.9001 27 23.9 32.7 23 39.9C22.1 46.3 25.1 52.2 30 55.4C28.7 57.2 27.9 59.5 27.9 61.9C27.9 65.6 29.7 69 32.8 71.2C34.7 72.5 37 73.1 39.3 73.1H45.9001C46.4001 73.1 46.8 72.7 46.8 72.2V72C46.8 71.5 46.4 71 45.8 71H39.3C37.4 71 35.5 70.5 34 69.4C31.4 67.6 29.9 64.8 29.9 61.8C29.9 59.7 30.6 57.8 31.7001 56.3C33.8001 57.3 36.1 57.9 38.5 57.9C45.2001 57.9 51.1 53.5 53.5 47.2C54.7001 43.9 54.7001 40.9 53.9001 38.1C54.6001 38 55.2 38 55.9 38C62 38 67.6 41.3 70.4 46.7C70.6 47.1 71.1 47.3 71.6 47.2C73 46.7 74.5 46.5 76 46.5C82.2 46.6 87.8001 52.2 88.0001 58.4C88.2001 65.3 82.6 71 75.7 71H70.1C69.5 71 69.1 71.4 69.1 72C69.1 72.6 69.5 73 70.1 73H75.7C84 73.1 90.7001 65.9 89.9001 57.4ZM51.3 47.4C49.3 52.1 44.9 55.6 39.8 56C37.4 56.2 35.1 55.8 33.1 54.9C32.4 54.6 31.8001 54.3 31.2001 53.9C26.8001 51.1 24.1 46 24.8 40.3C25.6 34.3 30.4001 29.4 36.4001 28.5C43 27.5 48.8 31.2 51.2001 36.7C51.5 37.3 51.7001 38 51.9001 38.6C52.6001 41.3 52.6 44.2 51.3 47.4Z"
        fill="black"
      />
      <path
        d="M51.0001 84.8C50.9001 84.8 50.7001 84.8 50.6001 84.7C50.1001 84.5 49.9001 83.9 50.1001 83.4L52.9001 77.2L50.6001 77.3C50.2001 77.3 49.9001 77.2 49.7001 76.9C49.5001 76.6 49.5001 76.2 49.6001 75.9L51.9001 70.9C52.1001 70.4 52.7001 70.2 53.2001 70.4C53.7001 70.6 53.9001 71.2 53.7001 71.7L52.1001 75.1L54.4001 75C54.7001 75 55.1001 75.1 55.3001 75.4C55.5001 75.7 55.5001 76.1 55.4001 76.4L51.9001 84.1C51.8001 84.6 51.4001 84.8 51.0001 84.8Z"
        fill="currentColor"
      />
      <path
        d="M61.7001 81.5C61.6001 81.5 61.4001 81.5 61.3001 81.4C60.8001 81.2 60.6001 80.6 60.8001 80.1L63.6001 73.9L61.3001 74C60.9001 74 60.6001 73.9 60.4001 73.6C60.2001 73.3 60.2001 72.9 60.3001 72.6L62.6001 67.6C62.8001 67.1 63.4001 66.9 63.9001 67.1C64.4001 67.3 64.6001 67.9 64.4001 68.4L62.8001 71.8L65.1001 71.7C65.4001 71.7 65.8001 71.8 66.0001 72.1C66.2001 72.4 66.2001 72.8 66.1001 73.1L62.6001 80.8C62.5001 81.2 62.1001 81.5 61.7001 81.5Z"
        fill="currentColor"
      />
    </>
  ),
})

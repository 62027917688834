import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'YoupiLeft',
  viewBox: '0 0 25 84',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.24186 3.04694C4.76842 2.07597 5.98243 1.7157 6.9534 2.24227C9.52023 3.63428 12.0338 5.21642 14.4904 6.76268L14.5413 6.79471C17.0339 8.36362 19.4703 9.89568 21.9534 11.2423C22.9244 11.7688 23.2846 12.9828 22.7581 13.9538C22.2315 14.9248 21.0175 15.2851 20.0465 14.7585C17.4318 13.3405 14.8873 11.7389 12.4149 10.1827L12.4105 10.1799C9.91688 8.61037 7.49713 7.08748 5.04653 5.75849C4.07555 5.23193 3.71529 4.01792 4.24186 3.04694ZM0.266477 41.9054C0.319151 40.8021 1.25626 39.9504 2.35958 40.0031C11.609 40.4446 11.6354 40.4463 19.5812 41.1387C20.6816 41.2346 21.4959 42.2043 21.4001 43.3047C21.3042 44.4051 20.3344 45.2195 19.234 45.1236C15.589 44.8059 13.6344 44.6355 11.6773 44.5006C9.40005 44.3436 7.1195 44.2349 2.16883 43.9985C1.06552 43.9458 0.213804 43.0087 0.266477 41.9054ZM24.1206 71.216C24.5187 72.2463 24.0062 73.4043 22.9758 73.8024C20.913 74.5993 19.2669 75.1711 17.631 75.7392C14.5761 76.8003 11.557 77.8489 5.92719 80.3285C4.91633 80.7737 3.73593 80.3152 3.2907 79.3043C2.84547 78.2935 3.304 77.1131 4.31487 76.6678C10.066 74.1348 13.3515 72.9908 16.503 71.8935C18.1116 71.3334 19.6853 70.7855 21.5343 70.0712C22.5646 69.6731 23.7226 70.1856 24.1206 71.216Z"
      fill="currentColor"
    />
  ),
})

import { differenceInDays, differenceInHours } from 'date-fns'

import type { FrontProjectStateType } from '@miimosa/common/types'

type Props = {
  status: FrontProjectStateType
  teasing: boolean
}

export const getProjectStatus = ({
  status,
  teasing,
}: Props): {
  isSoon: boolean
  isDone: boolean
  isSuccess: boolean
} => {
  const isSoon = !!teasing
  const isDone = status === 'failed' || status === 'funded'
  const isSuccess = status === 'funded'

  return {
    isSoon,
    isDone,
    isSuccess,
  }
}

export function getProjectStatusString(
  isDone: boolean,
  isSoon: boolean,
  isSuccess: boolean,
  endsAt: Date | undefined,
  tCommon: (key: string, param?: any) => string,
  now: Date = new Date()
) {
  if (isSoon || !endsAt) {
    return tCommon('project_status.soon')
  }
  if (isDone || (endsAt && now > endsAt)) {
    return tCommon('project_status.done')
  }
  if (isSuccess) {
    return tCommon('project_status.success')
  }

  let remaining = differenceInDays(endsAt, now)
  let key = 'short_relative_time.day'
  if (remaining == 0) {
    remaining = differenceInHours(endsAt, now)
    key = 'short_relative_time.hour'
  }
  return tCommon(key, { val: remaining })
}

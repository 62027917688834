import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Euro',
  viewBox: '0 0 20 20',
  path: (
    <>
      <path
        d="M12.36 6.21C11.8905 6.01781 11.3873 5.92133 10.88 5.92622C10.3727 5.9311 9.87147 6.03726 9.40574 6.23845C8.94001 6.43965 8.51915 6.73183 8.16785 7.09786C7.81656 7.46389 7.5419 7.8964 7.36 8.37H6.59C6.4574 8.37 6.33022 8.42268 6.23645 8.51645C6.14268 8.61022 6.09 8.73739 6.09 8.87C6.09256 9.00181 6.14607 9.1275 6.23928 9.22072C6.3325 9.31394 6.4582 9.36744 6.59 9.37H7.09C7.04415 9.57743 7.01407 9.78803 7 10C7.00417 10.2112 7.02423 10.4218 7.06 10.63H6.56C6.4282 10.6326 6.3025 10.6861 6.20928 10.7793C6.11606 10.8725 6.06256 10.9982 6.06 11.13C6.06 11.2626 6.11268 11.3898 6.20645 11.4836C6.30022 11.5773 6.4274 11.63 6.56 11.63H7.34C7.62734 12.342 8.11843 12.9533 8.75172 13.3873C9.38501 13.8214 10.1323 14.0589 10.9 14.07C11.2937 14.0704 11.685 14.0097 12.06 13.89C12.1844 13.8466 12.2871 13.7568 12.3468 13.6394C12.4064 13.522 12.4183 13.386 12.38 13.26C12.3608 13.1969 12.329 13.1383 12.2866 13.0877C12.2442 13.0372 12.192 12.9957 12.1332 12.9658C12.0743 12.936 12.0101 12.9183 11.9442 12.9138C11.8784 12.9094 11.8123 12.9183 11.75 12.94C11.4744 13.0239 11.1881 13.0677 10.9 13.07C10.403 13.0636 9.91641 12.9273 9.48839 12.6747C9.06037 12.4221 8.70585 12.0619 8.46 11.63H9.8C9.93261 11.63 10.0598 11.5773 10.1536 11.4836C10.2473 11.3898 10.3 11.2626 10.3 11.13C10.2974 10.9982 10.2439 10.8725 10.1507 10.7793C10.0575 10.6861 9.93181 10.6326 9.8 10.63H8.10001C8.00667 10.2152 8.00667 9.78481 8.10001 9.37H9.8C9.93181 9.36744 10.0575 9.31394 10.1507 9.22072C10.2439 9.1275 10.2974 9.00181 10.3 8.87C10.3 8.73739 10.2473 8.61022 10.1536 8.51645C10.0598 8.42268 9.93261 8.37 9.8 8.37H8.49C8.73585 7.93806 9.09037 7.57792 9.51839 7.32532C9.94641 7.07272 10.433 6.93644 10.93 6.93C11.2972 6.92733 11.6611 6.99876 12 7.14C12.1223 7.18713 12.258 7.18537 12.379 7.1351C12.5001 7.08483 12.5971 6.9899 12.65 6.87C12.6963 6.74384 12.692 6.60464 12.6379 6.48159C12.5839 6.35854 12.4843 6.26121 12.36 6.21Z"
        fill="currentColor"
      />
    </>
  ),
})

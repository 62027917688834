import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Bruxelles',
  viewBox: '0 0 27 27',
  path: (
    <>
      <path
        d="M25.5299 12.6453C25.4361 11.8945 25.3288 11.1303 25.0473 10.5002C24.7791 9.92367 24.3233 9.38739 23.8809 8.86451L20.4889 4.88259C18.6521 2.73744 16.8153 0.779995 14.4422 0.498445C13.9328 0.444816 13.4099 0.458224 12.8468 0.471631C11.9753 0.498445 11.0636 0.525261 10.1117 0.257118C9.14641 -0.0110255 8.61012 -0.118284 7.85932 0.176674C7.256 0.404595 6.6929 0.80681 6.18342 1.3431C5.86165 1.67827 5.5801 2.05367 5.25833 2.44248C4.62819 3.2335 3.97125 4.05134 3.07297 4.66807C2.93889 4.76192 2.81823 4.82896 2.69756 4.9094C2.42942 5.08369 2.17469 5.24458 2.0138 5.41887C1.69203 5.82109 1.70544 6.46463 1.73225 7.21543C1.74566 7.71149 1.77247 8.23437 1.67862 8.75725C1.54455 9.64212 1.14233 10.3795 0.753527 11.1035C0.378126 11.8007 0.0295437 12.4576 0.00272941 13.1012C-0.0643064 14.4017 1.11552 15.5815 2.21491 16.6004C4.0785 18.3031 6.07617 19.9656 8.27494 19.8316C8.34198 19.8316 8.3956 19.8182 8.46264 19.8182C8.61012 19.8047 8.771 19.7913 8.93189 19.7913C9.22685 19.7913 9.56203 19.8316 9.8838 19.979C10.5542 20.2874 10.9027 20.9041 11.1441 21.36L11.1575 21.3868C12.163 23.2236 13.3831 24.5777 14.764 25.3821C16.5337 26.4279 19.2554 26.4413 20.328 24.7922C20.4486 24.6179 20.5559 24.4034 20.6631 24.1889C20.824 23.8671 21.0117 23.4917 21.2799 23.1565C21.9771 22.2583 22.996 21.8158 23.9077 21.4136C24.7389 21.0516 25.5165 20.7164 25.9053 20.0997C26.3344 19.4159 26.2539 18.4104 26.1199 17.3512L25.5299 12.6453Z"
        fill="fill"
      />
    </>
  ),
})

import { Flex, Container, Text, Heading } from '@chakra-ui/react'

import { s3ImageURL } from '@miimosa/common'

import Image from '@components/Image'

const Hero = () => {
  return (
    <Container variant="boxed" py={{ base: 0, md: 0 }}>
      <Flex
        h="505px"
        w="full"
        justifyContent="center"
        bgImage={s3ImageURL('m6-hero-high.jpg')}
        bgRepeat="no-repeat"
        position="relative"
        backgroundSize="cover"
        bgPosition="center"
      >
        <Flex h="306px" w="546px" position="relative">
          <Image src={s3ImageURL('CNA-logo.png')} alt="logo" fill style={{ objectFit: 'cover' }} />
        </Flex>
      </Flex>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        w="full"
        h={{ base: 'auto', md: '140px' }}
        alignItems="center"
        my={{ base: 6, md: 14 }}
      >
        <Flex w={{ base: 'full', md: '50%' }} mb={{ base: 6, md: 0 }}>
          <Heading size="2xl">Cultivons notre avenir</Heading>
        </Flex>
        <Flex w={{ base: 'full', md: '50%' }}>
          <Text size="lg">
            M6 Publicité et MiiMOSA s’associent pour soutenir les agriculteurs dans leur transition agricole aux côtés
            de 3 partenaires engagés (La Provençale Bio, GRDF et Harrys). Cet appel à projets inédit « Cultivons notre
            avenir » a permis d’accompagner, de valoriser et de soutenir financièrement des projets défendant une
            agriculture plus durable et responsable.
          </Text>
        </Flex>
      </Flex>
    </Container>
  )
}

export default Hero

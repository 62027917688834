import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Flex,
  Text,
  ListItem,
  UnorderedList,
  Heading,
} from '@chakra-ui/react'
import { ReactNode, useState } from 'react'

import { Icon } from '@miimosa/design-system/components'

const Faq = () => {
  const [indexOpened, setIndexOpened] = useState<number>(0)
  const qs = questions()

  return (
    <Container variant="boxed" py={{ base: 0, md: 0 }}>
      <Heading> FAQ</Heading>
      <Accordion
        onChange={(index) => setIndexOpened(index as number)}
        w="full"
        maxW="995px"
        allowToggle
        borderColor="transparent"
        defaultIndex={0}
        zIndex="1"
      >
        {qs.map(({ title, text }, i) => {
          const isOpen = i === indexOpened
          return <QuestionItem key={`${title}_${i}`} isOpen={isOpen} title={title} text={text} />
        })}
      </Accordion>
    </Container>
  )
}

const QuestionItem = ({ title, text, isOpen }: { title: string; text: ReactNode; isOpen: boolean }) => {
  return (
    <>
      <AccordionItem id={title}>
        <AccordionButton
          borderTopLeftRadius="4px"
          borderTopRightRadius="4px"
          borderBottomLeftRadius={isOpen ? 0 : '4px'}
          borderBottomRightRadius={isOpen ? 0 : '4px'}
          _hover={{
            bg: 'white',
          }}
          _focus={{
            outline: 0,
            boxShadow: 'none',
          }}
          _expanded={{ bg: 'white', color: '#000' }}
          borderColor="miimosa"
          bgColor="white"
          py="20px"
        >
          <Flex justify="space-between" align="center" flex="1 1 0%">
            <Text size="xl" textAlign="left" pr="4px">
              {title}
            </Text>
            <Icon name={isOpen ? 'ChevronUp' : 'ChevronDown'} color="black" size="4xs" />
          </Flex>
        </AccordionButton>
        <AccordionPanel bgColor="white" pr="8" borderBottomLeftRadius="4px" borderBottomRightRadius="4px">
          {text}
        </AccordionPanel>
      </AccordionItem>
      <Box w="full" borderBottom="1px solid rgba(0, 0, 0, 0.2)" />
    </>
  )
}

const questions = () => {
  return [
    {
      title: 'Qui a pu postuler à l’appel à projets ?',
      text: (
        <>
          <Text size="lg" mb={4}>
            Les agriculteurs pouvant participer à l’opération ont dû répondre aux critères suivants :
          </Text>
          <UnorderedList>
            <ListItem>
              <Text>Être installé(e) depuis au moins 2 ans sur le territoire français</Text>
            </ListItem>
            <ListItem>
              <Text>
                Être engagé(e) dans des démarches de transition économique, sociale et/ou environnementale : sécurité et
                souveraineté alimentaire, emplois et territoires, santé et bien-être, énergies renouvelables, cohésion
                sociale, ressources naturelles, climat, biodiversité, etc.
              </Text>
            </ListItem>
          </UnorderedList>
        </>
      ),
    },
    {
      title: 'Comment ont été sélectionnés les 4 projets lauréats ?',
      text: (
        <>
          <Text size="lg" mb={4}>
            La sélection des projets lauréats a eu lieu en deux temps : 20 projets ont été pré-sélectionnés par les
            équipes MiiMOSA et présentés au jury qui a sélectionné les 4 projets lauréats selon les critères suivants :
          </Text>
          <UnorderedList>
            <ListItem>
              <Text>Réussite de la collecte de financement participatif</Text>
            </ListItem>
            <ListItem>
              <Text>Répartition géographique des projets</Text>
            </ListItem>
            <ListItem>
              <Text>Parité des porteurs/ses de projets</Text>
            </ListItem>
            <ListItem>
              <Text>Besoin de financement</Text>
            </ListItem>
            <ListItem>
              <Text>Impacts du projet</Text>
            </ListItem>
            <Text mt={2}>
              Le jury a été particulièrement attentif aux 3 thématiques suivantes : production d’énergie verte et
              renouvelable à partir de déchets agricoles (méthanisation), mise en place de pratiques agroécologiques
              pour préserver la biodiversité en filière céréales et innovation au service d’une filière biologique plus
              efficace.
            </Text>
          </UnorderedList>
        </>
      ),
    },
    {
      title: 'Quels ont été les bénéfices en tant que projets lauréats ?',
      text: (
        <>
          <Text size="lg" mb={4}>
            Les 4 projets lauréats bénéficient :
          </Text>
          <UnorderedList>
            <ListItem>
              <Text>
                D&apos;une dotation financière de 20 000€ de la part de M6 Publicité et des 3 partenaires de l’appel à
                projets
              </Text>
            </ListItem>
            <ListItem>
              <Text>
                D&apos;une mise en avant du projet au sein d’un programme court* “Cultivons Notre Avenir” parrainé par
                les 3 partenaires de l’appel à projets tourné durant l’été 2023 et diffusé sur M6 et la plateforme 6play
                dès la rentrée 2023 (août à novembre).**
              </Text>
            </ListItem>
            <Flex direction="column" mt={2}>
              <Text size="sm">
                *programme court : portraits des lauréats avec un état des lieux du projet et des principaux enjeux. 2
                épisodes d’une minute par lauréat sont diffusés sur M6 dès la rentrée 2023 sous réserve de la
                programmation.
              </Text>
              <Text size="sm">
                **diffusion du programme “Cultivons Notre Avenir” sur la chaîne M6 les samedis vers 20H15 et les
                dimanches vers 12H35. Les épisodes sont aussi disponibles sur la plateforme 6play. Dates estimatives
                sous réserve de la diffusion de la prochaine saison de l’Amour est dans le Pré.
              </Text>
            </Flex>
          </UnorderedList>
        </>
      ),
    },
    {
      title: 'Quels ont été les engagements des porteurs de projet ?',
      text: (
        <>
          <Text size="lg" mb={4}>
            Le porteur de projet sélectionné s’est engagé à :
          </Text>
          <UnorderedList>
            <ListItem>
              <Text>Réaliser le projet si la collecte est réussie</Text>
            </ListItem>
            <ListItem>
              <Text>Accepter d’être contacté(e) par M6 Publicité ou les partenaires de l’appel à projets</Text>
            </ListItem>
            <ListItem>
              <Text>Accepter d’être identifié(e) comme porteur de projet participant à l’appel à projets</Text>
            </ListItem>
            <ListItem>
              <Text>Accepter d’être filmé(e) durant l’été 2023 par les équipes M6 Publicité</Text>
            </ListItem>
          </UnorderedList>
        </>
      ),
    },
    {
      title: 'Qu’est ce que le financement participatif ?',
      text: (
        <>
          <Text size="lg">
            C’est un mode de financement qui permet de collecter de l’argent auprès du grand public pour financer ses
            projets.
          </Text>
          <Text size="lg" mb={4}>
            MiiMOSA accompagne les acteurs des secteurs agricoles et alimentaires à travers ses offres de financement :
          </Text>
          <UnorderedList>
            <ListItem>
              <Text>
                Don avec contrepartie : pour financer des projets jusqu’à 150 000€ en remerciant les contributeurs avec
                de jolies contreparties (produits, services, expériences)
              </Text>
            </ListItem>
            <ListItem>
              <Text>
                Prêt participatif : pour financer des projets de transition agricole, alimentaire ou énergétique jusqu’à
                2 000 000€ en remboursant les épargnants à partir de 2% d’intérêts (aucune garantie et caution
                personnelle demandées)
              </Text>
            </ListItem>
          </UnorderedList>
        </>
      ),
    },
  ]
}
export default Faq

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'CheckMark',
  viewBox: '0 0 12 8',
  path: (
    <path
      d="M4.04967 7.48994C3.91567 7.47969 3.79056 7.41893 3.69967 7.31994L0.149666 3.31994C0.0688505 3.2196 0.0298843 3.09194 0.040892 2.96358C0.0518997 2.83521 0.112032 2.71605 0.20875 2.63094C0.305468 2.54583 0.431307 2.50134 0.560028 2.50674C0.68875 2.51214 0.810419 2.56702 0.899666 2.65994L4.11967 6.27994L10.2597 0.779937C10.3046 0.732106 10.3588 0.693984 10.419 0.667924C10.4792 0.641864 10.5441 0.628418 10.6097 0.628418C10.6753 0.628418 10.7402 0.641864 10.8004 0.667924C10.8606 0.693984 10.9148 0.732106 10.9597 0.779937C11.0062 0.82556 11.0432 0.880016 11.0685 0.940115C11.0937 1.00021 11.1067 1.06475 11.1067 1.12994C11.1067 1.19513 11.0937 1.25966 11.0685 1.31976C11.0432 1.37986 11.0062 1.43431 10.9597 1.47994L4.43967 7.31994C4.39073 7.37468 4.33055 7.41821 4.26324 7.44755C4.19593 7.47689 4.12308 7.49135 4.04967 7.48994Z"
      fill="currentColor"
    />
  ),
})

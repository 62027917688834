import { Heading, Box, Text, Container, Flex, BoxProps } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { FC, useEffect, useMemo, useState } from 'react'

import { extractString } from '@miimosa/common'
import { useMountedBreakpoints, usePlatform } from '@miimosa/design-system'

import { QueryFilter, useFilter, SearchBar } from '@components'
import getFilters, { formatQueryFilters } from '@helpers/getFilters'

interface Props {
  query: Record<string, string | string[] | undefined>
  projectsCount: number
  withSearchBar?: boolean
}

const HeadFilter: FC<Props & BoxProps> = ({ query, withSearchBar, projectsCount }) => {
  const { platform } = usePlatform()
  const { pathname, push } = useRouter()
  const { t } = useTranslation('projects', { keyPrefix: 'headFilter' })
  const { t: tCommon } = useTranslation('common')
  const [scroll, setScroll] = useState(false)
  const isMobile = useMountedBreakpoints({ base: true, md: false }, false)
  const filtersInit = getFilters(tCommon)
  const keyword = extractString(query['keyword'])
  const filtersCategoriesToKeep = useMemo(() => ['type', 'order', 'region'], [])
  const filtersData = useMemo(
    () => filtersInit?.filter(({ id }) => filtersCategoriesToKeep.includes(id)),
    [filtersInit, filtersCategoriesToKeep]
  )
  const formattedFilters = formatQueryFilters(query, platform)
  const { filters, addFilter, addRadioFilter, removeFilter, clearFilters } = useFilter(formattedFilters)
  const typeAsString = query.type ? (Array.isArray(query.type) ? undefined : query.type) : undefined

  const onSubmitSearch = (e: React.FormEvent) => {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      keyword: { value: string }
    }

    const newQuery = {
      ...query,
      ...filters,
      page: 1,
      keyword: target?.keyword?.value,
    }
    const url = { pathname, query: newQuery }

    return push(url)
  }

  const handleClearInput = () => {
    const newRoute = { pathname, query: { ...query, page: 1, keyword: '' } }
    push(newRoute, undefined, { scroll: false })
  }

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 72 && window.scrollY < (isMobile ? 5000 : 1300))
    })
  }, [isMobile])

  return (
    <>
      <Container variant="full" display="flex" flexDirection="column">
        <Container variant="boxed" py={8}>
          <Flex
            align={{ base: 'flex-start', md: 'center' }}
            direction={{ base: 'column', md: 'row' }}
            width="100%"
            justifyContent="space-evenly"
            px={{ base: 2, md: 0 }}
            rowGap={8}
          >
            <Flex align="center">
              <Heading as="h1" size="xl" maxW="500px" color="green">
                {typeAsString ? tCommon(typeAsString as string) : tCommon('undefined_type')}
              </Heading>
            </Flex>
            <Text size="md" maxW={{ base: '100%', md: '311px' }} color="green">
              {typeAsString ? t(typeAsString as string) : t('undefined_type')}
            </Text>
          </Flex>
        </Container>
        <Box width="full" height="0.5px" bgColor="light_border" />
      </Container>
      <Container
        variant="full"
        bgColor="white"
        pos={scroll ? 'sticky' : 'initial'}
        top={scroll ? '90px' : ''}
        zIndex={scroll ? 2 : ''}
        borderTop="1px solid"
        borderBottom="1px solid"
        borderColor="light_border"
      >
        <Container variant="boxed" py={8} flexDirection={isMobile ? 'column' : 'row'} justifyContent="flex-start">
          <Flex align="center">
            <QueryFilter
              filters={filters}
              addRadioFilter={addRadioFilter}
              addFilter={addFilter}
              clearFilters={clearFilters}
              removeFilter={removeFilter}
              filtersData={filtersData}
              totalResults={projectsCount}
              showResults={false}
              width="fit-content"
            />
          </Flex>
          {withSearchBar && (
            <SearchBar
              w={{ base: '100%', lg: '90%' }}
              h="40px"
              my="10px"
              ml={{ base: '0', lg: '40px' }}
              onSubmit={onSubmitSearch}
              defaultValue={keyword ?? ''}
              allowEmptyValue
              placeholder={t('search-p')}
              onClearInput={handleClearInput}
            />
          )}
        </Container>
      </Container>
    </>
  )
}

export default HeadFilter

import { Flex, Button } from '@chakra-ui/react'

import { Icon } from '../../../components'

const ClearIndicator = ({ clearValue }: { clearValue: () => void }) => {
  return (
    <Flex bgColor="white" align="center" borderRadius="100%" justify="center" p="12px" mr="4px" mb="2px">
      <Button variant="ghost" onClick={clearValue}>
        <Icon name="Close" size="4xs" />
      </Button>
    </Flex>
  )
}

export default ClearIndicator

import { BoxProps, Box, Text, Flex, Link, useDisclosure } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import NextLink from 'next/link'
import { FC, SyntheticEvent } from 'react'

import type { FrontProjectCardType } from '@miimosa/common/types'
import { getProjectStatus } from '@miimosa/design-system/helpers/projects'

import { Image, Tag, Person, ProjectProgressBar, ProjectProgress } from '@components'

import WarnedModal from './WarnedModal'

const ProjectCard: FC<FrontProjectCardType & BoxProps> = ({
  imageFileName,
  interestsRatio,
  slug,
  title,
  teasing,
  goalAmount,
  goalQuantity,
  place,
  endsAt,
  goalType,
  collectType,
  collectedAmount,
  collectedQuantity,
  progress,
  userFullName,
  avatarFileName,
  labels,
  state,
  isHorizontal = false,
  ...props
}) => {
  const { t } = useTranslation('common')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isSuccess, isSoon, isDone } = getProjectStatus({
    status: state,
    teasing: teasing,
  })
  const projectLink = `/projects/${slug}`

  const openWarnedModal = (e: SyntheticEvent) => {
    e.preventDefault()
    onOpen()
  }

  return (
    <>
      <NextLink href={projectLink}>
        <Flex
          width={{ base: '100%' }}
          direction={isHorizontal ? 'row' : 'column'}
          pointerEvents={teasing ? 'none' : 'auto'}
          {...props}
          height="586px"
        >
          <Flex
            position="relative"
            overflow="hidden"
            borderTopLeftRadius="4px"
            borderTopRightRadius={isHorizontal ? '0' : '4px'}
            borderBottomLeftRadius={isHorizontal ? '4px' : '0'}
            borderBottomRightRadius="0"
            borderLeftWidth="0.5px"
            borderRightWidth="0.5px"
            borderTopWidth="0.5px"
            borderColor="border"
            width="100%"
            bgColor="dark_green"
            height={isHorizontal ? '314px' : '200px'}
          >
            {imageFileName && (
              <Image src={imageFileName} fill style={{ objectFit: 'cover' }} alt="" quality={100} priority />
            )}
          </Flex>
          <Flex
            direction="column"
            bgColor="white"
            borderStyle="solid"
            borderTopWidth={isHorizontal ? '0.5px' : '0'}
            borderRightWidth="0.5px"
            borderBottomWidth="0.5px"
            borderLeftWidth={isHorizontal ? '0' : '0.5px'}
            borderColor="border"
            borderBottomLeftRadius="4px"
            borderBottomRightRadius="4px"
            width="100%"
            py={isHorizontal ? 4 : 6}
            px={6}
            height="386px"
          >
            <ProjectProgress
              isDone={isDone}
              endsAt={endsAt}
              isSoon={isSoon}
              isSuccess={isSuccess}
              collectType={collectType}
              progress={progress || 0}
              interestsRatio={interestsRatio}
            />
            <Text
              size="lg"
              overflow="hidden"
              height={isHorizontal ? '42px' : '72px'}
              maxH={isHorizontal ? '42px' : '72px'}
              textOverflow="ellipsis"
              my="4"
            >
              {title}
            </Text>
            <Flex
              flexWrap="wrap"
              overflow="hidden"
              maxHeight={isHorizontal ? '48px' : '56px'}
              minH={isHorizontal ? '48px' : '56px'}
              mb={4}
              columnGap={1}
              rowGap={1}
            >
              {labels && labels.map((label, index) => <Tag key={`_${index}`} title={label.value} />)}
            </Flex>
            {!teasing && (
              <>
                <ProjectProgressBar
                  collectedAmount={collectedAmount}
                  progress={progress || 0}
                  goalType={goalType}
                  collectType={collectType}
                  isSuccess={isSuccess}
                  isDone={isDone}
                  goalAmount={goalAmount}
                  collectedQuantity={collectedQuantity}
                  goalQuantity={goalQuantity}
                />
                <Box border="none" borderTop="1px dotted" borderColor="border" my={isHorizontal ? 4 : 5} />
                {userFullName && <Person isSmall imageUrl={avatarFileName} fullName={userFullName} place={place} />}
              </>
            )}
            {teasing && (
              <>
                <Box
                  border="none"
                  borderTop="1px dotted"
                  borderColor="border"
                  mb={isHorizontal ? 4 : 5}
                  mt={isHorizontal ? 4 : 16}
                />
                <Link as="button" size="sm" onClick={openWarnedModal} href="" color="orange" pointerEvents="auto">
                  <Text size="sm">{t('get_warned_project')}</Text>
                </Link>
              </>
            )}
          </Flex>
        </Flex>
      </NextLink>
      <WarnedModal onClose={onClose} title={title} slug={slug} isOpen={isOpen} />
    </>
  )
}
export default ProjectCard

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'HealthWellBeingBig',
  viewBox: '0 0 100 100',
  path: (
    <>
      <path
        d="M45.7 83.2C44.2 83.2 42.7 81.7 41.6 80.5C41.5 80.3 41.3 80.2 41.2 80.1L17.2 55.3C10.7 48.8 8.09997 41 10.2 33.5C12.1 26.5 17.6 21 24.6 19.1C31.9 17.1 39.3 19.4 45.7 25.4C51.9 19.6 61.7 15.6 71.3 20.9C79.3 25.3 83.4 34.3 81.3 43.1C81.2 43.6 80.6 44 80.1 43.9C79.6 43.8 79.2 43.2 79.3 42.7C81.1 34.8 77.5 26.7 70.2 22.7C67.3 21.1 57 16.7 46.2 27.5L45.5 28.2L44.8 27.5C38.8 21.5 31.7 19.2 24.9 21C18.6 22.7 13.6 27.7 12 34C10.2 40.8 12.5 47.9 18.5 53.9L42.4 78.7C42.5 78.8 42.7 79 42.8 79.1C43.5 79.8 44.8 81.2 45.5 81.2C46.3 81.2 46.5 81 48.5 79.1L48.6 79L60 67.1C60.4 66.7 61 66.7 61.4 67.1C61.8 67.5 61.8 68.1 61.4 68.5L49.9 80.5C48.1 82.5 47.4 83.2 45.7 83.2Z"
        fill="black"
      />
      <path
        d="M63.2999 65.8C63.1999 65.8 63.1999 65.8 63.0999 65.8C62.6999 65.7 62.4 65.5 62.2999 65.1L59.7 56.7L55.7 62.6C55.5 62.9 55.1999 63.1 54.7999 63L46.7 62.7C46.1 62.7 45.7 62.2 45.7 61.7C45.7 61.1 46.2 60.7 46.7 60.7L54.2 60.9L59.0999 53.8C59.2999 53.5 59.6999 53.3 60.0999 53.4C60.4999 53.5 60.8 53.7 60.9 54.1L63.5999 62.7L77.5 45.8C77.7 45.5 78.1 45.4 78.4 45.4C78.8 45.5 78.9999 45.7 79.1999 46L84.4 59.4L88.1999 54.9C88.5999 54.5 89.1999 54.4 89.5999 54.8C90 55.2 90.0999 55.8 89.6999 56.2L84.8 62C84.6 62.3 84.2 62.4 83.9 62.3C83.6 62.2 83.2999 62 83.0999 61.7L78 48.4L64 65.4C63.7999 65.7 63.5999 65.8 63.2999 65.8Z"
        fill="currentColor"
      />
    </>
  ),
})

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'MiddleBrush3',
  viewBox: '0 0 15 35',
  path: (
    <>
      <path
        d="M14.3881 8.15312C14.1832 8.20044 13.9624 8.17944 13.7785 8.00603L5.9844 1.67422C5.71636 1.44827 5.60596 0.970058 5.90022 0.686248C6.12617 0.418211 6.60438 0.307806 6.88819 0.602072L14.6823 6.93388C14.9503 7.15983 15.0607 7.63805 14.7665 7.92186C14.6456 8.02172 14.5247 8.12158 14.3881 8.15312Z"
        fill="#FF6C4A"
      />
      <path
        d="M5.11504 33.7522L12.5926 27.4925C12.8869 27.2087 13.3283 27.2507 13.5438 27.5607C13.7593 27.8708 13.7856 28.2965 13.4756 28.5119L5.99801 34.7716C5.94547 34.8557 5.80884 34.8873 5.6722 34.9188C5.39894 34.9819 5.16244 34.8926 4.97849 34.7192C4.77877 34.4774 4.82077 34.036 5.11504 33.7522Z"
        fill="#FF6C4A"
      />
      <path
        d="M1.63464 18.3652C1.27728 18.3758 0.909387 18.029 0.898843 17.6716C0.888299 17.3142 1.23511 16.9464 1.59246 16.9358L11.3724 16.9086C11.7298 16.8981 12.0977 17.2449 12.1082 17.6022C12.1188 17.9596 11.8928 18.2276 11.5512 18.3065C11.4829 18.3223 11.4829 18.3223 11.4146 18.338L1.63464 18.3652Z"
        fill="#FF6C4A"
      />
    </>
  ),
})

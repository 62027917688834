import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Garbage',
  viewBox: '0 0 16 16',
  path: (
    <>
      <g>
        <path
          fill="currentColor"
          d="M11.24,16.2H4.96c-1.08,0-1.98-.81-2.09-1.89l-.87-8.65c-.03-.33,.21-.62,.54-.66,.34-.03,.62,.21,.66,.54l.87,8.65c.05,.46,.43,.81,.89,.81h6.29c.46,0,.85-.35,.9-.81l.87-8.65c.03-.33,.33-.56,.66-.54,.33,.03,.57,.33,.54,.66l-.87,8.65c-.11,1.08-1,1.89-2.09,1.89Z"
        />
        <path
          fill="currentColor"
          d="M15.6,4.2H.6c-.33,0-.6-.27-.6-.6s.27-.6,.6-.6H5V.6c0-.33,.27-.6,.6-.6h5c.33,0,.6,.27,.6,.6V3h4.4c.33,0,.6,.27,.6,.6s-.27,.6-.6,.6ZM6.2,3h3.8V1.2h-3.8v1.8Z"
        />
      </g>
      <path
        fill="currentColor"
        d="M8.1,14.33c-.33,0-.6-.27-.6-.6V5.6c0-.33,.27-.6,.6-.6s.6,.27,.6,.6V13.73c0,.33-.27,.6-.6,.6Z"
      />
    </>
  ),
})

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Arrow',
  viewBox: '0 0 841.9 595.3',
  path: (
    <g>
      <rect
        x="188"
        y="64.6"
        transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 841.8898 595.2756)"
        fill="#00694F"
        width="466"
        height="466"
      />
      <g>
        <path
          fill="#FFFFFF"
          d="M471.3,307.3l-81.4,81.4c-5.3,5.3-13.9,5.3-19.2,0c-5.3-5.3-5.3-13.9,0-19.2l71.8-71.9l-71.8-71.9
			c-5.3-5.3-5.3-13.9,0-19.2c5.3-5.3,13.9-5.3,19.2,0l81.4,81.4C476.6,293.3,476.6,301.9,471.3,307.3L471.3,307.3z"
        />
      </g>
    </g>
  ),
})

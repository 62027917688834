import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'TadaTopLeftTwo',
  viewBox: '0 0 48 48',
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3665 7.06547C19.6285 4.76344 18.1723 2.71197 16.6849 0.12809C18.3916 1.89771 19.5503 3.4324 20.7246 5.2333C24.1693 9.30495 28.6475 12.7816 32.8752 16.5402C33.4546 17.3075 35.0518 17.2137 34.8638 18.5604C34.6758 19.907 33.8772 19.9539 33.3448 19.9852C33.376 20.5176 33.423 21.3163 32.6087 21.097C31.5282 20.8933 30.9333 19.8598 30.0877 19.1081C27.5511 16.8529 25.2494 14.0498 22.6971 11.5284C19.2993 8.25541 16.6689 4.40305 14.0228 0.28448C15.62 0.190646 15.667 0.989269 15.9801 1.77225C16.2776 2.28903 16.8256 2.52396 17.1231 3.04074C17.6868 3.54187 17.9999 4.32486 18.5636 4.82599C17.9687 3.79244 17.1075 2.77453 16.5125 1.74098C16.2307 1.49041 16.1681 0.425575 16.4656 0.942352C18.4385 2.69633 19.6441 5.02965 21.3665 7.06547Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1093 35.957C7.35908 35.0874 4.98371 34.2589 2.27441 33.0144C4.69073 33.4681 6.52424 34.0478 8.52471 34.8354C13.6913 36.1586 19.3556 36.3981 24.979 37.0125C25.8958 37.3023 27.1433 36.3005 27.7703 37.5071C28.3972 38.7137 27.7734 39.2146 27.3575 39.5485C27.6915 39.9644 28.1924 40.5882 27.4016 40.8811C26.403 41.3411 25.3192 40.8433 24.1946 40.7204C20.8205 40.3518 17.3205 39.4004 13.7795 38.8238C9.1138 38.1244 4.73796 36.5082 0.195153 34.6841C1.44271 33.6823 1.94361 34.3061 2.65244 34.7629C3.1943 35.0118 3.77711 34.8858 4.31898 35.1347C5.06876 35.2166 5.77759 35.6734 6.52737 35.7553C5.44365 35.2575 4.15201 34.9267 3.06829 34.429C2.69339 34.388 2.02552 33.5563 2.56738 33.8052C5.19163 34.0919 7.52604 35.2954 10.1093 35.957Z"
        fill="currentColor"
      />
    </>
  ),
  defaultProps: {
    w: '48px',
    h: '48px',
    color: 'orange',
    top: '-13px',
    left: '-32px',
    position: 'absolute',
    zIndex: 1,
  },
})

import {
  Text,
  Flex,
  Checkbox,
  Box,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Button,
  Drawer,
  AccordionIcon,
  DrawerOverlay,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  RadioGroup,
  Radio,
} from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { LeftArrow } from '@miimosa/design-system'

type Filter = {
  label: string
  value: string
}

const FilterListItem = ({
  id,
  onClick,
  value,
  isSelected,
  label,
}: {
  id: string
  isSelected: boolean
  onClick: (id: string, value: string) => void
  value: string
  label: string
}) => {
  return (
    <Button mt="3" variant="ghost" onClick={() => onClick(id, value)}>
      <Checkbox
        pointerEvents="none"
        onClick={() => onClick(id, value)}
        isChecked={isSelected}
        size="sm"
        colorScheme="checkbox"
        mr="2"
      />
      <Text size="sm">{label}</Text>
    </Button>
  )
}

const FilterListItemRadio = ({
  id,
  onClick,
  value,
  label,
}: {
  id: string
  onClick: (id: string, value: string) => void
  value: string
  label: string
}) => {
  return (
    <Button display="flex" mt="3" variant="ghost" onClick={() => onClick(id, value)}>
      <Radio colorScheme="radio" value={value} onClick={() => onClick(id, value)} mr="2">
        <Text size="sm">{label}</Text>
      </Radio>
    </Button>
  )
}

const FilterDrawer = ({
  filters,
  filtersData,
  isOpen,
  addFilter,
  addRadioFilter,
  removeFilter,
  onClose,
}: {
  filters: {
    [id: string]: string[]
  }
  filtersData: { id: string; title: string; filters: Filter[]; isRadio: boolean }[]
  isOpen: boolean
  onClose: () => void
  removeFilter: (cat: string, value: string) => void
  addFilter: (cat: string, value: string) => void
  addRadioFilter: (cat: string, value: string) => void
}) => {
  const { pathname, query, push } = useRouter()
  const { t } = useTranslation('common', { keyPrefix: 'queryFilter' })
  const onPushFilters = () => {
    const filter = { ...query, ...filters }
    const url = { pathname, query: filter }
    push(url)
    return onClose()
  }

  return (
    <Drawer isOpen={isOpen} size="md" placement="left" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Button variant="ghost" onClick={onClose}>
            <LeftArrow color="black" mr="2" width="12px" />
            <Text size="sm">{t('return')}</Text>
          </Button>
        </DrawerHeader>
        <DrawerBody mt="8">
          <Flex direction="column" rowGap={6}>
            <Accordion defaultIndex={0} allowToggle>
              {filtersData.map(({ id, title, filters: filtersItems, isRadio }) => {
                return (
                  <AccordionItem key={id} border="none" borderBottom="solid 0.5px black" borderWidth="0.5px !important">
                    <AccordionButton
                      px={0}
                      _hover={{ bgColor: 'transparent' }}
                      _expanded={{ borderBottom: 'solid 0.5px black' }}
                    >
                      <Box flex="1" textAlign="left">
                        <Text size="sm">{title}</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} px={0} pl="2px">
                      <Flex direction="column" align="flex-start">
                        {isRadio ? (
                          <>
                            <RadioGroup value={filters[id]?.[0]}>
                              {filtersItems.map(({ value, label }, index) => {
                                return (
                                  <FilterListItemRadio
                                    onClick={addRadioFilter}
                                    id={id}
                                    value={value}
                                    label={label}
                                    key={index}
                                  />
                                )
                              })}
                            </RadioGroup>
                          </>
                        ) : (
                          filtersItems.map(({ value, label }, index) => {
                            const categoryFilters = filters[id]
                            const isSelected = categoryFilters?.includes(value)

                            return (
                              <FilterListItem
                                onClick={isSelected ? removeFilter : addFilter}
                                id={id}
                                isSelected={isSelected}
                                value={value}
                                label={label}
                                key={index}
                              />
                            )
                          })
                        )}
                      </Flex>
                    </AccordionPanel>
                  </AccordionItem>
                )
              })}
            </Accordion>
          </Flex>
          <Button size="md" variant="primary" bgColor="dark_green" mt={4} onClick={onPushFilters}>
            <Text size="md" color="white">
              {t('validate')}
            </Text>
          </Button>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default FilterDrawer

import { Flex, BoxProps, Input } from '@chakra-ui/react'
// import SearchRadius from './SearchRadius'
import { useTranslation } from 'next-i18next'
import logger from 'pino'
import { FC, useRef, useState } from 'react'
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete'

import type { Coordinates } from '@miimosa/common/types'

import useFocusExit from '../../lib/hooks/use_focus_exit'
import useMountedBreakpoints from '../../lib/hooks/use_mounted_breakpoints'
import { ClearIndicator, DropdownIndicator } from '../Select/components'

import SearchSuggestions from './SearchSuggestions'

interface Props {
  translationKey: string
  onSelectCoordinates: (coordinates: Coordinates) => void
}

const MapSelect: FC<Props & BoxProps> = ({ translationKey, onSelectCoordinates, ...props }) => {
  const { t } = useTranslation(translationKey, { keyPrefix: 'projectsMap' })
  const placeholder = useMountedBreakpoints(
    {
      base: t('searchPlaceholderMobile'),
      lg: t('searchPlaceholder'),
    },
    t('searchPlaceholderMobile')
  )
  const inputRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  // temporary fix waiting for radius
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false)
  // const [showRadius, setShowRadius] = useState<boolean>(false)

  const {
    value,
    suggestions: { loading, status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 300,
  })

  const onBlur = useFocusExit(containerRef, (_, inside) => {
    if (!inside) {
      return clearSuggestions()
    }
  })

  const clearInput = () => setValue('')
  const handleInput = (e: any) => {
    // setShowRadius(false)
    setShowSuggestions(true)
    setValue(e?.target?.value)
  }

  const handleDropdown = () => {
    if (inputRef?.current) {
      inputRef.current.focus()
    }
    return null
  }
  const handleSelect = ({ description }: { description: string }) => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, true)
    clearSuggestions()
    setShowSuggestions(false)
    // setShowRadius(true)

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        onSelectCoordinates({ lat, lng })
      })
      .catch((error) => {
        logger().error(error, 'no place found')
      })
  }

  return (
    <Flex
      ref={containerRef}
      direction="column"
      position="relative"
      w={{ base: '100%', md: 'auto' }}
      minW={{ base: '100%', md: '50%' }}
      onBlur={onBlur}
      {...props}
    >
      <Flex flex="1 1 0%" borderBottom="solid 2px" borderColor="dark_green" justifyContent="space-between">
        <Flex
          flex="3 1 0%"
          // TODO: fix blur
        >
          <Input
            ref={inputRef}
            name="searchProjects"
            variant="unstyled"
            type="search"
            height="48px"
            value={value}
            onChange={handleInput}
            placeholder={placeholder}
            maxLength={90}
            px="8px"
          />
        </Flex>
        <Flex flex="1 1 0%" justifyContent="flex-end">
          {value && <ClearIndicator clearValue={clearInput} />}
          <DropdownIndicator isFocused onClick={handleDropdown} />
        </Flex>
      </Flex>

      {status === 'OK' && showSuggestions && (
        <SearchSuggestions loading={loading} data={data} handleSelect={handleSelect} />
      )}
      {/* {status === 'OK' && showRadius && (
        <SearchRadius
          clearInput={clearInput}
          onChangeRadius={onChangeRadius}
          closeRadius={() => setShowRadius(false)}
        />
      )} */}
    </Flex>
  )
}

export default MapSelect

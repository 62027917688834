import { createIcon } from '@chakra-ui/icons'

export const PartnerMiimosa = createIcon({
  displayName: 'PartnerMiimosa',
  viewBox: '0 0 155 40',
  path: (
    <>
      <path
        d="M86.688 27.3934C86.7817 23.9419 87.8117 20.9046 90.1526 18.4196C92.1658 16.2106 95.0218 14.9681 98.0182 14.9681C101.67 14.876 104.807 16.0265 107.288 18.7417C109.021 20.6285 110.051 23.0675 110.238 25.5986C110.612 28.866 109.676 32.1794 107.616 34.8025C103.824 39.6346 97.3627 40.509 92.2595 37.4257C89.0758 35.4928 87.3903 32.6396 86.8285 29.0501C86.7348 28.4058 86.688 27.8536 86.688 27.3934ZM105.322 27.0712C105.322 25.6446 105.135 24.218 104.76 22.8835C104.245 20.9506 103.356 19.2019 101.811 17.9133C99.8909 16.2566 97.0818 16.1186 94.975 17.5912C93.7109 18.5576 92.8213 19.8922 92.4468 21.4108C91.5572 24.4481 91.4636 27.6235 92.119 30.7068C92.4467 32.5016 93.2427 34.1583 94.4131 35.4928C96.4732 37.7938 99.6568 38.208 102.045 36.4132C103.356 35.4008 104.292 34.0202 104.666 32.4555C105.135 30.6608 105.322 28.866 105.322 27.0712Z"
        fill="currentColor"
      />
      <path
        d="M120.304 39.0824C118.15 39.0364 116.043 38.4381 114.218 37.3797C112.766 36.5053 111.643 35.3088 111.315 33.6521C111.081 32.9157 111.174 32.1334 111.596 31.4891C112.204 30.6148 113.141 30.3847 114.218 30.5687C115.107 30.6608 115.856 31.213 116.184 31.9954C116.465 32.6856 116.699 33.3759 116.839 34.1123C117.588 37.5177 121.24 38.254 123.722 37.2876C125.267 36.6894 126.11 35.0326 125.735 33.468C125.501 32.1794 124.658 31.3051 123.628 30.4767C122.27 29.3722 120.632 28.728 119.134 27.8996C117.588 27.1633 116.09 26.2429 114.686 25.2305C113.515 24.3101 112.579 23.2056 112.345 21.779C111.923 19.524 112.766 17.7293 114.779 16.3947C116.09 15.6124 117.588 15.1061 119.134 15.0141C121.662 14.6459 124.237 15.0601 126.531 16.2106C128.029 16.9929 129.106 18.0974 129.387 19.7541C129.48 20.2143 129.434 20.7205 129.293 21.2268C129.012 22.2392 128.17 22.6073 126.859 22.4693C125.829 22.3773 124.939 21.6409 124.752 20.6285C124.611 20.0763 124.471 19.524 124.284 18.9718C123.628 16.9469 121.521 16.2566 119.976 16.3487C119.649 16.3487 119.368 16.3947 119.04 16.4867C116.512 16.993 116.043 19.6621 117.167 21.3188C117.682 22.0551 118.384 22.6534 119.18 23.1136C120.913 24.126 122.692 25.0004 124.377 26.0128C125.595 26.7031 126.718 27.4854 127.842 28.2678C129.2 29.2342 130.089 30.7068 130.276 32.3635C130.464 34.6185 129.246 36.7814 127.186 37.7938C125.126 38.8063 122.879 39.0824 120.304 39.0824Z"
        fill="currentColor"
      />
      <path
        d="M51.8548 33.7441C51.8548 34.4804 51.9952 35.1707 52.2761 35.861C52.4166 36.1831 52.6507 36.5053 52.838 36.7814C53.0721 37.1035 53.3998 37.3336 53.6339 37.6097C53.9148 38.0239 53.8212 38.7142 53.2125 38.7602C52.4166 38.8063 51.6207 38.8063 50.8248 38.8063C49.2797 38.8063 47.8752 38.8063 46.2833 38.8063C45.7683 38.8063 45.4874 38.5301 45.5342 38.0699C45.581 37.9319 45.6279 37.7478 45.7683 37.6558C45.862 37.5177 46.0024 37.4257 46.1429 37.3336C46.9856 36.7814 47.407 35.815 47.3602 34.8486C47.3133 33.2379 47.3602 31.4431 47.3602 29.8324C47.3602 29.0501 47.2197 28.2678 46.8451 27.5314C45.9556 25.9668 44.1765 25.0924 42.3505 25.3685C40.431 25.5986 38.9796 27.2093 38.9328 29.0961C38.8391 31.121 38.8859 33.2839 38.8859 35.3088C38.8859 35.861 39.12 36.3672 39.4946 36.7814C39.8691 37.1956 40.4778 37.4257 40.6182 37.9779C40.6651 38.116 40.6651 38.254 40.6651 38.3921C40.6182 38.6682 40.3373 38.8523 40.0564 38.8523H38.6987C36.8259 38.8523 35.0936 38.8523 33.2209 38.8523C32.8463 38.8523 32.5654 38.8063 32.4249 38.4381C32.2845 38.116 32.4249 37.7478 32.7058 37.5637C33.5486 36.8734 34.1104 35.907 34.3445 34.8486C34.3913 34.4804 34.4381 34.0662 34.4381 33.6981C34.4381 29.9245 34.4381 26.1509 34.4381 22.4233C34.4381 21.2268 34.9532 20.9046 36.0768 21.4108C37.4346 22.0551 38.7455 22.7454 40.1501 23.3437C42.0696 24.2641 44.3169 24.2641 46.2365 23.3437C47.6411 22.6994 48.9988 22.0091 50.4034 21.3648C51.3398 20.9046 51.9016 21.2728 51.9016 22.2852L51.8548 33.7441Z"
        fill="currentColor"
      />
      <path
        d="M49.5138 13.8636C51.012 13.8636 52.1825 14.646 52.5571 15.8425C53.1189 17.7293 51.9016 19.386 49.7947 19.524C48.1561 19.6621 46.7515 18.7417 46.4706 17.3151C46.0492 15.4283 47.407 13.8636 49.5138 13.8636Z"
        fill="currentColor"
      />
      <path
        d="M36.6854 13.8636C38.1837 13.8636 39.3541 14.646 39.7287 15.8425C40.2905 17.7293 39.0732 19.386 36.9195 19.524C35.2809 19.6621 33.8763 18.7417 33.5954 17.3151C33.2208 15.4283 34.5786 13.8636 36.6854 13.8636Z"
        fill="currentColor"
      />
      <path
        d="M29.6158 37.5177C29.4285 37.3797 29.2412 37.2416 29.054 37.1035C28.4921 36.6894 28.1176 36.0911 28.0708 35.4468C27.6962 33.56 27.743 31.6732 27.743 29.7404C27.743 27.7615 27.743 25.7367 27.743 23.7578C27.743 22.2392 27.743 20.7205 27.743 19.2019C27.743 18.1434 27.9303 17.177 28.8199 16.4407C29.1008 16.2566 29.1944 15.8885 29.054 15.5663C28.9135 15.2442 28.5858 15.1982 28.3049 15.1982C27.0876 15.1982 26.2917 15.1982 25.0744 15.1982C24.2316 15.1061 23.4357 15.6124 23.1548 16.4407L16.4597 31.121L15.7106 32.7317L12.246 25.4606C10.8414 22.3773 9.39003 19.34 7.98546 16.3027C7.75137 15.6584 7.0959 15.2442 6.44043 15.2442C5.08269 15.1982 3.72494 15.2442 2.36719 15.2442C1.71173 15.2442 1.43081 15.7044 1.75854 16.2566C1.899 16.4867 2.08628 16.6708 2.27355 16.8089C2.69492 17.177 2.92902 17.7293 2.92902 18.2815L2.8822 34.1123C2.8822 35.5389 2.50765 36.8274 1.05626 37.5177C0.775348 37.6558 0.681711 37.9779 0.775349 38.254C0.868986 38.5302 1.10308 38.7142 1.38399 38.7142C2.83538 38.7142 4.28677 38.7142 5.69133 38.7142C6.01907 38.7142 6.29998 38.4841 6.3468 38.162C6.3468 38.07 6.3468 38.0239 6.29998 37.9319C6.20634 37.7018 6.06589 37.5177 5.87861 37.4257C5.08269 36.9195 4.66132 36.0451 4.70814 35.1247L4.56768 20.7205L12.2928 36.5973C12.5737 37.1956 12.9014 37.7938 13.3228 38.3461C13.791 38.9903 14.3996 38.9443 14.9147 38.3461C15.1487 38.0239 15.3828 37.6558 15.5701 37.2876L23.108 20.4904L23.3421 35.2628C23.4357 36.1371 23.0611 37.0115 22.312 37.5177C21.9843 37.7018 21.797 38.07 21.9375 38.4381C22.0779 38.8523 22.4525 38.8983 22.827 38.8983C24.5593 38.8983 25.8235 38.8983 27.5558 38.8983H29.2412C29.569 38.9443 29.8967 38.7602 29.9903 38.4381C30.0372 38.0239 29.8967 37.6558 29.6158 37.5177Z"
        fill="currentColor"
      />
      <path
        d="M85.1898 37.5177C85.0025 37.3796 84.8153 37.2416 84.628 37.1035C84.0662 36.6894 83.6916 36.0911 83.6448 35.4468C83.2702 33.56 83.3171 31.6732 83.3171 29.7404C83.3171 27.7615 83.3171 25.7827 83.3171 23.7578C83.3171 22.2392 83.3171 20.7205 83.3171 19.2019C83.3171 18.1434 83.5043 17.177 84.3939 16.4407C84.6748 16.2566 84.7685 15.8885 84.628 15.5663C84.4875 15.2442 84.2066 15.1982 83.8789 15.1982C82.6616 15.1982 81.8657 15.1982 80.6484 15.1982C79.8057 15.1522 79.0097 15.6584 78.7288 16.4407L71.9869 31.121L71.2378 32.7317L67.7732 25.4606C66.3686 22.3772 64.9172 19.34 63.5127 16.3027C63.2786 15.6584 62.6231 15.2442 61.9676 15.2442C60.6099 15.1982 59.2521 15.2442 57.8944 15.2442C57.2389 15.2442 56.958 15.7044 57.3326 16.2566C57.473 16.4407 57.6603 16.6708 57.8476 16.8089C58.269 17.177 58.5031 17.7293 58.5031 18.2815L58.4562 34.1123C58.4562 35.5389 58.0817 36.8274 56.6303 37.5177C56.3494 37.6558 56.2089 37.9779 56.3494 38.254C56.3962 38.5301 56.6771 38.7142 57.0048 38.7142C58.4562 38.7142 59.9076 38.7142 61.3122 38.7142C61.6399 38.7142 61.9208 38.4841 61.9676 38.116C61.9676 38.0239 61.9676 37.9779 61.9208 37.8859C61.8272 37.6558 61.6867 37.4717 61.4526 37.3796C60.6567 36.8734 60.2354 35.9991 60.2822 35.0787L60.1417 20.6745L67.8668 36.5513C68.1478 37.1496 68.4755 37.7478 68.8969 38.3C69.365 38.9443 69.9737 38.8983 70.4887 38.3C70.7228 37.9779 70.9569 37.6097 71.1442 37.2416L78.7288 20.4444L78.9629 35.2167C79.0565 36.0911 78.6352 36.9195 77.9329 37.4257C77.6052 37.6097 77.4179 37.9779 77.5583 38.3461C77.6988 38.7602 78.0733 38.8063 78.4479 38.8063C80.1802 38.8063 81.4443 38.8063 83.1766 38.8063H84.8621C85.2366 38.8523 85.5644 38.6222 85.658 38.254C85.6112 37.9319 85.4239 37.6558 85.1898 37.5177Z"
        fill="currentColor"
      />
      <path
        d="M154.06 37.6097C153.124 37.0575 152.375 36.2752 151.813 35.3548C151.485 34.7565 151.298 34.0662 151.017 33.468C150.736 32.8697 150.455 32.1334 150.128 31.4891C149.144 29.1882 148.114 26.8872 147.131 24.5862C146.242 22.5613 145.352 20.5365 144.463 18.5116C144.182 17.8673 143.901 17.223 143.62 16.5788C143.386 15.7504 142.543 15.1982 141.653 15.2442H140.015C139.032 15.2442 138.517 15.6584 138.657 16.5328C138.704 16.9009 138.797 17.177 138.891 17.5452C138.938 17.9133 138.938 18.3275 138.891 18.6957C138.751 19.6161 138.517 20.4904 138.142 21.3648V21.4108C138.095 21.5489 138.002 21.733 137.955 21.871L137.159 23.8499C136.691 25.0924 136.176 26.2889 135.707 27.5314L134.256 31.167L133.086 34.1123C132.945 34.5264 132.758 34.8946 132.617 35.3088C132.477 35.6769 132.29 36.0451 132.056 36.3672C131.775 36.6433 131.494 36.8734 131.166 37.0575C130.932 37.2416 130.698 37.4257 130.417 37.6097C130.136 37.8398 130.089 38.208 130.276 38.5301C130.417 38.7142 130.604 38.8063 130.791 38.8063H135.099C135.473 38.8063 135.754 38.5301 135.754 38.162C135.754 37.9779 135.661 37.7478 135.52 37.6558L135.099 37.3336C134.256 36.6894 134.35 35.5849 134.631 34.8025L135.286 33.1458H135.52C136.737 33.0998 137.955 33.0078 139.172 32.7777C141.232 32.4555 143.198 31.7653 145.024 30.7068L145.071 30.8449L146.054 33.0538C146.288 33.606 146.569 34.1123 146.803 34.6645C147.225 35.6769 147.131 36.8274 146.148 37.4717C145.867 37.6097 145.68 37.8859 145.68 38.208C145.68 38.5762 145.961 38.8523 146.335 38.8523H153.592C153.967 38.8523 154.248 38.5301 154.248 38.208C154.295 37.9319 154.201 37.7478 154.06 37.6097ZM135.942 31.5352L136.176 30.9829L138.563 24.6782L138.844 23.8959L139.266 22.8374C139.312 22.6994 139.359 22.5613 139.453 22.3772L140.296 20.1683L141.607 22.8374L141.794 23.2056C142.168 24.034 142.543 24.8623 142.918 25.6907L144.463 29.1882C141.841 30.9829 137.721 31.4891 135.942 31.5352Z"
        fill="currentColor"
      />
      <path
        d="M4.9169 5.05313C4.63969 5.59433 4.26168 5.92233 3.61487 5.92233C2.81685 5.92233 2.32964 5.41393 2.28764 4.60212H5.40411C5.41251 4.55292 5.41251 4.45452 5.41251 4.38072C5.41251 3.18352 4.70689 2.37991 3.54767 2.37991C2.40525 2.37991 1.55683 3.22452 1.55683 4.44632C1.55683 5.65993 2.43045 6.51273 3.61487 6.51273C4.57249 6.51273 5.1353 5.99613 5.45451 5.38113L4.9169 5.05313ZM3.53927 2.96212C4.24488 2.96212 4.63129 3.34752 4.69009 4.05272H2.30444C2.39685 3.37212 2.86726 2.96212 3.53927 2.96212Z"
        fill="currentColor"
      />
      <path
        d="M6.43667 6.41433H7.13389V4.04452C7.13389 3.34752 7.6379 2.97032 8.27631 2.97032C8.93993 2.97032 9.28433 3.34752 9.28433 4.05272V6.41433H9.98155V3.97892C9.98155 2.99492 9.42714 2.37991 8.43591 2.37991C7.8479 2.37991 7.41109 2.59311 7.13389 2.97032V2.47831H6.43667V6.41433Z"
        fill="currentColor"
      />
      <path
        d="M13.1142 8.13634H13.8114V5.79113C14.1138 6.20933 14.5926 6.51273 15.2898 6.51273C16.365 6.51273 17.163 5.59433 17.163 4.44632C17.163 3.30652 16.365 2.37991 15.2898 2.37991C14.5926 2.37991 14.1138 2.68331 13.8114 3.10972V2.47831H13.1142V8.13634ZM15.1386 5.92233C14.391 5.92233 13.8114 5.28273 13.8114 4.44632C13.8114 3.60992 14.391 2.97032 15.1386 2.97032C15.945 2.97032 16.4322 3.54432 16.4322 4.44632C16.4322 5.35653 15.945 5.92233 15.1386 5.92233Z"
        fill="currentColor"
      />
      <path
        d="M19.3706 6.51273C19.841 6.51273 20.2946 6.29953 20.5886 5.90593V6.41433H21.2858V3.94612C21.2858 2.92932 20.6138 2.37991 19.6478 2.37991C18.7238 2.37991 18.0602 2.89652 17.8501 3.73292L18.4802 3.90512C18.6314 3.29012 18.9842 2.97032 19.6394 2.97032C20.2358 2.97032 20.5886 3.34752 20.5886 3.97072V4.48732C20.3282 4.24952 19.9166 4.01992 19.3706 4.01992C18.4718 4.01992 17.8249 4.57752 17.8249 5.26633C17.8249 6.03713 18.4718 6.51273 19.3706 6.51273ZM19.4378 5.94693C18.9086 5.94693 18.5642 5.66813 18.5642 5.26633C18.5642 4.87272 18.9086 4.59392 19.4378 4.59392C19.925 4.59392 20.3366 4.85632 20.5886 5.14333V5.29093C20.3198 5.66813 19.925 5.94693 19.4378 5.94693Z"
        fill="currentColor"
      />
      <path
        d="M22.4823 6.41433H23.1795V4.47912C23.1795 3.60172 23.5995 3.01952 24.1204 3.01952C24.5152 3.01952 24.7 3.22452 24.7 3.69192C24.7 3.79032 24.6832 3.89692 24.6664 4.01172L25.3216 4.07732C25.3888 3.87232 25.414 3.69192 25.414 3.51972C25.414 2.83912 24.9268 2.41271 24.322 2.41271C23.7423 2.41271 23.3895 2.69151 23.1795 3.14252V2.47831H22.4823V6.41433Z"
        fill="currentColor"
      />
      <path
        d="M27.5407 6.51273C28.1371 6.51273 28.6411 6.06993 28.9183 5.41393L28.3471 5.15153C28.1203 5.64353 27.8851 5.89773 27.5659 5.89773C27.2383 5.89773 27.0955 5.69273 27.0955 5.25813V3.06872H28.7251V2.47831H27.0955V1.30571H26.541L26.3982 2.47831H25.7346V3.06872H26.3982V5.24173C26.3982 6.11093 26.7847 6.51273 27.5407 6.51273Z"
        fill="currentColor"
      />
      <path
        d="M32.6112 5.05313C32.334 5.59433 31.956 5.92233 31.3092 5.92233C30.5112 5.92233 30.024 5.41393 29.982 4.60212H33.0984C33.1068 4.55292 33.1068 4.45452 33.1068 4.38072C33.1068 3.18352 32.4012 2.37991 31.242 2.37991C30.0996 2.37991 29.2512 3.22452 29.2512 4.44632C29.2512 5.65993 30.1248 6.51273 31.3092 6.51273C32.2668 6.51273 32.8296 5.99613 33.1488 5.38113L32.6112 5.05313ZM31.2336 2.96212C31.9392 2.96212 32.3256 3.34752 32.3844 4.05272H29.9988C30.0912 3.37212 30.5616 2.96212 31.2336 2.96212Z"
        fill="currentColor"
      />
      <path
        d="M34.131 6.41433H34.8282V4.04452C34.8282 3.34752 35.3322 2.97032 35.9706 2.97032C36.6343 2.97032 36.9787 3.34752 36.9787 4.05272V6.41433H37.6759V3.97892C37.6759 2.99492 37.1215 2.37991 36.1302 2.37991C35.5422 2.37991 35.1054 2.59311 34.8282 2.97032V2.47831H34.131V6.41433Z"
        fill="currentColor"
      />
      <path
        d="M40.1577 6.51273C40.6281 6.51273 41.0817 6.29953 41.3758 5.90593V6.41433H42.073V3.94612C42.073 2.92932 41.401 2.37991 40.4349 2.37991C39.5109 2.37991 38.8473 2.89652 38.6373 3.73292L39.2673 3.90512C39.4185 3.29012 39.7713 2.97032 40.4265 2.97032C41.0229 2.97032 41.3758 3.34752 41.3758 3.97072V4.48732C41.1153 4.24952 40.7037 4.01992 40.1577 4.01992C39.2589 4.01992 38.6121 4.57752 38.6121 5.26633C38.6121 6.03713 39.2589 6.51273 40.1577 6.51273ZM40.2249 5.94693C39.6957 5.94693 39.3513 5.66813 39.3513 5.26633C39.3513 4.87272 39.6957 4.59392 40.2249 4.59392C40.7121 4.59392 41.1237 4.85632 41.3758 5.14333V5.29093C41.1069 5.66813 40.7121 5.94693 40.2249 5.94693Z"
        fill="currentColor"
      />
      <path
        d="M43.2695 6.41433H43.9667V4.47912C43.9667 3.60172 44.3867 3.01952 44.9075 3.01952C45.3023 3.01952 45.4871 3.22452 45.4871 3.69192C45.4871 3.79032 45.4703 3.89692 45.4535 4.01172L46.1087 4.07732C46.1759 3.87232 46.2011 3.69192 46.2011 3.51972C46.2011 2.83912 45.7139 2.41271 45.1091 2.41271C44.5295 2.41271 44.1767 2.69151 43.9667 3.14252V2.47831H43.2695V6.41433Z"
        fill="currentColor"
      />
      <path
        d="M47.2972 1.67471C47.566 1.67471 47.776 1.47791 47.776 1.22371C47.776 0.969506 47.566 0.772705 47.2972 0.772705C47.0284 0.772705 46.8183 0.969506 46.8183 1.22371C46.8183 1.47791 47.0284 1.67471 47.2972 1.67471ZM46.9528 6.41433H47.65V2.47831H46.9528V6.41433Z"
        fill="currentColor"
      />
      <path
        d="M50.1575 6.51273C50.628 6.51273 51.0816 6.29953 51.3756 5.90593V6.41433H52.0728V3.94612C52.0728 2.92932 51.4008 2.37991 50.4348 2.37991C49.5107 2.37991 48.8471 2.89652 48.6371 3.73292L49.2671 3.90512C49.4183 3.29012 49.7711 2.97032 50.4263 2.97032C51.0228 2.97032 51.3756 3.34752 51.3756 3.97072V4.48732C51.1152 4.24952 50.7036 4.01992 50.1575 4.01992C49.2587 4.01992 48.6119 4.57752 48.6119 5.26633C48.6119 6.03713 49.2587 6.51273 50.1575 6.51273ZM50.2247 5.94693C49.6955 5.94693 49.3511 5.66813 49.3511 5.26633C49.3511 4.87272 49.6955 4.59392 50.2247 4.59392C50.712 4.59392 51.1236 4.85632 51.3756 5.14333V5.29093C51.1068 5.66813 50.712 5.94693 50.2247 5.94693Z"
        fill="currentColor"
      />
      <path
        d="M54.5377 6.51273C55.1341 6.51273 55.6381 6.06993 55.9153 5.41393L55.3441 5.15153C55.1173 5.64353 54.8821 5.89773 54.5629 5.89773C54.2353 5.89773 54.0925 5.69273 54.0925 5.25813V3.06872H55.7221V2.47831H54.0925V1.30571H53.5381L53.3953 2.47831H52.7317V3.06872H53.3953V5.24173C53.3953 6.11093 53.7817 6.51273 54.5377 6.51273Z"
        fill="currentColor"
      />
      <path
        d="M59.7964 6.51273C60.2668 6.51273 60.7204 6.29953 61.0144 5.90593V6.41433H61.7117V3.94612C61.7117 2.92932 61.0396 2.37991 60.0736 2.37991C59.1496 2.37991 58.486 2.89652 58.276 3.73292L58.906 3.90512C59.0572 3.29012 59.41 2.97032 60.0652 2.97032C60.6616 2.97032 61.0144 3.34752 61.0144 3.97072V4.48732C60.754 4.24952 60.3424 4.01992 59.7964 4.01992C58.8976 4.01992 58.2508 4.57752 58.2508 5.26633C58.2508 6.03713 58.8976 6.51273 59.7964 6.51273ZM59.8636 5.94693C59.3344 5.94693 58.99 5.66813 58.99 5.26633C58.99 4.87272 59.3344 4.59392 59.8636 4.59392C60.3508 4.59392 60.7624 4.85632 61.0144 5.14333V5.29093C60.7456 5.66813 60.3508 5.94693 59.8636 5.94693Z"
        fill="currentColor"
      />
      <path
        d="M63.8842 6.41433H64.6906L66.3118 2.47831H65.5642L64.3042 5.74193L63.0105 2.47831H62.2629L63.8842 6.41433Z"
        fill="currentColor"
      />
      <path
        d="M69.9772 5.05313C69.7 5.59433 69.322 5.92233 68.6752 5.92233C67.8772 5.92233 67.39 5.41393 67.348 4.60212H70.4644C70.4728 4.55292 70.4728 4.45452 70.4728 4.38072C70.4728 3.18352 69.7672 2.37991 68.608 2.37991C67.4656 2.37991 66.6172 3.22452 66.6172 4.44632C66.6172 5.65993 67.4908 6.51273 68.6752 6.51273C69.6328 6.51273 70.1956 5.99613 70.5148 5.38113L69.9772 5.05313ZM68.5996 2.96212C69.3052 2.96212 69.6916 3.34752 69.7504 4.05272H67.3648C67.4572 3.37212 67.9276 2.96212 68.5996 2.96212Z"
        fill="currentColor"
      />
      <path
        d="M73.2022 6.51273C74.1515 6.51273 74.6891 5.97153 75.0167 5.33193L74.4791 4.97932C74.1935 5.55333 73.8491 5.92233 73.2022 5.92233C72.3958 5.92233 71.9254 5.34833 71.9254 4.44632C71.9254 3.53612 72.3874 2.97032 73.1686 2.97032C73.7147 2.97032 74.2019 3.24912 74.4119 4.21672L75.0503 4.00352C74.8571 2.89652 74.0843 2.37991 73.1686 2.37991C72.0262 2.37991 71.1946 3.26552 71.1946 4.44632C71.1946 5.74193 72.0262 6.51273 73.2022 6.51273Z"
        fill="currentColor"
      />
    </>
  ),
})

export default PartnerMiimosa

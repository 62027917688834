import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'LogoCo2',
  viewBox: '0 0 512 512',
  path: (
    <>
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
        <path
          fill="currentColor"
          d="M2895,4815c-485-88-878-452-1003-928c-23-90-27-98-46-92c-62,21-199,45-251,44c-350-1-660-244-745-585l-10-41
		l-82-8c-293-25-548-197-679-456c-64-128-79-200-79-377c0-174,11-232,66-352c42-91,68-120,106-120c31,0,78,39,78,64c0,8-18,52-40,98
		c-53,112-73,193-73,308c0,213,101,421,265,545c132,100,267,145,439,145c118,0,123,4,149,128c83,406,501,615,877,439
		c60-27,97-25,117,7c8,11,21,64,30,117c59,362,295,673,630,830c359,168,777,134,1109-90c42-28,85-51,95-51c36,0,72,38,72,76
		c0,29-8,40-56,78c-124,97-341,190-518,221C3239,4833,2996,4834,2895,4815z"
        />
        <path
          fill="currentColor"
          d="M4032,4364c-40-16-51-54-28-98c9-17,41-69,71-115c57-87,102-193,138-321c18-65,21-103,22-291
		c0-164,3-220,13-232c8-9,39-21,70-27c316-62,563-291,643-598c29-111,29-279,0-391c-60-232-216-424-426-527c-174-85-53-78-1466-82
		l-1256-2l-5,114c-5,99-9,123-32,170c-34,69-99,128-167,149c-71,23-489,25-566,2c-64-19-136-82-170-149c-23-44-27-66-31-170l-5-119
		l-76,6c-109,9-232,53-326,116c-61,42-125,14-125-54c0-30,6-40,43-66c103-75,264-132,412-145l75-7v-203v-204h-62c-35,0-81-5-103-11
		c-72-20-135-107-135-184c1-64,66-134,459-499c105-97,177-129,302-134c113-5,177,10,256,60c28,18,156,132,283,252
		c273,257,287,279,259,381c-14,51-73,110-124,124c-22,6-68,11-102,11h-63v205v205h1209c813,0,1235,4,1287,11c377,52,683,318,789,685
		c22,77,25,104,25,259s-3,182-25,259c-61,212-201,408-374,525c-79,54-208,115-281,133l-55,14l4,115c5,184-25,349-94,517
		c-59,146-169,313-213,324C4071,4375,4049,4372,4032,4364z M1606,1950c53-50,54-55,54-510v-421l25-24c23-24,30-25,138-25
		c89,0,117-3,127-15c7-8,10-21,7-29c-3-7-106-109-229-225c-257-244-289-265-403-266c-110,0-148,25-405,265C800,813,699,913,695,923
		c-14,38,11,47,132,47c108,0,115,1,138,25l25,24v426c0,457,0,454,52,501c12,12,30,24,38,27s124,5,257,4l241-2L1606,1950z"
        />
        <path
          fill="currentColor"
          d="M1932,3280c-334-88-501-470-339-773c95-179,275-287,475-287c62,0,110,6,155,20c67,21,171,89,201,131
		c36,52,6,119-55,119c-23,0-44-12-80-44c-76-68-183-97-285-76c-128,27-238,111-291,225c-25,54-28,71-28,165c0,93,3,111,27,163
		c35,73,116,155,193,193c55,26,69,29,165,29c101,0,108-1,184-38c69-34,81-37,106-27c16,7,34,25,41,42c18,44-2,73-76,114
		C2211,3300,2068,3315,1932,3280z"
        />
        <path
          fill="currentColor"
          d="M2945,3288c-75-14-186-72-248-128c-73-67-120-140-153-236c-24-72-26-89-22-189c6-123,24-182,83-272
		c100-151,243-232,424-241c171-8,289,36,407,153c109,107,164,235,164,382c0,190-109,379-271,471C3221,3289,3073,3312,2945,3288z
		 M3222,3117c84-41,152-107,190-185c31-62,33-73,33-172c0-94-3-111-28-166c-72-156-254-254-419-225c-173,30-299,156-329,329
		c-33,184,89,378,274,438c26,9,76,13,133,11C3151,3145,3176,3140,3222,3117z"
        />
        <path
          fill="currentColor"
          d="M3856,2837c-22-12-53-39-70-60c-25-31-30-47-31-89c0-43,4-53,26-70c38-29,77-14,105,38c28,54,61,71,99,53
		c67-33,48-84-105-294c-113-154-124-174-111-208c19-49,33-52,213-52h170l25,29c18,21,23,36,18,55c-11,48-39,61-127,61
		c-61,0-79,3-75,13c2,7,31,48,62,92c32,44,71,108,88,143c39,83,36,160-9,224C4074,2857,3944,2888,3856,2837z"
        />
      </g>
    </>
  ),
})

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'People',
  viewBox: '0 0 100 100',
  path: (
    <>
      <path
        d="M78.1 64.8C81.6 63.5 84.2 60 84.2 56C84.2 50.8 80 46.6 74.8 46.6C69.6 46.6 65.4 50.8 65.4 56C65.4 59.9 67.7 63.2 71.1 64.6C68.2 65.1 65.7 66.5 63.8 68.6C61.4 66.3 58.2 64.4 54 63.6C59.4 62 63.3 57 63.3 51C63.3 43.7 57.3 37.7 50 37.7C42.7 37.7 36.7 43.7 36.7 51C36.7 56.8 40.4 61.7 45.6 63.5C41.3 64.1 38.1 65.8 35.7 68.1C33.8 66.3 31.6 65.2 28.9 64.7C32.3 63.3 34.6 59.9 34.6 56.1C34.6 50.9 30.4 46.7 25.2 46.7C20 46.7 15.8 50.9 15.8 56.1C15.8 60.1 18.3 63.5 21.9 64.9C13.7 66.7 10.9 74.2 10 79C9.90003 79.5 10.2 80.1 10.8 80.2C10.9 80.2 10.9 80.2 11 80.2C11.5 80.2 11.9 79.9 12 79.4C14.1 68.8 20.5 66.5 25.6 66.5H25.8C25.8 66.5 25.8 66.5 25.9 66.5C29.4 66.5 32.2 67.6 34.3 69.6C30 74.6 28.7 81.4 28.3 85.2C28.2 85.7 28.7 86.2 29.2 86.3H29.3C29.8 86.3 30.2 85.9 30.3 85.4C31 77.9 34.4 65.4 49 65.3H49.1H49.4C64.1 65.3 68.2 77.9 69.4 85.4C69.5 85.9 70 86.3 70.5 86.2C71 86.1 71.4 85.6 71.3 85.1C70.7 81.4 69.2 75.1 65 70.1C67.2 67.7 70.2 66.5 74 66.4C74 66.4 74 66.4 74.1 66.4H74.3C79.4 66.4 85.9 68.7 88 79.3C88.1 79.8 88.5 80.1 89 80.1C89.1 80.1 89.1 80.1 89.2 80.1C89.7 80 90.1 79.5 90 78.9C89 74.2 86.3 66.6 78.1 64.8ZM38.7 51C38.7 44.8 43.8 39.7 50 39.7C56.2 39.7 61.3 44.8 61.3 51C61.3 57.2 56.2 62.3 50 62.3C43.8 62.3 38.7 57.2 38.7 51ZM17.8 56.1C17.8 52 21.1 48.7 25.2 48.7C29.3 48.7 32.6 52 32.6 56.1C32.6 60.2 29.3 63.5 25.2 63.5C21.1 63.5 17.8 60.1 17.8 56.1ZM67.4 56.1C67.4 52 70.7 48.7 74.8 48.7C78.9 48.7 82.2 52 82.2 56.1C82.2 60.2 78.9 63.5 74.8 63.5C70.7 63.5 67.4 60.1 67.4 56.1Z"
        fill="#2A2A2A"
      />
      <path
        d="M28.8 38C28.6 38 28.4 37.9 28.2 37.8L16.9 29C16.5 28.7 16.4 28 16.7 27.6C17 27.2 17.7 27.1 18.1 27.4L29.4 36.2C29.8 36.5 29.9 37.2 29.6 37.6C29.4 37.9 29.1 38 28.8 38Z"
        fill="#FF6C4A"
      />
      <path
        d="M69.8 38C69.5 38 69.2 37.9 69 37.6C68.7 37.2 68.7 36.5 69.2 36.2L80.5 27.4C80.9 27.1 81.6 27.1 81.9 27.6C82.2 28 82.2 28.7 81.7 29L70.4 37.8C70.2 37.9 70 38 69.8 38Z"
        fill="#FF6C4A"
      />
      <path
        d="M57.9 30.1C57.7 30.1 57.6 30.1 57.4 30C56.9 29.8 56.6 29.2 56.8 28.7L61.5 15.7C61.7 15.2 62.3 14.9 62.8 15.1C63.3 15.3 63.6 15.9 63.4 16.4L58.7 29.4C58.6 29.8 58.3 30 57.9 30.1Z"
        fill="#FF6C4A"
      />
      <path
        d="M42 30.8C41.5 30.8 41.1 30.5 41 30L37.9 16.4C37.8 15.9 38.1 15.3 38.7 15.2C39.2 15.1 39.8 15.4 39.9 16L43 29.6C43.1 30.1 42.8 30.7 42.2 30.8C42.2 30.8 42.1 30.8 42 30.8Z"
        fill="#FF6C4A"
      />
    </>
  ),
})

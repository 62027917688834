import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'LivingStandardsBig',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d="M28.7001 47.3C18.4001 47.3 10.1001 39 10.1001 28.7C10.1001 18.4 18.4001 10.1 28.7001 10.1C39.0001 10.1 47.3001 18.4 47.3001 28.7C47.3001 39 38.9001 47.3 28.7001 47.3ZM28.7001 12.1C19.5001 12.1 12.1001 19.5 12.1001 28.7C12.1001 37.9 19.5001 45.3 28.7001 45.3C37.9001 45.3 45.3001 37.9 45.3001 28.7C45.3001 19.5 37.8001 12.1 28.7001 12.1Z"
        fill="black"
      />
      <path
        d="M28.7 40.9C24.4 40.9 21 37.8 21 33.9C21 33.3 21.4 32.9 22 32.9C22.6 32.9 23 33.3 23 33.9C23 36.6 25.6 38.9 28.7 38.9C31.8 38.9 34.4 36.7 34.4 33.9C34.4 33.3 34.8 32.9 35.4 32.9C36 32.9 36.4 33.3 36.4 33.9C36.4 37.7 32.9 40.9 28.7 40.9Z"
        fill="currentColor"
      />
      <path
        d="M30.9 67.8V88H12V67.8H30.9ZM32.2 65.8H10.6C10.3 65.8 10 66.1001 10 66.4001V89.4001C10 89.7001 10.3 90 10.6 90H32.2C32.5 90 32.8 89.7001 32.8 89.4001V66.4001C32.9 66.1001 32.6 65.8 32.2 65.8Z"
        fill="#1D1D1B"
      />
      <path
        d="M59.4001 55.8V88H40.6001V55.8H59.4001ZM60.8001 53.8H39.2001C38.9001 53.8 38.6001 54.1001 38.6001 54.4001V89.4001C38.6001 89.7001 38.9001 90 39.2001 90H60.8001C61.1001 90 61.4001 89.7001 61.4001 89.4001V54.4001C61.4001 54.1001 61.1001 53.8 60.8001 53.8Z"
        fill="#1D1D1B"
      />
      <path
        d="M88 12V88H69.0999V12H88ZM89.4 10H67.7999C67.4999 10 67.2 10.3 67.2 10.6V89.4C67.2 89.7 67.4999 90 67.7999 90H89.4C89.7 90 90 89.7 90 89.4V10.6C90 10.3 89.7 10 89.4 10Z"
        fill="#1D1D1B"
      />
      <path
        d="M23.5 23.8C24.2179 23.8 24.8 22.9046 24.8 21.8C24.8 20.6955 24.2179 19.8 23.5 19.8C22.782 19.8 22.2 20.6955 22.2 21.8C22.2 22.9046 22.782 23.8 23.5 23.8Z"
        fill="black"
      />
      <path
        d="M33.9001 23.8C34.6181 23.8 35.2001 22.9046 35.2001 21.8C35.2001 20.6955 34.6181 19.8 33.9001 19.8C33.1821 19.8 32.6001 20.6955 32.6001 21.8C32.6001 22.9046 33.1821 23.8 33.9001 23.8Z"
        fill="black"
      />
    </>
  ),
})

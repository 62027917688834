import { BoxProps, Flex, Text, TextProps } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { FC } from 'react'

import type { FrontCollectGoalType, FrontCollectTypeType } from '@miimosa/common/types'
import { useTranslation } from '@miimosa/design-system/lib/i18n/hooks/useTranslation'

interface Props {
  isSuccess?: boolean
  progress: number
  collectedAmount: number
  collectedQuantity: number
  goalType?: FrontCollectGoalType
  collectType: FrontCollectTypeType
  goalAmount: number
  goalQuantity: number
  collectedStyle?: TextProps
  isDone: boolean
}

const ProjectProgressBar: FC<Props & BoxProps> = ({
  isSuccess,
  collectedAmount,
  collectedQuantity,
  progress,
  goalType,
  collectType,
  goalAmount,
  goalQuantity,
  collectedStyle,
  isDone,
  ...props
}) => {
  const { locale } = useRouter()
  const { tCommon } = useTranslation()
  const barColor = isDone ? 'yellow' : 'light_green'

  return (
    <Flex direction="column" width="100%" {...props}>
      <Text size="xs" {...collectedStyle}>
        {tCommon(goalType === 'quantity' && collectType === 'donation' ? 'presales' : 'collected', {
          val:
            goalType === 'quantity'
              ? collectedQuantity.toLocaleString(locale, { maximumFractionDigits: 0 })
              : collectedAmount.toLocaleString(locale, { maximumFractionDigits: 0 }),
        })}
      </Text>
      <Flex
        bgColor="white"
        width="100%"
        height="7px"
        boxSizing="border-box"
        border="solid 0.5px"
        borderColor="main_grey"
        my="2"
        borderRadius="7px"
        overflow="hidden"
      >
        <Flex
          position="relative"
          bgColor={barColor}
          boxSizing="border-box"
          height="6px"
          borderRadius="7px"
          w={`${isSuccess ? '100' : progress}%`}
        ></Flex>
      </Flex>
      <Flex align="center" justify="space-between">
        <Text size="xs">
          {tCommon(
            goalType === 'quantity' && collectType === 'donation'
              ? 'presales_obj'
              : `${tCommon('goal')} ${goalAmount.toLocaleString(locale, { maximumFractionDigits: 0 })} €`,
            {
              val:
                goalType === 'quantity'
                  ? goalQuantity.toLocaleString(locale, { maximumFractionDigits: 0 })
                  : goalAmount.toLocaleString(locale, { maximumFractionDigits: 0 }),
            }
          )}
        </Text>
        <Text size="xs" fontWeight="bold">
          {progress}%
        </Text>
      </Flex>
    </Flex>
  )
}

export default ProjectProgressBar

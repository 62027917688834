import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'LinkedIn',
  viewBox: '0 0 16 16',
  path: (
    <>
      <path d="M3.6305 5.31111H0.336505V16H3.6305V5.31111Z" fill="currentColor" />
      <path
        d="M1.94951 3.89909C3.02511 3.89909 3.89902 3.02516 3.89902 1.94955C3.89902 0.873935 3.02511 0 1.94951 0C0.873917 0 0 0.873935 0 1.94955C0 3.02516 0.873917 3.89909 1.94951 3.89909Z"
        fill="currentColor"
      />
      <path
        d="M8.87419 10.3522C8.87419 8.87327 9.54644 7.93211 10.8909 7.93211C12.101 7.93211 12.706 8.80604 12.706 10.3522C12.706 11.8984 12.706 15.932 12.706 15.932H16C16 15.932 16 12.0329 16 9.2094C16 6.38592 14.3866 4.97418 12.101 4.97418C9.81534 4.97418 8.87419 6.72204 8.87419 6.72204V5.31031H5.71464V15.9992H8.87419C8.87419 15.932 8.87419 11.9657 8.87419 10.3522Z"
        fill="currentColor"
      />
    </>
  ),
})

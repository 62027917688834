import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Discover',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        d="M2 12.0027C2.00158 14.0496 2.63107 16.0467 3.80348 17.7246C4.9759 19.4024 6.63481 20.6801 8.55631 21.3853V2.62012C6.63481 3.3253 4.9759 4.60303 3.80348 6.28085C2.63107 7.95867 2.00158 9.95581 2 12.0027Z"
        fill="#24387C"
      />
      <path
        d="M12.0023 2C10.826 2.00141 9.65935 2.21135 8.5564 2.62008V21.3853C10.77 22.2049 13.204 22.2049 15.4176 21.3853V2.60992C14.3233 2.20961 13.1675 2.00321 12.0023 2Z"
        fill="white"
      />
      <path
        d="M15.4175 2.60999V21.3955C17.3463 20.6952 19.0128 19.4181 20.1905 17.7376C21.3682 16.0572 21.9999 14.0548 21.9999 12.0027C21.9999 9.95064 21.3682 7.94831 20.1905 6.26783C19.0128 4.58735 17.3463 3.31022 15.4175 2.60999Z"
        fill="#DD3E41"
      />
    </g>
  ),
  defaultProps: {
    w: '24px',
    h: '24px',
  },
})

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Soft',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4994 7.08489L16.0282 6.24531C16.126 6.09007 16.331 6.04348 16.4864 6.14135C16.6416 6.23922 16.6882 6.44433 16.5904 6.5998L16.0011 7.53581L16.9865 7.89021C17.1592 7.95249 17.2488 8.14262 17.1866 8.31564C17.1379 8.45121 17.0104 8.5355 16.8741 8.5355C16.8367 8.5355 16.7988 8.52918 16.7618 8.51583L15.8354 8.1826V9.96668C16.9352 9.80378 17.7818 8.85308 17.7818 7.70847C17.7818 6.4495 16.7578 5.42537 15.4996 5.42537C14.3539 5.42537 13.4027 6.27413 13.2415 7.37599H14.8976L14.367 6.53314C14.2692 6.3779 14.3158 6.17256 14.4709 6.07469C14.6263 5.97682 14.8314 6.02342 14.9292 6.17912L15.4994 7.08489ZM15.1709 10.3718C15.1686 10.3562 15.1675 10.3401 15.1675 10.3238V8.12651H10.4524V12.4117H15.1709V10.3718ZM12.5631 7.46178C12.6888 5.95136 13.9578 4.76065 15.4996 4.76065C17.1243 4.76065 18.4462 6.08307 18.4462 7.70847C18.4462 9.2203 17.3026 10.47 15.8354 10.6373V21.6678C15.8354 21.8511 15.6867 22 15.5032 22H8.82423C8.64097 22 8.49212 21.8511 8.49212 21.6678V7.79403C8.49212 7.61046 8.64097 7.46178 8.82423 7.46178H9.78813V5.37801L6.13114 2.59689C5.98509 2.48591 5.95677 2.27729 6.06771 2.13119C6.17888 1.98509 6.38719 1.95676 6.53323 2.06774L10.3213 4.9486C10.4039 5.01158 10.4524 5.10945 10.4524 5.21317V7.46178H12.5631ZM9.78813 8.12651H9.15658V12.4117H9.78813V8.12651ZM9.15658 21.3353H15.1709V13.0767H9.15658V21.3353Z"
        fill="currentColor"
      />
    </>
  ),
})

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'InformationFilled',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d="M6.4443 3.6853C8.08879 2.58649 10.0222 2 12 2C13.3132 2 14.6136 2.25866 15.8268 2.7612C17.0401 3.26375 18.1425 4.00035 19.0711 4.92893C19.9997 5.85752 20.7363 6.95991 21.2388 8.17317C21.7413 9.38642 22 10.6868 22 12C22 13.9778 21.4135 15.9112 20.3147 17.5557C19.2159 19.2002 17.6541 20.4819 15.8268 21.2388C13.9996 21.9957 11.9889 22.1937 10.0491 21.8079C8.10929 21.422 6.32746 20.4696 4.92894 19.0711C3.53041 17.6725 2.578 15.8907 2.19215 13.9509C1.8063 12.0111 2.00433 10.0004 2.76121 8.17317C3.51809 6.3459 4.79981 4.78412 6.4443 3.6853Z"
        fill="currentColor"
      />
      <path
        d="M12 11.44C11.8674 11.44 11.7402 11.4927 11.6464 11.5864C11.5527 11.6802 11.5 11.8074 11.5 11.94V16.27C11.5026 16.4018 11.5561 16.5275 11.6493 16.6207C11.7425 16.7139 11.8682 16.7674 12 16.77C12.1326 16.77 12.2598 16.7173 12.3536 16.6236C12.4473 16.5298 12.5 16.4026 12.5 16.27V11.94C12.5 11.8074 12.4473 11.6802 12.3536 11.5864C12.2598 11.4927 12.1326 11.44 12 11.44Z"
        fill="white"
      />
      <path
        d="M11.98 9.51999C12.4274 9.51999 12.79 9.15734 12.79 8.70999C12.79 8.26264 12.4274 7.89999 11.98 7.89999C11.5327 7.89999 11.17 8.26264 11.17 8.70999C11.17 9.15734 11.5327 9.51999 11.98 9.51999Z"
        fill="white"
      />
    </>
  ),
})

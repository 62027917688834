import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Mousquetaires',
  viewBox: '0 0 163.83 105',
  path: (
    <>
      <path
        d="M55,99.31c.15.91.46,1.32,1.43,1.32h2.78v1.58H56.54a2.87,2.87,0,0,1-2.16-.69,4.49,4.49,0,0,1,.09-6.15,3,3,0,0,1,1.94-.53h2.8v1.58H56.44c-1,0-1.28.39-1.43,1.31h4v1.58Z"
        transform="translate(0 0.02)"
        fill="currentColor"
      />
      <path
        d="M123,99.31c.15.91.46,1.32,1.43,1.32h2.77v1.58H124.5a2.89,2.89,0,0,1-2.16-.69,4.49,4.49,0,0,1,.09-6.15,3,3,0,0,1,1.95-.53h2.79v1.58h-2.77c-1,0-1.28.39-1.42,1.31h4v1.58Z"
        transform="translate(0 0.02)"
        fill="currentColor"
      />
      <path
        d="M152.09,99.31c.15.91.47,1.32,1.43,1.32h2.78v1.58h-2.68a2.88,2.88,0,0,1-2.16-.69,4.5,4.5,0,0,1,.09-6.15,3.05,3.05,0,0,1,1.95-.53h2.79v1.58h-2.78c-1,0-1.27.39-1.42,1.31h4v1.58Z"
        transform="translate(0 0.02)"
        fill="currentColor"
      />
      <path
        d="M86,94.74c2.15,0,3.43,1.44,3.43,3.88,0,2.27-1.33,3.7-3.44,3.7s-3.38-1.4-3.38-3.86c0-2.24,1.35-3.72,3.4-3.72m-1.59,3.82c0,1.44.55,2.21,1.62,2.21s1.57-.75,1.57-2.26S87.1,96.28,86,96.28s-1.58.75-1.59,2.28"
        transform="translate(0 0.02)"
        fill="currentColor"
      />
      <path
        d="M95,94.84h1.78v7.37h-4c-1.46,0-2.11-1-2.11-2.83V94.84h1.77v4.74c0,.66.32,1.05.86,1.05H95Z"
        transform="translate(0 0.02)"
        fill="currentColor"
      />
      <path
        d="M118.07,94.84h1.77v7.37h-4c-1.45,0-2.11-1-2.11-2.83V94.84h1.78v4.74c0,.66.31,1.05.85,1.05h1.67Z"
        transform="translate(0 0.02)"
        fill="currentColor"
      />
      <path
        d="M112.08,105h-1.77v-2.76h-1.7c-1.9,0-3.1-1.4-3.1-3.59a4.14,4.14,0,0,1,1.16-3.14,2.8,2.8,0,0,1,2.24-.64h3.18ZM109,96.42c-1.1,0-1.69.74-1.69,2.1s.61,2.12,1.75,2.12h1.22V96.42Z"
        transform="translate(0 0.02)"
        fill="currentColor"
      />
      <path
        d="M129.43,92.55h1.77v2.29h1.64v1.58H131.2v2.67c0,.76,0,1,.14,1.19a.87.87,0,0,0,.8.36h.69v1.58h-1.28a1.76,1.76,0,0,1-2-1.29,4.45,4.45,0,0,1-.16-1.49v-3H128.3V94.84h1.13Z"
        transform="translate(0 0.02)"
        fill="currentColor"
      />
      <path
        d="M138.65,97.61c0-.9-.34-1.19-1.35-1.19h-2.77V94.84H138c1.83,0,2.45,1.36,2.4,3.21v4.15h-3.51a5.43,5.43,0,0,1-1.61-.12,2.07,2.07,0,0,1-1.24-2.07,2.48,2.48,0,0,1,.76-1.88,2.82,2.82,0,0,1,1.88-.41h1.95Zm-1.83,1.7h-.29a.61.61,0,0,0-.66.68c0,.48.25.65,1,.65h1.83V99.31Z"
        transform="translate(0 0.02)"
        fill="currentColor"
      />
      <path
        d="M147.44,102.21h-1.77V98.06a4.83,4.83,0,0,1,.15-1.5,2.84,2.84,0,0,1,.43-.91,2.13,2.13,0,0,1,1.81-.8H150v1.58h-1.16c-1,0-1.35.29-1.35,1.19Z"
        transform="translate(0 0.02)"
        fill="currentColor"
      />
      <path
        fill="currentColor"
        d="M142.15,92.11h1.77v2h-1.77Zm1.77,10.1h-1.77V94.84h1.77Z"
        transform="translate(0 0.02)"
      />
      <path
        d="M48.87,92.55h1.77v6.62c0,.72,0,.94.15,1.13a.87.87,0,0,0,.79.34h1v1.58H51a1.93,1.93,0,0,1-1.55-.53A2,2,0,0,1,49,101a4.06,4.06,0,0,1-.16-1.42Z"
        transform="translate(0 0.02)"
        fill="currentColor"
      />
      <path
        d="M70.93,92.11v10.1h1.78V93.87h1.67c.55,0,.86.39.86,1.06v7.29H77V93.87H78.7c.54,0,.86.39.86,1.06v7.29h1.77V94.95c0-2.14-.92-2.84-2.34-2.84Z"
        transform="translate(0 0.02)"
        fill="currentColor"
      />
      <path
        d="M66.3,94.83v1.58H63.13c-.15,0-.9-.05-.9.67s.73.69.9.69h1.34a2,2,0,0,1,2.28,2.1,2.07,2.07,0,0,1-2.28,2.33H60.71v-1.58h3.46c.45,0,.85-.16.85-.67s-.38-.67-.85-.67H62.78A2.07,2.07,0,0,1,60.5,97a2.1,2.1,0,0,1,2.27-2.15Z"
        transform="translate(0 0.02)"
        fill="currentColor"
      />
      <path
        d="M104,94.83v1.58h-3.17c-.15,0-.9-.05-.9.67s.73.69.9.69h1.34a2,2,0,0,1,2.29,2.1,2.07,2.07,0,0,1-2.29,2.33H98.42v-1.58h3.46c.45,0,.85-.16.85-.67s-.38-.67-.85-.67h-1.39A2.07,2.07,0,0,1,98.22,97a2.1,2.1,0,0,1,2.27-2.15Z"
        transform="translate(0 0.02)"
        fill="currentColor"
      />
      <path
        d="M163.37,94.83v1.58H160.2c-.15,0-.9-.05-.9.67s.74.69.9.69h1.34a2,2,0,0,1,2.28,2.1,2.07,2.07,0,0,1-2.28,2.33h-3.75v-1.58h3.45c.45,0,.85-.16.85-.67s-.39-.67-.85-.67h-1.39A2.07,2.07,0,0,1,157.58,97a2.1,2.1,0,0,1,2.27-2.15Z"
        transform="translate(0 0.02)"
        fill="currentColor"
      />
      <path
        d="M91.74,49.81c-4.91,0-10.62,2.68-16.63,7.07,5.2-3.54,10.19-5.65,14.69-5.65,16.33,0-1.07,28.93-1.07,28.93h18.54s11.13-30.35-15.54-30.35"
        transform="translate(0 0.02)"
        fill="currentColor"
      />
      <path
        fill="currentColor"
        d="M92.85,47.3C50.59,47.3,0,102.2,0,102.2H35.23c11.18-16.81,26.24-36,39.89-45.32,6-4.38,11.72-7.07,16.63-7.07,26.67,0,15.54,30.35,15.54,30.35S121.21,47.3,92.85,47.3"
        transform="translate(0 0.02)"
      />
      <path
        d="M32.7,25.46A27.12,27.12,0,0,0,34,33c14.78,3.36,39.84,6.87,62.22,6S138.4,36,163.53,28.25c-50.68,7.07-109.27.88-130.17-10.34a23.77,23.77,0,0,0-.66,7.56"
        transform="translate(0 0.02)"
        fill="currentColor"
      />
      <path
        d="M123.39,11.92c7-5.29,6.24-9.46.43-11.29-5.39-1.7-17.42.29-23.41,1.62,18,.18,27.52,6,23,9.67"
        transform="translate(0 0.02)"
        fill="currentColor"
      />
      <path
        fill="currentColor"
        d="M76.46,15.1c10.39,4.87,34,7.11,49,2.9-13.68-1.5-55-7.68-53.67-17-3,8-.5,11.63,4.69,14.06"
        transform="translate(0 0.02)"
      />
      <path d="M37.92,89.19" transform="translate(0 0.02)" fill="currentColor" />
    </>
  ),
})

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Play',
  viewBox: '0 0 86 86',
  path: (
    <>
      <circle cx="43" cy="43" r="41" fill="none" stroke="white" strokeWidth="4" />
      <path
        d="M32 59.4587V25.5413C32 23.9848 33.6997 23.0248 35.0327 23.8285L63.1593 40.7872C64.4491 41.5649 64.4491 43.4351 63.1593 44.2128L35.0327 61.1715C33.6997 61.9752 32 61.0152 32 59.4587Z"
        fill="white"
      />
    </>
  ),
})

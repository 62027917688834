import { Flex, Checkbox, Box } from '@chakra-ui/react'
import { FC } from 'react'
import { components, OptionProps } from 'react-select'

const Option: FC<OptionProps<any, any> & { withCheckbox?: boolean }> = ({
  withCheckbox,
  label,
  selectProps,
  ...restProps
}) => {
  const { isSelected } = restProps
  const { isLoading = false } = selectProps

  return (
    <components.Option label={label} selectProps={selectProps} {...restProps}>
      {isLoading ? (
        <Box bgColor="light_gray" height="32px" borderRadius="4px" />
      ) : (
        <Flex align="center">
          {withCheckbox && <Checkbox isChecked={isSelected} colorScheme="checkbox" mr={2} />}
          <label>{label}</label>
        </Flex>
      )}
    </components.Option>
  )
}

export default Option

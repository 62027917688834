import { Box, Button, Container, Flex, Heading, Text } from '@chakra-ui/react'
import NextLink from 'next/link'
import { useState } from 'react'
import { FaPlay } from 'react-icons/fa'

import { s3ImageURL } from '@miimosa/common'

import Image from '@components/Image'

const Candidate = () => {
  const [videoIsPlaying, setVideoIsPlaying] = useState(false)
  return (
    <Container variant="full" flexDirection={{ base: 'column-reverse', md: 'row' }}>
      <Flex
        direction="column"
        minW={{ base: 'full', md: '400px', lg: '500px', xl: '600px' }}
        h={{ base: 'auto', md: '584px' }}
        backgroundImage={s3ImageURL('bg-texture-green.jpg')}
        justifyContent="center"
        alignItems="center"
        position="relative"
        p={{ base: 10, lg: 0 }}
      >
        <Heading color="white" size="lg" maxW={{ base: 'full', md: '380px' }}>
          Vous êtes agriculteur ou agricultrice et avez un projet de transition agricole ?
        </Heading>
        <Text my={8} size="md" color="white" maxW={{ base: 'full', md: '380px' }} whiteSpace="pre-line">
          {
            'Les candidatures pour la saison 1 du programme sont clôturées. \nUne saison 2 est en préparation pour 2024.'
          }
        </Text>
        <Flex w="full" maxW={{ base: 'full', md: '380px' }}>
          <Button variant="primary" size="md">
            <NextLink href="/laureats">Découvrez les lauréats</NextLink>
          </Button>
        </Flex>
        <Flex direction="row" mt={20}>
          <Text size="md" color="white" whiteSpace="pre-line">
            <b>Hélène Darroze</b>, marraine de la saison 1, cheffe multi étoilée, jury Top Chef
          </Text>
        </Flex>
      </Flex>
      <Flex w="full" position="relative" h="584px">
        {videoIsPlaying ? (
          <Box
            as="iframe"
            src="https://www.youtube.com/embed/aZaZk-BbFv4?autoplay=1"
            allowFullScreen
            zIndex={2}
            w="100%"
            allow="autoplay"
          />
        ) : (
          <Flex alignItems="center" justifyContent="center" direction="column" w="full" position="relative">
            <Image
              src={s3ImageURL('candidate-picture-video.jpg')}
              fill
              style={{ objectFit: 'cover' }}
              alt="picture of Helene Darozze"
              priority
              quality={100}
            />
            <Flex
              w="102px"
              h="102px"
              backgroundColor="rgba(255, 255, 255, .3)"
              border="1px solid white"
              rounded="full"
              zIndex={2}
              alignItems="center"
              justifyContent="center"
              _hover={{ backgroundColor: 'dark_green', cursor: 'pointer' }}
              onClick={() => setVideoIsPlaying(true)}
            >
              <FaPlay color="white" size="40" />
            </Flex>
          </Flex>
        )}
      </Flex>
    </Container>
  )
}
export default Candidate

import { destroyCookie, parseCookies, setCookie } from 'nookies'

export function setOrDestroyJSONCookie(key: string, value: unknown) {
  if (!value) {
    destroyCookie(null, key)
    return
  }
  setCookie(null, key, JSON.stringify(value), {
    path: '/',
    sameSite: 'strict',
    secure: process.env.NODE_ENV === 'production',
  })
}

export function getJSONCookie<T>(key: string): T | undefined {
  const fromCookies = parseCookies()[key]
  if (!fromCookies) {
    return undefined
  }
  try {
    return JSON.parse(fromCookies) as T
  } catch {
    return undefined
  }
}

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'GridOutline',
  viewBox: '0 0 24 24',

  path: (
    <>
      <rect x="5.5" y="5.5" width="5" height="5" fill="none" stroke="currentColor" />
      <rect x="5.5" y="13.5" width="5" height="5" fill="none" stroke="currentColor" />
      <rect x="13.5" y="13.5" width="5" height="5" fill="none" stroke="currentColor" />
      <rect x="13.5" y="5.5" width="5" height="5" fill="none" stroke="currentColor" />
    </>
  ),
})

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Facebook',
  viewBox: '0 0 7 16',
  path: (
    <path
      d="M1.59091 16H4.66667V7.91638H6.78788L7 5.24042H4.66667C4.66667 5.24042 4.66667 4.23693 4.66667 3.67944C4.66667 3.0662 4.77273 2.78746 5.35606 2.78746C5.83333 2.78746 7 2.78746 7 2.78746V0C7 0 5.25 0 4.87879 0C2.59848 0 1.59091 1.05923 1.59091 3.0662C1.59091 4.85017 1.59091 5.24042 1.59091 5.24042H0V7.97213H1.59091V16Z"
      fill="currentColor"
    />
  ),
})

import { Flex, IconProps as ChakraIconProps } from '@chakra-ui/react'
import React, { FC } from 'react'

import ConditionalWrapper from '../ConditionalWrapper'
import * as iconsMap from '../icons'

const sizesMap = {
  '6xs': 2,
  '5xs': 3,
  '4xs': 5,
  '3xs': 8,
  '2xs': 10,
  xs: 12,
  sm: 14,
  md: 20,
  lg: 28,
  xl: 32,
  '2xl': 40,
  '3xl': 48,
  '4xl': 56,
  '5xl': 64,
}

export type IconSize =
  | '6xs'
  | '5xs'
  | '4xs'
  | '3xs'
  | '2xs'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl'
  | '5xl'

export type IconType = 'primary' | 'secondary' | 'dark' | 'danger' | 'warning' | 'success' | 'light'

export type IconName = keyof typeof iconsMap
interface IconProps extends ChakraIconProps {
  name: IconName
  bgColor?: string
  size?: IconSize
  // type?: IconType
}

const Icon: FC<IconProps> = ({ name, bgColor, size = 'sm', css, ...restProps }) => {
  if (!name) {
    return null
  }

  const IconComponent = iconsMap[name]

  // css given as props to avoid TS errors
  return (
    <ConditionalWrapper
      condition={!!bgColor}
      wrap={(children) => (
        <Flex
          bgColor={bgColor || 'dark_green'}
          p="1.5"
          justify="center"
          align="center"
          width="fit-content"
          height="fit-content"
          borderRadius="50%"
        >
          {children}
        </Flex>
      )}
    >
      <IconComponent boxSize={sizesMap[size]} css={css as any} {...restProps} />
    </ConditionalWrapper>
  )
}

export default Icon

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Development',
  viewBox: '0 0 100 100',
  path: (
    <>
      <path
        d="M32.2 65.9H10.6C10.3 65.9 10 66.2 10 66.5V89.5C10 89.8 10.3 90.1 10.6 90.1H32.2C32.5 90.1 32.8 89.8 32.8 89.5V66.5C32.9 66.2 32.6 65.9 32.2 65.9ZM30.9 88.1H12V67.9H30.9V88.1Z"
        fill="#1D1D1B"
      />
      <path
        d="M60.8 48.3H39.2C38.9 48.3 38.6 48.6 38.6 48.9V89.5C38.6 89.8 38.9 90.1 39.2 90.1H60.8C61.1 90.1 61.4 89.8 61.4 89.5V48.9C61.4 48.6 61.1 48.3 60.8 48.3ZM40.6 88.1V50.3H59.5V88.1H40.6Z"
        fill="#1D1D1B"
      />
      <path
        d="M89.4 10.1H67.8C67.5 10.1 67.2 10.4 67.2 10.7V89.5C67.2 89.8 67.5 90.1 67.8 90.1H89.4C89.7 90.1 90 89.8 90 89.5V10.7C90 10.4 89.7 10.1 89.4 10.1ZM88 88.1H69.1V12.1H88V88.1Z"
        fill="#1D1D1B"
      />
      <path
        d="M64.6 15.2L62 10L55.9 10.8C55.4 10.9 55 11.4 55 11.9C55.1 12.4 55.6 12.8 56.1 12.8L59.5 12.4L45.3 37.9L31.3 27.8L10.2 57.9C9.90001 58.3 10 59 10.4 59.3C10.6 59.4 10.8 59.5 11 59.5C11.3 59.5 11.6 59.4 11.8 59.1L31.8 30.7L46 40.9L61.4 13.2L62.9 16.1C63.1 16.4 63.4 16.7 63.8 16.7C64 16.7 64.1 16.7 64.2 16.6C64.7 16.3 64.9 15.7 64.6 15.2Z"
        fill="#FF6C4A"
      />
    </>
  ),
})

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'ChevronLeftFillCircle',
  viewBox: '0 0 33 33',
  path: (
    <path
      fill="currentColor"
      d="M16.5,0.4C7.6,0.4,0.4,7.6,0.4,16.5s7.2,16.1,16.1,16.1s16.1-7.2,16.1-16.1S25.4,0.4,16.5,0.4z M18.2,20.3
	c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.3v0c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.1,0c0,0-0.1-0.1-0.1-0.1
	l-4.3-4.8c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3l4.3-4.8c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0
	c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.3l-4.1,4.5L18.2,20.3z"
    />
  ),
})

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'LogoCarasso',
  viewBox: '0 0 86 47',
  path: (
    <>
      <path
        d="M23.432 20.7427C24.8785 20.7427 26.0356 21.895 26.0356 23.3355C26.0356 24.776 24.8785 25.9283 23.432 25.9283C21.9856 25.9283 20.8285 24.776 20.8285 23.3355C20.8285 21.895 21.9856 20.7427 23.432 20.7427Z"
        fill="#A8C455"
      />
      <path
        d="M23.432 0C24.8785 0 26.0356 1.15236 26.0356 2.59282C26.0356 4.03328 24.8785 5.18564 23.432 5.18564C21.9856 5.18564 20.8285 4.03328 20.8285 2.59282C20.8285 1.15236 21.9856 0 23.432 0Z"
        fill="#A8C455"
      />
      <path
        d="M23.432 10.4536C24.8785 10.4536 26.0356 11.606 26.0356 13.0464C26.0356 14.4869 24.8785 15.6393 23.432 15.6393C21.9856 15.6393 20.8285 14.4869 20.8285 13.0464C20.8285 11.606 21.9856 10.4536 23.432 10.4536Z"
        fill="#F1B748"
      />
      <path
        d="M33.8462 10.4536C35.2927 10.4536 36.4498 11.606 36.4498 13.0464C36.4498 14.4869 35.2927 15.6393 33.8462 15.6393C32.3998 15.6393 31.2427 14.4869 31.2427 13.0464C31.2427 11.606 32.3998 10.4536 33.8462 10.4536Z"
        fill="#F1B748"
      />
      <path
        d="M12.9765 10.4536C14.4229 10.4536 15.58 11.606 15.58 13.0464C15.58 14.4869 14.4229 15.6393 12.9765 15.6393C11.5301 15.6393 10.3729 14.4869 10.3729 13.0464C10.4143 11.606 11.5301 10.4536 12.9765 10.4536Z"
        fill="#F1B748"
      />
      <path
        d="M28.6805 15.5981C30.1269 15.5981 31.284 16.7505 31.284 18.191C31.284 19.6314 30.1269 20.7838 28.6805 20.7838C27.234 20.7838 26.0769 19.6314 26.0769 18.191C26.0769 16.7505 27.234 15.5981 28.6805 15.5981Z"
        fill="#00B4D5"
      />
      <path
        d="M71.7011 33.7478C73.1475 33.7478 74.3047 34.9002 74.3047 36.3406C74.3047 37.7811 73.1475 38.9334 71.7011 38.9334C70.2547 38.9334 69.0975 37.7811 69.0975 36.3406C69.0975 34.859 70.2547 33.7478 71.7011 33.7478Z"
        fill="#00B4D5"
      />
      <path
        d="M18.2249 15.5981C19.6713 15.5981 20.8285 16.7505 20.8285 18.191C20.8285 19.6314 19.6713 20.7838 18.2249 20.7838C16.7785 20.7838 15.6213 19.6314 15.6213 18.191C15.6213 16.7505 16.7785 15.5981 18.2249 15.5981Z"
        fill="#00B4D5"
      />
      <path
        d="M28.6805 5.22681C30.1269 5.22681 31.284 6.37917 31.284 7.81963C31.284 9.26008 30.1269 10.4124 28.6805 10.4124C27.234 10.4124 26.0769 9.26008 26.0769 7.81963C26.0769 6.37917 27.234 5.22681 28.6805 5.22681Z"
        fill="#EA8C42"
      />
      <path
        d="M18.2249 5.22681C19.6713 5.22681 20.8285 6.37917 20.8285 7.81963C20.8285 9.26008 19.6713 10.4124 18.2249 10.4124C16.7785 10.4124 15.6213 9.26008 15.6213 7.81963C15.6213 6.37917 16.7785 5.22681 18.2249 5.22681Z"
        fill="#EA8C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86 44.1603C85.876 43.1314 85.3388 42.5141 84.3056 42.2672C83.7684 42.1437 83.4378 41.8144 83.3551 41.2383C83.3138 40.8679 83.2725 40.5386 83.2725 40.1682C83.2725 38.8101 83.2725 37.4519 83.2725 36.0938C83.2312 31.4432 80.5036 27.6568 76.1644 26.2575C74.0567 25.5579 71.8664 25.4344 69.6348 25.7637C65.7501 26.2987 62.692 28.0684 61.0389 31.7724C59.7578 34.6122 59.6339 37.5754 60.5017 40.4975C61.3696 43.3784 63.2706 45.3127 66.1221 46.3416C67.5272 46.8355 68.9736 46.8766 70.4613 46.9589C73.5195 47.0824 76.2057 46.3004 78.4373 44.0369C78.5613 44.2426 78.6439 44.4484 78.7266 44.613C79.8011 46.6297 82.7766 47.5763 84.7602 46.5474C85.6281 46.1358 86.0827 45.1892 86 44.1603ZM71.5358 42.6376C67.8165 42.6376 65.3782 40.2094 65.4195 36.5053C65.4195 32.5955 67.9404 30.085 71.7838 30.085C75.5858 30.085 78.024 32.5544 77.9827 36.3819C77.9414 40.0859 75.2965 42.6376 71.5358 42.6376Z"
        fill="#00B4D5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.4849 44.3659C57.4849 45.971 56.4517 46.9176 54.716 46.9176C53.0216 46.9176 52.1124 46.0122 52.0711 44.3659C52.0711 41.4439 52.0711 38.4806 52.0298 35.5586C52.0298 35.0236 51.9471 34.4474 51.7818 33.9123C51.0793 31.443 48.9303 30.1672 46.1614 30.5376C43.5166 30.8668 41.9462 32.3896 41.8222 35.0647C41.6982 38.0691 41.6569 41.0735 41.7395 44.0778C41.8222 46.5884 39.7559 47.3292 37.9788 46.8353C36.987 46.5472 36.3258 45.7241 36.3258 44.654C36.3258 41.485 36.2431 38.2749 36.3258 35.1059C36.4084 32.513 37.1936 30.1672 39.0947 28.3151C40.4171 27.0393 42.0288 26.3397 43.8059 25.9693C46.1614 25.5165 48.517 25.5165 50.8726 26.175C54.5507 27.2039 57.0716 30.0849 57.3609 33.9535C57.4849 35.8878 57.4849 42.8432 57.4849 44.3659Z"
        fill="#00B4D5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.20712 26.1752C6.40559 26.1752 7.48007 26.1752 8.59588 26.1752C10.6209 26.1752 11.8193 28.233 10.8275 29.9615C10.3729 30.7435 9.71169 31.1139 8.80251 31.1551C7.81068 31.1962 6.86018 31.1551 5.86834 31.1962C5.66171 31.1962 5.45508 31.1962 5.1658 31.1962C5.1658 31.8547 5.1658 32.4721 5.1658 33.0894C5.20712 34.8179 5.12447 36.5465 5.28978 38.275C5.49641 40.7032 6.86018 41.8556 9.33975 41.8967C11.2821 41.9379 12.3152 43.3372 11.778 45.1892C11.4887 46.2181 10.7862 46.8354 9.75302 46.8766C7.76935 47.0001 5.82702 46.7943 4.00866 45.9712C1.77704 44.9423 0.702558 43.0491 0.247969 40.7855C0.0413373 39.7155 -0.0826416 38.6043 -0.0826416 37.5342C1.09896e-05 32.5132 1.09896e-05 27.451 1.09896e-05 22.43C1.09896e-05 20.2899 1.94235 19.1376 3.88468 20.043C4.75253 20.4545 5.20712 21.1542 5.20712 22.1419C5.20712 23.4589 5.20712 24.7759 5.20712 26.1752Z"
        fill="#00B4D5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5387 35.394C15.5387 33.1715 15.5387 30.9491 15.5387 28.7267C15.5387 26.9982 16.4065 26.175 18.1836 26.175C19.9606 26.175 20.9524 27.0805 20.9524 28.7267C20.9524 32.4719 20.9524 36.2171 20.9524 39.9623C20.9524 40.2915 20.9524 40.6208 20.9938 40.9088C21.0764 41.6908 21.3657 41.9789 22.1509 42.1435C22.7295 42.267 23.1014 42.5962 23.3907 43.0901C24.3412 44.9421 23.1841 46.8353 20.9938 46.9588C17.6463 47.1645 15.5387 45.1891 15.5387 41.8554C15.5387 39.6742 15.5387 37.5341 15.5387 35.394Z"
        fill="#00B4D5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.8703 35.4351C25.8703 33.1715 25.8703 30.9491 25.8703 28.6856C25.8703 26.9982 26.7795 26.175 28.5565 26.175C30.2922 26.175 31.284 27.0805 31.284 28.7267C31.284 32.4719 31.284 36.2582 31.284 40.0034C31.284 40.415 31.3254 40.7854 31.3667 41.1969C31.4493 41.6908 31.7386 42.0612 32.2759 42.1024C33.433 42.1847 33.8876 43.2136 33.9702 44.0779C34.0942 45.2714 33.6396 46.1356 32.6478 46.5884C30.4162 47.6584 27.2754 46.5472 26.3662 44.2836C26.0769 43.5428 25.9116 42.7197 25.8703 41.8966C25.829 39.7565 25.8703 37.5752 25.8703 35.4351Z"
        fill="#00B4D5"
      />
    </>
  ),
})

export { default as Biodiversity } from './Biodiversity'
export { default as AnimalWelfare } from './AnimalWelfare'
export { default as RenewableEnergy } from './RenewableEnergy'
export { default as LivingStandards } from './LivingStandards'
export { default as SocialCohesion } from './SocialCohesion'
export { default as WasteReduction } from './WasteReduction'
export { default as FoodNationalSafety } from './FoodNationalSafety'
export { default as LocalDevelopment } from './LocalDevelopment'
export { default as NaturalRessources } from './NaturalRessources'
export { default as HealthWellBeing } from './HealthWellBeing'
export { default as Climat } from './Climat'
export { default as ClimatBig } from './ClimatBig'
export { default as BiodiversityBig } from './BiodiversityBig'
export { default as AnimalWelfareBig } from './AnimalWelfareBig'
export { default as RenewableEnergyBig } from './RenewableEnergyBig'
export { default as LivingStandardsBig } from './LivingStandardsBig'
export { default as SocialCohesionBig } from './SocialCohesionBig'
export { default as WasteReductionBig } from './WasteReductionBig'
export { default as FoodNationalSafetyBig } from './FoodNationalSafetyBig'
export { default as LocalDevelopmentBig } from './LocalDevelopmentBig'
export { default as NaturalRessourcesBig } from './NaturalRessourcesBig'
export { default as HealthWellBeingBig } from './HealthWellBeingBig'
export { default as Water } from './Water'
export { default as WaterBig } from './WaterBig'

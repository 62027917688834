import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'ChevronRightFillCircle',
  viewBox: '0 0 33 33',
  path: (
    <path
      fill="currentColor"
      d="M16.5,0.4C7.6,0.4,0.4,7.6,0.4,16.5s7.2,16.1,16.1,16.1s16.1-7.2,16.1-16.1S25.4,0.4,16.5,0.4z M19.6,16.1
	l-4,4.8c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0s-0.1,0-0.1,0c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3
	l3.7-4.5l-3.7-4.5C15.1,11.2,15,11.1,15,11c0-0.1,0-0.2,0.1-0.3v0c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0s0.1,0,0.1,0
	c0,0,0.1,0.1,0.1,0.1l4,4.8c0.1,0.1,0.1,0.2,0.1,0.3C19.7,15.9,19.7,16,19.6,16.1z"
    />
  ),
})

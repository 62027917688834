import { Flex, Heading, SystemProps, Text } from '@chakra-ui/react'

import { s3ImageURL } from '@miimosa/common'
import { Carousel, ChevronLeftCircle, ChevronRightCircle } from '@miimosa/design-system/components'
import { useMountedBreakpoints } from '@miimosa/design-system/lib/hooks'

import Image from '@components/Image'

type CardProps = {
  title: string
  titleColor: string
  flexDirection: SystemProps['flexDirection']
  imageUrl: string
  imageHeight?: string
  text: string
  textColor: string
  bgColor: string
}

const Card = ({
  title,
  titleColor,
  flexDirection,
  imageUrl,
  text,
  bgColor,
  textColor,
  imageHeight = '150px',
}: CardProps) => {
  return (
    <Flex direction="column" h="400px" bgColor={bgColor} justifyContent="flex-start" alignItems="center" p={6} mx={6}>
      <Heading size="sm" color={titleColor} mb={6} textAlign="center">
        {title}
      </Heading>
      <Flex w="full" direction={flexDirection} rowGap={6} alignItems="center">
        <Flex maxW="200px" textAlign="center">
          <Text color={textColor} dangerouslySetInnerHTML={{ __html: text }} whiteSpace="pre-line" />
        </Flex>
        <Flex position="relative" w="full" h={imageHeight}>
          <Image src={s3ImageURL(imageUrl)} fill style={{ objectFit: 'cover' }} alt="" />
        </Flex>
      </Flex>
    </Flex>
  )
}

const data = [
  {
    title: 'Avant le 31 mai 2023',
    titleColor: 'orange',
    flexDirection: 'column-reverse' as SystemProps['flexDirection'],
    imageUrl: 'calendar-1.png',
    text: 'Inscription via le formulaire <a href="#form"><strong>ci-dessous</strong></a> et lancement de la collecte de financement participatif sur MiiMOSA',
    textColor: 'orange',
    bgColor: 'pink',
    imageHeight: '181px',
  },
  {
    title: 'Juin 2023',
    titleColor: 'white',
    flexDirection: 'column' as SystemProps['flexDirection'],
    imageUrl: 'calendar-2.png',
    text: 'Sélection des 4 projets lauréats par un jury composé de MiiMOSA, M6 Publicité et des partenaires de l’appel à projets',
    textColor: 'white',
    bgColor: 'orange',
    imageHeight: '137px',
  },
  {
    title: 'Été 2023',
    titleColor: 'white',
    flexDirection: 'column-reverse' as SystemProps['flexDirection'],
    imageUrl: 'calendar-3.png',
    text: 'Tournage du programme court chez les 4 porteurs de projet lauréats',
    textColor: 'white',
    bgColor: 'green',
    imageHeight: '225px',
  },
  {
    title: 'Août - Novembre 2023',
    titleColor: 'green',
    flexDirection: 'column' as SystemProps['flexDirection'],
    imageUrl: 'calendar-4.png',
    text: 'Diffusion du programme \n« Cultivons Notre Avenir » sur la chaîne M6 et la plateforme 6play',
    textColor: 'green',
    bgColor: 'blue',
    imageHeight: '150px',
  },
]

const Calendar = () => {
  const itemsPerPage = useMountedBreakpoints({ base: 1, md: 2, lg: 4 }, 4) as number
  return (
    <Flex direction="column" w="full" alignItems="center" mt={{ base: 10, md: 20 }}>
      <Heading mb={14}>Le calendrier de la saison 1</Heading>
      <Carousel
        zIndex="1"
        width="full"
        isDark={true}
        draggable
        boxedControls
        SlideComponent={Card}
        itemsPerPage={itemsPerPage}
        centerMode
        hasDots={false}
        data={data}
        ArrowLeft={ChevronLeftCircle}
        ArrowRight={ChevronRightCircle}
        navColor="green"
        navBottom
      />
    </Flex>
  )
}

export default Calendar

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'FoodNationalSafety',
  viewBox: '0 0 17 20',
  path: (
    <>
      <path
        d="M14.9001 6.9C14.0001 5.9 12.7001 5.6 11.6001 5.6C14.0001 4.5 14.2001 3 14.0001 1.2C14.0001 1 14.0001 0.8 14.0001 0.5C14.0001 0.2 13.8001 0 13.5001 0C11.7001 0 10.3001 0.5 9.4001 1.6C8.7001 2.4 8.4001 3.4 8.3001 4.2C6.6001 2.2 4.2001 2.2 4.1001 2.2C3.8001 2.2 3.6001 2.4 3.6001 2.7C3.6001 3 3.8001 3.2 4.1001 3.2C4.2001 3.2 7.0001 3.2 8.2001 6.2H8.1001C6.0001 4.5 3.4001 5.6 3.3001 5.6C2.0001 6.2 1.2001 7.1 0.800097 8.4C-0.0999033 11.2 1.2001 15.2 2.6001 17.7C3.0001 18.4 3.4001 18.9 3.8001 19.3C4.4001 19.7 5.1001 20 5.8001 20H5.9001C6.4001 20 6.8001 19.8 7.2001 19.7C7.4001 19.6 7.6001 19.6 7.8001 19.5C8.5001 19.3 8.9001 19.5 9.3001 19.7C9.7001 19.9 10.1001 20 10.6001 20C10.8001 20 11.1001 20 11.4001 19.9C13.1001 19.4 14.6001 18 15.4001 16C17.1001 11.9 16.4001 8.5 14.9001 6.9ZM10.2001 2.3C10.8001 1.5 11.8001 1.1 13.0001 1C13.0001 1.1 13.0001 1.1 13.0001 1.2C13.1001 3 13.2001 4.3 9.2001 5.4C9.2001 4.7 9.3001 3.3 10.2001 2.3ZM14.5001 15.6C13.8001 17.3 12.6001 18.5 11.2001 18.9C10.5001 19.1 10.2001 18.9 9.7001 18.7C9.3001 18.5 8.9001 18.4 8.3001 18.4C8.1001 18.4 7.8001 18.4 7.5001 18.5C7.3001 18.6 7.0001 18.6 6.8001 18.7C6.4001 18.8 6.1001 18.9 5.8001 18.9C5.4001 18.9 4.9001 18.7 4.5001 18.4C4.1001 18.1 3.8001 17.7 3.5001 17.1C2.1001 14.6 1.0001 11 1.7001 8.7C2.0001 7.7 2.7001 7 3.6001 6.6C3.7001 6.6 5.8001 5.7 7.5001 7C7.6001 7 8.8001 7.8 10.0001 7C10.7001 6.6 12.8001 6.2 14.1001 7.6C15.4001 8.9 16.0001 12 14.5001 15.6Z"
        fill="currentColor"
      />
    </>
  ),
})

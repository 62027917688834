import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'LockDS',
  viewBox: '0 0 70 70',
  path: (
    <>
      <path
        d="M48.125 30.625V19.6875C48.125 16.2065 46.7422 12.8681 44.2808 10.4067C41.8194 7.94531 38.481 6.5625 35 6.5625C31.519 6.5625 28.1806 7.94531 25.7192 10.4067C23.2578 12.8681 21.875 16.2065 21.875 19.6875V30.625M19.6875 63.4375H50.3125C52.053 63.4375 53.7222 62.7461 54.9529 61.5154C56.1836 60.2847 56.875 58.6155 56.875 56.875V37.1875C56.875 35.447 56.1836 33.7778 54.9529 32.5471C53.7222 31.3164 52.053 30.625 50.3125 30.625H19.6875C17.947 30.625 16.2778 31.3164 15.0471 32.5471C13.8164 33.7778 13.125 35.447 13.125 37.1875V56.875C13.125 58.6155 13.8164 60.2847 15.0471 61.5154C16.2778 62.7461 17.947 63.4375 19.6875 63.4375Z"
        stroke="#2A2A2A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#fff"
      />
      <path
        d="M27.5 47.75L33.5 53.75L42.5 40.25"
        stroke="#EE7455"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#fff"
      />
    </>
  ),
})

import { useTranslation as useTranslationNext } from 'next-i18next'

export const useTranslation = (key?: string, options?: any) => {
  const { t } = useTranslationNext(key, { ...options, useSuspense: true })
  const { t: tCommon } = useTranslationNext('common')

  return {
    t,
    tCommon,
  }
}

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'DonationSupport',
  viewBox: '0 0 100 100',
  path: (
    <>
      <path
        d="M88.5 64C86.7 62.5 84.3 62.6 83.4 62.8C81.6 63 72.5 67.1 69 68.7C69 67.2 68.5 65.9 67.6 64.9C65.9 63.1 63.3 63.1 63.2 63.1C63.1 63.1 57.8 63.2 54 63.1C50.2 58.5 45.8 58.1 42.8 57.8C42.5 57.8 42.3 57.8 42 57.7C34.7 57.1 30.9 57.6 29 58C28.8 55.4 26.7 53.3 24 53.3H10.5C9.9 53.3 9.5 53.7 9.5 54.3C9.5 54.9 9.9 55.3 10.5 55.3H23.9C25.6 55.3 26.9 56.7 26.9 58.3V77C26.9 78.7 25.5 80 23.9 80H17.6C17 80 16.6 80.4 16.6 81C16.6 81.6 17 82 17.6 82H23.9C25.9 82 27.6 80.8 28.4 79.1C35.8 82.4 46.4 85.2 51.6 86.3C53.4 86.7 54.8 86.8 55.9 86.8C57.9 86.8 59 86.3 59.2 86.2C60.2 85.7 85.2 73.5 86.7 72.8C88.2 72.1 90.2 71.3 90.3 68.8C90.4 66.8 89.8 65.1 88.5 64ZM86 71.1C84.5 71.8 59.5 84 58.4 84.6C58.4 84.6 56.7 85.5 52.2 84.5C47 83.3 36.3 80.5 29.1 77.3C29.1 77.2 29.1 77.2 29.1 77.1V60.1C30.4 59.8 34 59.2 41.9 59.8C42.2 59.8 42.4 59.9 42.7 59.9C45.6 60.2 49.5 60.5 52.8 64.8C53 65 53.3 65.2 53.6 65.2C57.5 65.3 63.3 65.2 63.4 65.2C63.4 65.2 65.3 65.2 66.4 66.4C67 67 67.3 68 67.3 69.1C67.3 69.6 67.1 70 66.7 70.4C65.6 71.3 63.3 71.3 62.4 71.3H47.8C47.2 71.3 46.8 71.8 46.8 72.3C46.8 72.8 47.2 73.3 47.8 73.3H62.3C62.4 73.3 62.7 73.3 63.2 73.3C64.4 73.3 66.6 73.1 67.9 71.9C68.1 71.7 68.3 71.5 68.4 71.3C68.5 71.3 68.6 71.3 68.7 71.2C73.8 68.8 82.4 65 83.8 64.8C84.3 64.7 86.1 64.6 87.3 65.6C88.1 66.3 88.6 67.5 88.6 69C88.4 70 87.5 70.5 86 71.1Z"
        fill="#2A2A2A"
      />
      <path
        d="M61.9507 48.2887C61.7507 48.2887 61.6507 48.2887 61.4507 48.1887C58.7507 46.7887 45.2507 39.1887 45.2507 28.1887C45.2507 25.7887 46.1507 23.5887 47.7507 21.8887C49.4507 20.1887 51.8507 19.1887 54.2507 19.2887C57.2507 19.2887 59.9507 20.7887 61.8507 23.2887C64.2507 20.0887 67.9507 18.5887 71.7507 19.5887C75.7507 20.5887 78.4507 24.0887 78.4507 28.1887C78.4507 38.1887 67.1507 45.4887 62.2507 48.1887C62.2507 48.2887 62.1507 48.2887 61.9507 48.2887ZM54.3507 21.2887C52.4507 21.2887 50.6507 21.9887 49.2507 23.3887C47.9507 24.6887 47.2507 26.3887 47.2507 28.1887C47.2507 37.4887 58.5507 44.2887 61.8507 46.1887C66.6507 43.4887 76.5507 36.7887 76.5507 28.1887C76.5507 24.8887 74.5507 22.2887 71.3507 21.4887C68.6507 20.7887 65.0507 21.4887 62.8507 25.5887C62.4507 26.1887 61.4507 26.1887 61.0507 25.5887C59.5507 22.8887 57.1507 21.2887 54.3507 21.2887Z"
        fill="#FF6C4A"
      />
    </>
  ),
})

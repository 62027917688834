import { List, ListItem, Text, Button } from '@chakra-ui/react'
import { FC } from 'react'

interface Suggestion {
  description: string
}
interface Props {
  loading: boolean
  data: Suggestion[]
  handleSelect: (suggestion: Suggestion) => void
}

// const renderLoading = () =>
//   Array.from(Array(5)).map((num) => (
//     <Box
//       key={`loading${num}`}
//       bgColor="light_gray"
//       animation="1.2s linear infinite skeletonBG"
//       height="28px"
//       mx="4px"
//       my="6px"
//       borderRadius="4px"
//     />
//   ))

const renderSuggestions = (data: Suggestion[], handleSelect: (suggestion: Suggestion) => void) =>
  data?.map((suggestion: any) => {
    const {
      place_id,
      structured_formatting: { main_text, secondary_text },
    } = suggestion

    return (
      <ListItem key={place_id} m="4px 18px" height="32px">
        <Button onClick={() => handleSelect(suggestion)} variant="ghost">
          <Text mr="4px">
            <strong>{main_text}</strong>
          </Text>
          <Text>
            <small>{secondary_text}</small>
          </Text>
        </Button>
      </ListItem>
    )
  })

const SearchSuggestions: FC<Props> = ({ data, handleSelect }) => {
  return (
    <List
      alignItems="left"
      bg="white"
      width="100%"
      p="4px"
      position="absolute"
      height="auto"
      top="50px"
      zIndex="1"
      borderBottom="solid 2px"
      borderColor="dark_green"
    >
      {renderSuggestions(data, handleSelect)}
    </List>
  )
}

export default SearchSuggestions

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'CounterPart',
  viewBox: '0 0 100 100',
  path: (
    <>
      <path
        d="M33.4 60.8H28C27.4 60.8 27 61.2 27 61.8C27 62.4 27.4 62.8 28 62.8H33.4C34 62.8 34.4 62.4 34.4 61.8C34.4 61.2 34 60.8 33.4 60.8Z"
        fill="#2A2A2A"
      />
      <path
        d="M33.4 78.8H28C27.4 78.8 27 79.2 27 79.8C27 80.4 27.4 80.8 28 80.8H33.4C34 80.8 34.4 80.4 34.4 79.8C34.4 79.2 34 78.8 33.4 78.8Z"
        fill="#2A2A2A"
      />
      <path
        d="M63.1 79.8C63.1 80.4 63.5 80.8 64.1 80.8H69.5C70.1 80.8 70.5 80.4 70.5 79.8C70.5 79.2 70.1 78.8 69.5 78.8H64.1C63.6 78.8 63.1 79.3 63.1 79.8Z"
        fill="#2A2A2A"
      />
      <path
        d="M70.2 61.1C70 60.9 69.8 60.8 69.5 60.8H64.1C63.5 60.8 63.1 61.2 63.1 61.8C63.1 62.4 63.5 62.8 64.1 62.8H69.5C70.1 62.8 70.5 62.4 70.5 61.8C70.5 61.5 70.4 61.3 70.2 61.1Z"
        fill="#2A2A2A"
      />
      <path
        d="M82.7 51.8H52.4C53.7 50.1 53.6 47.7 52.1 46.1C50.6 44.5 50.6 42 52 40.4C52.9 39.4 53.3 38.1 53.2 36.8C52.8 34.4 50.7 32.7 48.3 32.8C46.9 32.9 45.6 32.2 44.6 31.1C43.8 30.1 43.4 28.9 43.6 27.6C43.7 26.9 43.7 26.1 43.5 25.4C42.9 22.8 40.6 20.9 37.9 20.9C34.7 20.9 32.2 23.5 32.2 26.6C32.2 26.8 32.2 27.1 32.2 27.3C32.4 28.7 32 30.1 31.1 31.1C30.3 32.1 29 32.6 27.8 32.6C25.3 32.4 23.2 34.2 22.8 36.7C22.7 38 23.1 39.3 24 40.2C25.4 41.8 25.4 44.3 24 45.9C22.6 47.5 22.5 49.9 23.7 51.6H17.1C16.5 51.6 16.1 52 16.1 52.6V88.6C16.1 89.2 16.5 89.6 17.1 89.6H82.6C83.2 89.6 83.6 89.2 83.6 88.6V52.6C83.7 52.3 83.3 51.8 82.7 51.8ZM25.7 47.5C27.8 45.1 27.9 41.5 25.7 39.1C25.2 38.6 25 37.8 25 37.2C25.2 35.8 26.5 34.8 28 34.9C29.9 34.9 31.6 34.1 32.8 32.7C34.1 31.2 34.7 29.2 34.4 27.3C34.4 27.1 34.4 27 34.4 26.8C34.4 25.8 34.8 24.9 35.5 24.2C36.2 23.5 37.1 23.1 38.1 23.1C39.8 23.1 41.4 24.3 41.7 26C41.8 26.5 41.8 26.9 41.7 27.4C41.4 29.2 41.9 31.1 43.1 32.5C44.4 34 46.3 34.9 48.3 34.9C49.7 34.9 50.9 35.8 51.1 37.2C51.2 37.9 50.9 38.6 50.4 39.2C48.3 41.6 48.3 45.2 50.4 47.6C51.3 48.6 51.3 50 50.4 51C50.1 51.3 49.9 51.6 49.7 52H39.9L43.2 46.9C43.5 46.4 43.4 45.8 42.9 45.5C42.4 45.2 41.8 45.3 41.5 45.8L39.3 49.3V39.9C39.3 39.3 38.9 38.9 38.3 38.9C37.7 38.9 37.3 39.3 37.3 39.9V49.4L34.9 45.8C34.6 45.3 34 45.2 33.5 45.5C33 45.8 32.9 46.4 33.2 46.9L36.5 52H26.4C26.2 51.7 26 51.3 25.7 51C24.8 49.9 24.8 48.4 25.7 47.5ZM81.7 87.8H18.3V71.8H81.7V87.8ZM18.3 69.8V53.8H81.9V69.8H18.3Z"
        fill="#2A2A2A"
      />
      <path
        d="M81.2 21.5C80.8 21.1 80.2 21.2 79.8 21.6L74.3 27.9C73.8 27.6 73.3 27.4 72.7 27.3L76.7 11.2C76.8 10.7 76.5 10.1 76 10C75.5 9.9 74.9 10.2 74.8 10.7L70.8 26.8C70.4 26.7 70 26.7 69.5 26.7C69.4 26.7 69.2 26.7 69.1 26.7L66.7 18.5C66.5 18 66 17.7 65.5 17.8C65 18 64.7 18.5 64.8 19L67.1 26.9C63.2 27.8 60 30.9 59.2 35.1L59.7 51.7H61.7L61.2 35.3C62.1 31.4 65.5 28.6 69.5 28.6C70 28.6 70.6 28.7 71.1 28.8C73.3 29.2 75.3 30.5 76.5 32.4C77.7 34.2 78.2 36.4 77.8 38.6L77 40.5H76.9L65.3 38.5C64.8 38.4 64.2 38.8 64.1 39.3C64 39.8 64.4 40.4 64.9 40.5L76.1 42.4L74 47.2L67.9 46.4C67.4 46.3 66.9 46.7 66.8 47.3C66.7 47.8 67.1 48.3 67.7 48.4L73.2 49.1L72 51.7H74.2L79.7 39.1C80.5 35.1 78.9 31.2 75.8 28.8L81.1 22.7C81.7 22.5 81.6 21.9 81.2 21.5Z"
        fill="#FF6C4A"
      />
    </>
  ),
})

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'LogoMakesense',
  viewBox: '0 0 122 33',
  path: (
    <>
      <path
        d="M63.244 24.041C63.244 25.076 62.4868 25.9283 61.5369 25.9283H58.1226C57.1865 25.9283 56.4155 25.0912 56.4155 24.041C56.4155 23.0061 57.1727 22.1538 58.1226 22.1538H61.5369C62.4868 22.1538 63.244 23.0061 63.244 24.041Z"
        fill="#FFF30D"
      />
      <path
        d="M15.4033 10.5109C17.0278 10.5109 18.0328 11.2263 18.4458 12.6569C18.611 11.9872 18.9689 11.4545 19.5472 11.0741C20.1116 10.6936 20.7724 10.4957 21.5159 10.4957C23.6498 10.4957 24.7236 11.6981 24.7236 14.0875V21.8191C24.7236 22.0474 24.6685 22.154 24.5446 22.154H21.447C21.3231 22.154 21.2681 22.0779 21.2681 21.9104V15.503C21.2681 14.3311 20.8413 13.7527 19.9877 13.7527C19.6435 13.7527 19.3544 13.8593 19.0929 14.0571C18.8313 14.255 18.611 14.7572 18.611 15.1377V21.8343C18.611 22.0626 18.5559 22.1692 18.432 22.1692H15.3344C15.2105 22.1692 15.1555 22.0931 15.1555 21.9257V15.503C15.1555 14.3311 14.7287 13.7527 13.8751 13.7527C13.5309 13.7527 13.2281 13.8593 12.9665 14.0723C12.6912 14.2854 12.4984 14.7572 12.4984 15.1225V21.8952C12.4984 22.0626 12.4571 22.1387 12.3607 22.1387H9.22185C9.11171 22.1387 9.05664 22.0322 9.05664 21.8191V11.1197C9.05664 10.9371 9.09794 10.8458 9.19431 10.8458H12.2231C12.347 10.8458 12.402 10.9371 12.402 11.1197V12.6721C12.6223 12.0177 12.994 11.485 13.5309 11.1045C14.0541 10.7088 14.6874 10.5109 15.4033 10.5109Z"
        fill="#0C3944"
      />
      <path
        d="M31.2354 14.2093C30.6021 14.2093 30.0928 14.4376 29.721 14.879C29.3493 15.3203 29.1704 15.8987 29.1704 16.5988C29.1704 17.2685 29.3631 17.8316 29.7623 18.2577C30.1616 18.6839 30.6572 18.9122 31.2354 18.9122C31.7999 18.9122 32.2817 18.6991 32.681 18.2577C33.0664 17.8164 33.2729 17.2532 33.2729 16.5683C33.2729 15.853 33.0802 15.2747 32.6947 14.8485C32.2955 14.4224 31.8136 14.2093 31.2354 14.2093ZM33.4382 21.9561V20.5407C33.2729 21.0886 32.915 21.53 32.3368 21.8648C31.8274 22.1692 31.2354 22.3062 30.5746 22.3062C29.1841 22.3062 28.0415 21.7887 27.1741 20.7538C26.2517 19.6884 25.7974 18.273 25.7974 16.477C25.7974 14.8181 26.2655 13.4331 27.2017 12.3068C28.1103 11.2263 29.2117 10.6936 30.5195 10.6936C31.1666 10.6936 31.7586 10.8762 32.2817 11.2567C32.7636 11.5915 33.094 11.9872 33.2592 12.4743V11.1045C33.2592 10.9219 33.3142 10.8305 33.4244 10.8305H36.5495C36.6459 10.8305 36.6872 10.8914 36.6872 11.0284V21.8343C36.6872 22.0474 36.6321 22.154 36.522 22.154H33.6447C33.507 22.154 33.4382 22.0931 33.4382 21.9561Z"
        fill="#0C3944"
      />
      <path
        d="M41.7122 7.36046V15.3964C42.3455 14.4224 43.4744 12.9765 44.6996 11.0284C44.7547 10.9066 44.8373 10.8458 44.9474 10.8458H48.2515C48.4581 10.8458 48.4718 10.9827 48.3066 11.2415L44.8648 15.5334L48.6233 21.88C48.7334 22.0626 48.6646 22.154 48.4443 22.154H44.8786C44.7547 22.154 44.6446 22.0474 44.5344 21.8343L42.8411 17.999L41.7122 19.4296V21.8952C41.7122 22.0626 41.6571 22.1387 41.547 22.1387H38.4219C38.3117 22.1387 38.2566 22.0322 38.2566 21.8191V7.36046C38.2566 7.16261 38.2979 7.07129 38.3943 7.07129H41.5194C41.6433 7.07129 41.7122 7.16261 41.7122 7.36046Z"
        fill="#0C3944"
      />
      <path
        d="M51.2803 15.4421H54.7083C54.6257 14.1789 54.0475 13.5396 52.9874 13.5396C51.9824 13.5244 51.418 14.1636 51.2803 15.4421ZM53.2903 10.5109C54.5706 10.5109 55.6445 10.9675 56.5118 11.8807C57.4204 12.8395 57.8747 14.0875 57.8747 15.6247C57.8747 16.2335 57.8334 16.751 57.7508 17.1467C57.7095 17.3293 57.5719 17.4206 57.3516 17.4206H51.2941C51.3078 18.0142 51.5006 18.5012 51.8861 18.8513C52.2578 19.2166 52.7534 19.4144 53.3591 19.4144C54.0888 19.4144 54.901 19.2622 55.438 18.7295C55.5619 18.6078 55.6445 18.5926 55.7959 18.7752C56.4017 19.5971 56.6908 19.8862 57.09 20.5559C57.2002 20.7385 57.1589 20.9059 56.9936 21.0734C56.6357 21.4234 56.0713 21.7887 55.4655 22.0322C54.7496 22.3214 54.0337 22.4584 53.3178 22.4584C51.7484 22.4584 50.4818 21.9257 49.5043 20.8603C48.4856 19.7493 47.99 18.2729 47.99 16.4009C47.99 14.6659 48.4718 13.2505 49.4355 12.1547C50.3717 11.0588 51.6658 10.5109 53.2903 10.5109Z"
        fill="#0C3944"
      />
      <path
        d="M70.0862 21.4234C69.2601 22.1844 68.1175 22.5497 66.6581 22.5497C64.4141 22.5497 62.941 21.6821 62.2251 19.9319C62.1701 19.7493 62.1976 19.6427 62.2802 19.5971L64.4829 18.6534C64.5793 18.623 64.6344 18.6534 64.6619 18.7448C64.7721 19.0948 64.9786 19.3688 65.2952 19.6123C65.6119 19.8406 65.9423 19.9623 66.314 19.9623C67.1951 19.9623 67.6356 19.6579 67.6356 19.0644L70.0862 21.4234ZM62.2251 19.9471C62.1701 19.7645 62.1976 19.6579 62.2802 19.6123L64.4829 18.6687C64.5793 18.6382 64.6344 18.6687 64.6619 18.76C64.7721 19.11 64.9786 19.384 65.2952 19.6275C65.6119 19.8558 65.9423 19.9775 66.314 19.9775C67.1951 19.9775 67.6356 19.6732 67.6356 19.0796C67.6356 18.7752 67.4979 18.5317 67.2226 18.3338C66.9473 18.136 66.4241 17.9381 65.6532 17.725C64.5656 17.4206 63.7946 17.0554 63.299 16.5988C62.6932 16.0357 62.3903 15.229 62.3903 14.1789C62.3903 13.007 62.7896 12.109 63.6019 11.4698C64.4141 10.8305 65.5293 10.5109 66.9473 10.5109C69.1638 10.5109 70.4992 11.3176 70.9397 12.9309C70.9673 13.1135 70.9259 13.22 70.8433 13.2505L68.5993 14.118C68.5029 14.1636 68.4341 14.1332 68.379 14.0571C67.9936 13.3874 67.4704 13.0526 66.8371 13.0526C66.0799 13.0526 65.7082 13.3113 65.7082 13.844C65.7082 14.0723 65.8597 14.255 66.1488 14.3919C66.4516 14.5289 67.0299 14.6963 67.911 14.8942C70.1963 15.4117 71.339 16.5836 71.339 18.4099C71.339 19.6732 70.9259 20.6929 70.0999 21.4386C69.2739 22.1996 68.1312 22.5649 66.6719 22.5649C64.4141 22.5649 62.941 21.6821 62.2251 19.9471Z"
        fill="#0C3944"
      />
      <path
        d="M88.8232 15.2747C88.8232 14.2854 88.3964 13.7984 87.5428 13.7984C86.8407 13.7984 86.3451 14.1636 86.0697 14.879V21.9561C86.0697 22.1235 86.0285 22.1996 85.9321 22.1996H82.7932C82.683 22.1996 82.628 22.0931 82.628 21.88V11.1502C82.628 10.9675 82.6693 10.8762 82.7656 10.8762H85.7393C85.8908 10.8762 85.9596 10.998 85.9596 11.2415V12.7026C86.1661 12.0481 86.5378 11.5154 87.1023 11.1349C87.6667 10.7544 88.3413 10.5566 89.1123 10.5566C91.2049 10.5566 92.2512 11.7894 92.2512 14.255V21.88C92.2512 22.1083 92.1823 22.2148 92.0447 22.2148H88.9884C88.8645 22.2148 88.8094 22.1387 88.8094 21.9713V15.2747H88.8232Z"
        fill="#0C3944"
      />
      <path
        d="M93.2149 19.9775C93.1598 19.7949 93.1873 19.6884 93.2699 19.6427L95.4727 18.6991C95.569 18.6687 95.6241 18.6991 95.6516 18.7904C95.7618 19.1405 95.9683 19.4144 96.2849 19.6579C96.6016 19.8862 96.932 20.008 97.3037 20.008C98.1848 20.008 98.6253 19.7036 98.6253 19.11C98.6253 18.8056 98.4877 18.5621 98.2123 18.3643C97.937 18.1664 97.4138 17.9686 96.6429 17.7555C95.5553 17.4511 94.7843 17.0858 94.2887 16.6292C93.6829 16.0661 93.3801 15.2595 93.3801 14.2093C93.3801 13.0374 93.7793 12.1394 94.5916 11.5002C95.4038 10.861 96.519 10.5414 97.937 10.5414C100.153 10.5414 101.489 11.348 101.929 12.9613C101.957 13.1439 101.916 13.2505 101.833 13.2809L99.589 14.1484C99.4927 14.1941 99.4238 14.1636 99.3688 14.0875C98.9833 13.4179 98.4601 13.083 97.8269 13.083C97.0697 13.083 96.698 13.3418 96.698 13.8745C96.698 14.1028 96.8494 14.2854 97.1385 14.4224C97.4414 14.5594 98.0196 14.7268 98.9007 14.9246C101.186 15.4421 102.329 16.614 102.329 18.4404C102.329 19.7036 101.916 20.7233 101.09 21.4691C100.264 22.2301 99.121 22.5953 97.6616 22.5953C95.4176 22.6105 93.9308 21.7278 93.2149 19.9775Z"
        fill="#0C3944"
      />
      <path
        d="M75.1662 15.4269C75.3039 14.1636 75.8683 13.5244 76.8733 13.5244C77.9334 13.5244 78.5116 14.1636 78.5942 15.4269H75.1662ZM80.3977 11.8655C79.5304 10.9523 78.4565 10.4957 77.1762 10.4957C75.5517 10.4957 74.2576 11.0436 73.2939 12.1394C72.3302 13.2352 71.8483 14.6507 71.8483 16.3857C71.8483 18.2577 72.3577 19.734 73.3627 20.8451C74.3402 21.9104 75.6068 22.4431 77.1762 22.4431C77.8921 22.4431 78.608 22.3062 79.3239 22.017C79.6543 21.88 79.9709 21.6974 80.26 21.5147C80.5354 21.3473 80.797 21.1342 81.0448 20.8907C81.1549 20.7538 81.1824 20.632 81.1136 20.5559L79.4065 18.7448C79.3239 18.6839 79.2688 18.6839 79.2137 18.7448C79.1862 18.7752 79.1587 18.8209 79.1311 18.8513C78.5942 19.384 77.6856 19.4296 77.5066 19.4296C76.7357 19.4296 76.1437 19.2013 75.772 18.8513C75.4003 18.486 75.1937 18.0142 75.18 17.4206H81.2375C81.4715 17.4206 81.6092 17.3293 81.6367 17.1467C81.7194 16.751 81.7606 16.2335 81.7606 15.6247C81.7469 14.0723 81.3063 12.8243 80.3977 11.8655Z"
        fill="#0C3944"
      />
      <path
        d="M106.349 15.4725C106.486 14.2093 107.051 13.5701 108.056 13.5701C109.116 13.5701 109.694 14.2093 109.777 15.4725H106.349ZM111.58 11.9111C110.713 10.998 109.639 10.5414 108.359 10.5414C106.734 10.5414 105.44 11.0893 104.476 12.1851C103.513 13.2809 103.031 14.6963 103.031 16.4314C103.031 18.3034 103.54 19.7797 104.545 20.8907C105.523 21.9561 106.789 22.4888 108.359 22.4888C109.075 22.4888 109.79 22.3518 110.506 22.0626C110.837 21.9257 111.153 21.743 111.442 21.5604C111.718 21.393 111.979 21.1799 112.227 20.9364C112.337 20.7994 112.365 20.6777 112.296 20.6016L110.589 18.7904C110.506 18.7295 110.451 18.7295 110.396 18.7904C110.369 18.8209 110.341 18.8665 110.314 18.897C109.777 19.4296 108.868 19.4753 108.689 19.4753C107.918 19.4753 107.326 19.247 106.954 18.897C106.583 18.5317 106.376 18.0599 106.362 17.4663H112.42C112.654 17.4663 112.792 17.375 112.819 17.1924C112.902 16.7966 112.943 16.2792 112.943 15.6704C112.943 14.118 112.489 12.87 111.58 11.9111Z"
        fill="#0C3944"
      />
    </>
  ),
})

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'ChevronRightCircle',
  viewBox: '0 0 48 48',
  path: (
    <>
      <circle fill="transparent" cx="24" cy="24" r="23" stroke="currentColor" strokeWidth="2" />
      <path
        d="M29.8262 24.5809C29.8293 24.6566 29.8164 24.7321 29.7882 24.8025C29.7601 24.8729 29.7174 24.9365 29.6628 24.9892L19.1628 35.4892C19.1086 35.5439 19.0441 35.5873 18.973 35.6169C18.9019 35.6465 18.8257 35.6617 18.7487 35.6617C18.6717 35.6617 18.5954 35.6465 18.5243 35.6169C18.4532 35.5873 18.3887 35.5439 18.3345 35.4892C18.2269 35.3782 18.1667 35.2296 18.1667 35.075C18.1667 34.9204 18.2269 34.7719 18.3345 34.6609L28.4262 24.5809L18.3345 14.4892C18.2276 14.3802 18.1677 14.2336 18.1677 14.0809C18.1677 13.9282 18.2276 13.7816 18.3345 13.6725C18.3887 13.6179 18.4532 13.5745 18.5243 13.5448C18.5954 13.5152 18.6717 13.5 18.7487 13.5C18.8257 13.5 18.9019 13.5152 18.973 13.5448C19.0441 13.5745 19.1086 13.6179 19.1628 13.6725L29.6628 24.1725C29.7174 24.2252 29.7601 24.2888 29.7882 24.3592C29.8164 24.4296 29.8293 24.5051 29.8262 24.5809V24.5809Z"
        fill="currentColor"
      />
    </>
  ),
})

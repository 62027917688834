import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Quote',
  viewBox: '0 0 28 28',
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99957 17.3334C9.99957 17.3334 10.6662 18 10.7996 18.2667C12.5329 20.9334 13.8662 23.0667 15.8662 25.3334C14.6662 23.2 13.5996 21.3334 12.2662 19.4667C9.33291 14.6667 7.46624 8.80002 5.19957 3.46668C4.79957 2.53335 5.33291 1.06668 3.86624 0.800018C2.53291 0.533351 2.13291 1.33335 1.99957 1.73335C1.59957 1.46668 0.799575 1.33335 0.666241 2.00002C0.532908 3.33335 1.46624 4.13335 1.86624 5.20002C3.33291 8.66668 5.19957 11.7334 6.79957 15.0667C8.93291 19.6 11.7329 23.6 14.9329 27.4667C15.4662 26.5334 14.5329 26.1334 14.1329 25.6C13.7329 25.0667 13.5996 24.5334 13.1996 24C12.7996 23.3334 12.2662 22.6667 11.8662 21.8667C12.6662 22.9333 13.4662 24 14.3996 24.9334C14.5329 25.2 15.5996 25.7334 15.3329 25.2C13.9996 22.8 12.1329 20.8 10.6662 18.4C10.5329 18.2667 9.33291 17.7334 9.99957 17.3334Z"
        fill="#FFF056"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5996 18.8C23.5996 18.6667 22.5329 18.1333 23.1996 17.8667C23.1996 17.8667 23.7329 18.5333 23.8662 18.8C25.0662 21.3333 25.8662 23.3333 27.3329 25.4667C26.6662 23.4667 25.8662 21.7333 25.0662 20C22.9329 15.4667 22.1329 10.2667 20.7996 5.33334C20.5329 4.53334 21.3329 3.20001 19.9996 2.80001C18.9329 2.53334 18.3996 3.06668 18.2662 3.46668C17.8662 3.20001 17.3329 2.93334 17.0662 3.60001C16.7996 4.66668 17.4662 5.46668 17.5996 6.40001C18.3996 9.60001 19.7329 12.4 20.6662 15.4667C21.8662 19.7333 23.8662 23.4667 26.1329 27.0667C26.7996 26.2667 25.9996 25.8667 25.5996 25.3333C25.3329 24.8 25.1996 24.4 25.0662 23.8667C24.7996 23.2 24.5329 22.5333 24.1329 21.8667C24.6662 22.8 25.1996 23.8667 25.8662 24.8C25.9996 24.9333 26.7996 25.6 26.6662 25.0667C25.8662 22.9333 24.5329 20.9333 23.5996 18.8Z"
        fill="#FFF056"
      />
    </>
  ),
})

export { default as AuvergneRhoneAlpes } from './AuvergneRhoneAlpes'
export { default as BourgogneFrancheComte } from './BourgogneFrancheComte'
export { default as Bretagne } from './Bretagne'
export { default as CentreValDeLoire } from './CentreValDeLoire'
export { default as Corse } from './Corse'
export { default as GrandEst } from './GrandEst'
export { default as HautsDeFrance } from './HautsDeFrance'
export { default as IleDeFrance } from './IleDeFrance'
export { default as Normandie } from './Normandie'
export { default as NouvelleAquitaine } from './NouvelleAquitaine'
export { default as Occitanie } from './Occitanie'
export { default as OutreMer } from './OutreMer'
export { default as PaysDeLaLoire } from './PaysDeLaLoire'
export { default as ProvencesAlpesCoteDazur } from './ProvencesAlpesCoteDazur'
export { default as Anvers } from './Anvers'
export { default as BrabantFlamant } from './BrabantFlamant'
export { default as BrabantWallon } from './BrabantWallon'
export { default as Bruxelles } from './Bruxelles'
export { default as FlandreOccidentale } from './FlandreOccidentale'
export { default as FlandreOrientale } from './FlandreOrientale'
export { default as Hainaut } from './Hainaut'
export { default as Liege } from './Liege'
export { default as Limbourg } from './Limbourg'
export { default as Luxembourg } from './Luxembourg'
export { default as Namur } from './Namur'

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'LendingInvest',
  viewBox: '0 0 100 100',
  path: (
    <>
      <path
        d="M32.2 65.8H10.6C10.3 65.8 10 66.1 10 66.4V89.4C10 89.7 10.3 90 10.6 90H32.2C32.5 90 32.8 89.7 32.8 89.4V66.4C32.9 66.1 32.6 65.8 32.2 65.8ZM30.9 88H12V67.8H30.9V88Z"
        fill="#2A2A2A"
      />
      <path
        d="M60.8 53.8H39.2C38.9 53.8 38.6 54.1 38.6 54.4V89.4C38.6 89.7 38.9 90 39.2 90H60.8C61.1 90 61.4 89.7 61.4 89.4V54.4C61.4 54.1 61.1 53.8 60.8 53.8ZM40.6 88V55.8H59.5V88H40.6Z"
        fill="#2A2A2A"
      />
      <path
        d="M89.4 10H67.8C67.5 10 67.2 10.3 67.2 10.6V89.4C67.2 89.7 67.5 90 67.8 90H89.4C89.7 90 90 89.7 90 89.4V10.6C90 10.3 89.7 10 89.4 10ZM88 88H69.1V12H88V88Z"
        fill="#2A2A2A"
      />
      <path
        d="M28.7 47.3C18.4 47.3 10.1 39 10.1 28.7C10.1 18.4 18.4 10.1 28.7 10.1C39 10.1 47.3 18.4 47.3 28.7C47.3 38.9 38.9 47.3 28.7 47.3ZM28.7 12.1C19.5 12.1 12.1 19.5 12.1 28.7C12.1 37.9 19.5 45.3 28.7 45.3C37.9 45.3 45.3 37.9 45.3 28.7C45.3 19.5 37.8 12.1 28.7 12.1Z"
        fill="#2A2A2A"
      />
      <path
        d="M32.8 21.9C32 21.6 31.1 21.4 30.2 21.4C27.4 21.4 24.9 23.2 23.8 25.7H22.5C21.9 25.7 21.5 26.1 21.5 26.7C21.5 27.3 21.9 27.7 22.5 27.7H23.2C23.2 28 23.1 28.4 23.1 28.7C23.1 29 23.1 29.4 23.2 29.7H22.5C21.9 29.7 21.5 30.1 21.5 30.7C21.5 31.3 21.9 31.7 22.5 31.7H23.8C24.9 34.2 27.3 36 30.2 36C30.9 36 31.6 35.9 32.3 35.7C32.8 35.5 33.1 35 32.9 34.4C32.7 33.9 32.2 33.6 31.6 33.8C31.1 34 30.6 34 30.1 34C28.4 34 26.9 33.1 26 31.7H28.1C28.7 31.7 29.1 31.3 29.1 30.7C29.1 30.1 28.7 29.7 28.1 29.7H25.2C25.1 29.4 25.1 29 25.1 28.7C25.1 28.4 25.1 28 25.2 27.7H28.1C28.7 27.7 29.1 27.3 29.1 26.7C29.1 26.1 28.7 25.7 28.1 25.7H26C26.9 24.3 28.4 23.4 30.1 23.4C30.7 23.4 31.3 23.5 31.9 23.8C32.4 24 33 23.8 33.2 23.3C33.6 22.7 33.3 22.1 32.8 21.9Z"
        fill="#FF6C4A"
      />
    </>
  ),
})

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'TadaTopRight',
  viewBox: '0 0 35 39',
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 9.00001C17.2 7.20001 18.2 5.60001 19.2 3.60001C18 5.00001 17.2 6.20001 16.4 7.60001C14 10.8 10.8 13.6 7.79999 16.6C7.39999 17.2 6.19999 17.2 6.39999 18.2C6.59999 19.2 7.19999 19.2 7.59999 19.2C7.59999 19.6 7.59999 20.2 8.19999 20C8.99999 19.8 9.39999 19 9.99999 18.4C11.8 16.6 13.4 14.4 15.2 12.4C17.6 9.80001 19.4 6.80001 21.2 3.60001C20 3.60001 20 4.20001 19.8 4.80001C19.6 5.20001 19.2 5.40001 19 5.80001C18.6 6.20001 18.4 6.80001 18 7.20001C18.4 6.40001 19 5.60001 19.4 4.80001C19.6 4.60001 19.6 3.80001 19.4 4.20001C18 5.60001 17.2 7.40001 16 9.00001Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.8 36.8C35.8 36 37.6 35.2 39.4 34.2C37.8 34.6 36.2 35.2 34.8 35.8C31 37.2 26.8 37.8 22.8 38.6C22.2 38.8 21.2 38.2 20.8 39.2C20.4 40 21 40.4 21.2 40.6C21 40.8 20.8 41.4 21.2 41.6C22 41.8 22.8 41.4 23.4 41.2C26 40.6 28.4 39.8 31 39.2C34.4 38.4 37.6 37 40.8 35.2C40.2 34.6 39.8 35.2 39.4 35.6C39 35.8 38.6 35.8 38.2 36C37.6 36.2 37.2 36.4 36.6 36.6C37.4 36.2 38.2 35.8 39.2 35.4C39.4 35.4 40 34.6 39.6 34.8C37.4 35 35.6 36 33.8 36.8Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6 19C32.2 17.6 33.6 16.2 35 14.6C33.6 15.6 32.4 16.6 31.2 17.6C27.6 20.6 25.4 21.2 21.8 23.2C21.2 23.6 20 23.4 20 24.4C20 25.4 20.6 25.6 20.8 25.6C20.6 26 20.6 26.4 21.2 26.4C22 26.4 22.6 25.6 23.2 25.4C25.4 24 26.4 23.4 28.4 22C31.6 20 34 17.6 36.4 15C35.6 14.8 35.6 15.4 35.2 15.8C34.8 16.2 34.6 16.4 34.2 16.6C33.8 17 33.4 17.4 32.8 17.8C33.4 17.2 34.2 16.4 34.8 15.8C35 15.6 35.2 14.8 35 15.2C33.6 16.2 32.2 17.6 30.6 19Z"
        fill="currentColor"
      />
    </>
  ),
  defaultProps: {
    w: '48px',
    h: '48px',
    color: 'orange',
    top: '-26px',
    right: '-32px',
    position: 'absolute',
    zIndex: 1,
  },
})

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Whatsapp',
  viewBox: '0 0 25 24',
  path: (
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.5089 4.90543C17.6383 3.03253 15.1473 2 12.4965 2C7.03293 2 2.58566 6.44496 2.58334 11.9086C2.58334 13.6541 3.03941 15.3603 3.90757 16.8628L2.5 22L7.75524 20.6225C9.20216 21.412 10.8343 21.8287 12.4919 21.8287H12.4965C17.9601 21.8287 22.4074 17.3837 22.4097 11.9178C22.4097 9.26936 21.3795 6.78065 19.5089 4.90774V4.90543ZM12.4965 20.1526H12.4942C11.0149 20.1526 9.56563 19.7544 8.3016 19.0043L8.00064 18.826L4.88222 19.6432L5.71565 16.6035L5.51887 16.291C4.6947 14.9807 4.25946 13.4643 4.25946 11.9086C4.25946 7.36636 7.95665 3.67149 12.5035 3.67149C14.7051 3.67149 16.7725 4.53039 18.3282 6.08612C19.884 7.64417 20.7405 9.71154 20.7382 11.9132C20.7382 16.4554 17.041 20.1502 12.4988 20.1502L12.4965 20.1526ZM17.0156 13.9829C16.7679 13.8579 15.5501 13.2606 15.3232 13.1772C15.0964 13.0939 14.932 13.0522 14.7653 13.3022C14.6009 13.5499 14.1263 14.1079 13.9805 14.2723C13.837 14.4366 13.6911 14.4575 13.4434 14.3348C13.1957 14.2097 12.397 13.9505 11.4524 13.1055C10.7162 12.448 10.2185 11.6377 10.075 11.39C9.93142 11.1423 10.0587 11.008 10.1838 10.8853C10.2949 10.7742 10.4315 10.5959 10.5542 10.4524C10.6769 10.3088 10.7185 10.2047 10.8019 10.0403C10.8852 9.87591 10.8436 9.73006 10.7811 9.60736C10.7185 9.48235 10.2231 8.26461 10.0171 7.76919C9.81566 7.28533 9.61193 7.35247 9.45914 7.34321C9.3156 7.33627 9.14892 7.33395 8.98455 7.33395C8.82018 7.33395 8.55163 7.39646 8.32475 7.64417C8.09787 7.89189 7.45891 8.49149 7.45891 9.70923C7.45891 10.927 8.34558 12.1053 8.4706 12.2697C8.5933 12.4341 10.2162 14.9367 12.7026 16.0086C13.2929 16.2632 13.7559 16.416 14.1148 16.5295C14.7074 16.717 15.2492 16.6915 15.6751 16.6267C16.152 16.5549 17.1406 16.0271 17.3466 15.4483C17.5527 14.8695 17.5527 14.3741 17.4902 14.2699C17.4277 14.1658 17.2633 14.1056 17.0156 13.9806V13.9829Z"
      fill="currentColor"
    />
  ),
})

import { Link as ChakraLink, LinkProps } from '@chakra-ui/react'

import { useV2Link } from '../../lib/hooks'

interface Props extends LinkProps {
  path: string
}

const LinkV2 = ({ path, children, ...props }: Props) => {
  const link = useV2Link(path)
  return (
    <ChakraLink {...props} href={link}>
      {children}
    </ChakraLink>
  )
}

export default LinkV2

export { default as LogoAgri } from './Logo'
export { default as PersonAgri } from './Person'
export { default as AttestationAgri } from './Attestation'
export { default as KbisAgri } from './Kbis'
export { default as RibAgri } from './Rib'
export { default as ArrowAgri } from './Arrow'
export { default as ForfaitAgri } from './Forfait'
export { default as TankAgri } from './Tank'
export { default as ColdAgri } from './Cold'
export { default as BurnAgri } from './Burn'
export { default as MousquetairesAgri } from './Mousquetaires'
export { default as VerticalArrowAgri } from './VerticalArrow'

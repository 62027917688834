import { Box, BoxProps } from '@chakra-ui/react'
import { FC } from 'react'

interface Props {
  width?: string
  height?: string
  type?: string
  color?: string
}

const ColoredDot: FC<Props & BoxProps> = ({ type, width, height, color, ...rest }) => {
  const bgColor = type === 'donation' ? 'green' : 'blue' || color || 'dark_green'

  return <Box width={width || '11px'} height={height || '11px'} bgColor={bgColor} borderRadius="50%" {...rest} />
}

export default ColoredDot

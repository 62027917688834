import { useEffect, useRef, ReactChild } from 'react'
import ReactDOM from 'react-dom'

const pinW = 25
const pinH = 34

// base function for creating DOM div node
function createOverlayElement() {
  const el = document.createElement('div')
  el.style.position = 'absolute'
  el.style.display = 'inline-block'
  el.style.width = `${pinW}px`
  el.style.height = `${pinH}px`
  return el
}

export type Props = {
  map: google.maps.Map | null
  position: { lat: number; lng: number }
  children?: ReactChild
}

const OverlayContainer = ({ position, map, children }: Props) => {
  const overlay = useRef<google.maps.OverlayView | null>(null)
  const el = useRef<HTMLElement | null>(null)

  class OverlayView extends window.google.maps.OverlayView {
    position: google.maps.LatLng | null = null
    content: any = null

    constructor({ position, content }: { position: google.maps.LatLng | null; content: HTMLElement }) {
      super()
      position && (this.position = position)
      content && (this.content = content)
    }

    /** Called when the popup is added to the map. */
    onAdd = () => {
      if (this.content) {
        this.getPanes()!.floatPane.appendChild(this.content)
      }
    }

    /** Called when the popup is removed from the map. */
    onRemove = () => {
      if (this.content?.parentElement) {
        this.content.parentElement.removeChild(this.content)
      }
    }

    /** Called each frame when the popup needs to draw itself. */
    draw = () => {
      if (this.position) {
        const divPosition = this.getProjection().fromLatLngToDivPixel(this.position)
        if (divPosition) {
          this.content.style.left = divPosition.x - pinW * 0.5 + 'px'
          this.content.style.top = divPosition.y - pinH + 'px'
        }
      }
    }
  }

  useEffect(() => {
    if (overlay.current) {
      overlay.current.setMap(null)
    }
  }, [])

  if (map) {
    el.current = el.current || createOverlayElement()
    overlay.current = new OverlayView({
      position: new google.maps.LatLng(position.lat, position.lng),
      content: el.current,
    })
    overlay.current.setMap(map)
    return ReactDOM.createPortal(children, el.current)
  }

  return null
}

export default OverlayContainer

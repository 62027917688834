import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'VerticalArrow',
  viewBox: '0 0 38 616',
  path: (
    <>
      <path
        d="M17.2322 614.768C18.2085 615.744 19.7915 615.744 20.7678 614.768L36.6777 598.858C37.654 597.882 37.654 596.299 36.6777 595.322C35.7014 594.346 34.1184 594.346 33.1421 595.322L19 609.464L4.85786 595.322C3.88155 594.346 2.29864 594.346 1.32233 595.322C0.34602 596.299 0.34602 597.882 1.32233 598.858L17.2322 614.768ZM16.5 0L16.5 613H21.5L21.5 0L16.5 0Z"
        fill="url(#paint0_linear_343_187)"
      />
      <defs>
        <linearGradient id="paint0_linear_343_187" x1="18.5" y1="0" x2="18.5" y2="613" gradientUnits="userSpaceOnUse">
          <stop stopColor="#4E81BA" />
          <stop offset="0.5" stopColor="#E6352C" />
          <stop offset="1" stopColor="#B4CF53" />
        </linearGradient>
      </defs>
    </>
  ),
})

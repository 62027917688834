import { Flex, Button } from '@chakra-ui/react'

import { Icon } from '../..'

const DropdownArrowIndicator = ({
  // isFocused,
  onClick,
  disabled,
}: {
  isFocused?: boolean
  onClick?: () => void
  disabled?: boolean
}) => {
  return (
    <Flex bgColor="transparent" align="center" borderRadius="100%" justify="center" mb="2px" mr="8px">
      <Button variant="ghost" onClick={onClick} disabled={disabled} isDisabled={disabled}>
        <Icon name="ChevronDown" size="4xs" color="dark_green" />
      </Button>
    </Flex>
  )
}

export default DropdownArrowIndicator

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'ColoredDiamond',
  viewBox: '0 0 22 22',
  path: (
    <>
      <path
        d="M10.8546 17.6001C12.0569 17.6001 13.0187 18.5779 13.0187 19.8001C13.0187 21.0223 12.0569 22.0001 10.8546 22.0001C9.6524 22.0001 8.69061 21.0223 8.69061 19.8001C8.69061 18.5779 9.6524 17.6001 10.8546 17.6001Z"
        fill="#A8C455"
      />
      <path
        d="M10.8546 0C12.0569 0 13.0187 0.977778 13.0187 2.2C13.0187 3.42222 12.0569 4.4 10.8546 4.4C9.6524 4.4 8.69061 3.42222 8.69061 2.2C8.69061 0.977778 9.6524 0 10.8546 0Z"
        fill="#A8C455"
      />
      <path
        d="M10.8547 8.86963C12.0569 8.86963 13.0187 9.84741 13.0187 11.0696C13.0187 12.2919 12.0569 13.2696 10.8547 13.2696C9.65246 13.2696 8.69067 12.2919 8.69067 11.0696C8.69067 9.84741 9.65246 8.86963 10.8547 8.86963Z"
        fill="#F1B748"
      />
      <path
        d="M19.5107 8.86963C20.7129 8.86963 21.6747 9.84741 21.6747 11.0696C21.6747 12.2919 20.7129 13.2696 19.5107 13.2696C18.3085 13.2696 17.3467 12.2919 17.3467 11.0696C17.3467 9.84741 18.3085 8.86963 19.5107 8.86963Z"
        fill="#F1B748"
      />
      <path
        d="M2.16403 8.86963C3.36626 8.86963 4.32805 9.84741 4.32805 11.0696C4.32805 12.2919 3.36626 13.2696 2.16403 13.2696C0.961789 13.2696 0 12.2919 0 11.0696C0.0343496 9.84741 0.961789 8.86963 2.16403 8.86963Z"
        fill="#F1B748"
      />
      <path
        d="M15.2169 13.2349C16.4191 13.2349 17.3809 14.2126 17.3809 15.4349C17.3809 16.6571 16.4191 17.6349 15.2169 17.6349C14.0146 17.6349 13.0529 16.6571 13.0529 15.4349C13.0529 14.2126 14.0146 13.2349 15.2169 13.2349Z"
        fill="#00B4D5"
      />
      <path
        d="M6.52621 13.2349C7.72845 13.2349 8.69024 14.2126 8.69024 15.4349C8.69024 16.6571 7.72845 17.6349 6.52621 17.6349C5.32397 17.6349 4.36218 16.6571 4.36218 15.4349C4.36218 14.2126 5.32397 13.2349 6.52621 13.2349Z"
        fill="#00B4D5"
      />
      <path
        d="M15.2169 4.43506C16.4191 4.43506 17.3809 5.41284 17.3809 6.63506C17.3809 7.85728 16.4191 8.83506 15.2169 8.83506C14.0146 8.83506 13.0529 7.85728 13.0529 6.63506C13.0529 5.41284 14.0146 4.43506 15.2169 4.43506Z"
        fill="#EA8C42"
      />
      <path
        d="M6.52621 4.43506C7.72845 4.43506 8.69024 5.41284 8.69024 6.63506C8.69024 7.85728 7.72845 8.83506 6.52621 8.83506C5.32397 8.83506 4.36218 7.85728 4.36218 6.63506C4.36218 5.41284 5.32397 4.43506 6.52621 4.43506Z"
        fill="#EA8C42"
      />
    </>
  ),
})

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'QuestionMark',
  viewBox: '0 0 7 14',
  path: (
    <>
      <path
        d="M3 9.55995C2.87079 9.56005 2.74655 9.51012 2.65334 9.42064C2.56012 9.33115 2.50517 9.20906 2.5 9.07995V8.66995C2.49645 7.98496 2.68109 7.31215 3.03378 6.72493C3.38647 6.13771 3.89365 5.65862 4.5 5.33995C5.33 4.76995 5.8 4.39995 5.8 3.55995C5.75558 3.11783 5.54919 2.70778 5.22058 2.4087C4.89196 2.10962 4.46433 1.94266 4.02 1.93995C3.5783 1.99925 3.15407 2.15103 2.77499 2.38537C2.39591 2.61971 2.0705 2.93135 1.82 3.29995C1.77852 3.35063 1.72729 3.39248 1.66935 3.42303C1.61141 3.45357 1.54793 3.4722 1.48268 3.47779C1.41742 3.48338 1.3517 3.47584 1.28941 3.4556C1.22711 3.43535 1.16951 3.40283 1.12 3.35994C1.06932 3.31846 1.02747 3.26723 0.99692 3.20929C0.966373 3.15135 0.947752 3.08788 0.942159 3.02262C0.936565 2.95736 0.944112 2.89164 0.964353 2.82935C0.984594 2.76705 1.01712 2.70945 1.06 2.65995C1.39952 2.16708 1.84532 1.75668 2.36453 1.459C2.88374 1.16132 3.46313 0.983944 4.06 0.939945C4.7704 0.938768 5.4543 1.2096 5.97129 1.69683C6.48827 2.18406 6.79912 2.85072 6.84 3.55995C6.81815 4.11038 6.64515 4.64418 6.33999 5.1028C6.03483 5.56143 5.60928 5.92718 5.11 6.15995C4.63548 6.38337 4.23414 6.73693 3.95267 7.1795C3.6712 7.62207 3.52116 8.13545 3.52 8.65995V8.99995C3.52268 9.06644 3.51205 9.13279 3.48875 9.19512C3.46545 9.25745 3.42994 9.31451 3.3843 9.36294C3.33867 9.41137 3.28382 9.4502 3.22298 9.47716C3.16214 9.50412 3.09654 9.51867 3.03 9.51995L3 9.55995Z"
        fill="currentColor"
      />
      <path
        d="M2.99 13.0999C3.43735 13.0999 3.8 12.7373 3.8 12.2899C3.8 11.8426 3.43735 11.4799 2.99 11.4799C2.54265 11.4799 2.18 11.8426 2.18 12.2899C2.18 12.7373 2.54265 13.0999 2.99 13.0999Z"
        fill="currentColor"
      />
    </>
  ),
})

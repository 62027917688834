import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Calendar',
  viewBox: '0 0 10 11',
  path: (
    <>
      <path
        d="M7.16667 2.21484H2.16667C1.24619 2.21484 0.5 2.96104 0.5 3.88151V8.04818C0.5 8.96865 1.24619 9.71484 2.16667 9.71484H7.16667C8.08714 9.71484 8.83333 8.96865 8.83333 8.04818V3.88151C8.83333 2.96104 8.08714 2.21484 7.16667 2.21484Z"
        stroke="#BDBDBD"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#FFFFFF"
      />
      <path
        d="M0.5 4.71419H8.83333M3 1.38086V3.04753V1.38086ZM6.33333 1.38086V3.04753V1.38086Z"
        stroke="#BDBDBD"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#FFFFFF"
      />
    </>
  ),
})

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'SmileArrow',
  viewBox: '0 0 183 85',
  path: (
    <>
      <path
        d="M145.858918,66.6163394 C145.779268,81.0481454 142.84264,95.3213319 137.218469,108.612379 C131.696025,121.686897 123.688092,133.565086 113.639007,143.58786 C93.0222822,164.254447 65.0199368,175.853378 35.8282557,175.818151 C31.5882155,175.818151 28.1509837,172.380833 28.1509837,168.140784 C28.1509837,163.900735 31.5882155,160.463497 35.8282557,160.463497 C60.954008,160.531513 85.0627112,150.544753 102.780465,132.729297 C120.381426,115.223128 130.346988,91.4679572 130.504054,66.6438497 L138.191883,56.3933919 L145.858918,66.6163394 Z M173.507334,82.1551685 C176.899366,79.6111393 177.586812,74.7990054 175.042788,71.4069666 L144.3337,30.4614354 C142.883814,28.5282496 140.60836,27.3905206 138.191883,27.3905206 C135.775405,27.3905206 133.499951,28.5282496 132.050065,30.4614354 L101.340977,71.4069666 C99.6952818,73.601231 99.3452207,76.5067845 100.422658,79.0291333 C101.500096,81.551482 103.841343,83.3074212 106.564476,83.6355056 C109.287608,83.9635899 111.978917,82.8139755 113.624612,80.6197111 L138.191883,47.8632861 L162.759153,80.6197111 C163.980837,82.2486256 165.799566,83.3255112 167.81524,83.6134653 C169.830915,83.9014194 171.878423,83.3768544 173.507334,82.1551685 L173.507334,82.1551685 Z"
        transform="rotate(60 161.028 64.356)"
        fill="currentColor"
      ></path>
    </>
  ),
})

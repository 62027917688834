import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'LogoInnocentFull',
  viewBox: '0 0 176 78',
  path: (
    <>
      <path
        d="M59.2998 36.7148C59.9206 36.7148 60.4379 36.9226 60.8518 37.2861C61.2657 37.6496 61.5244 38.117 61.5244 38.6882C61.5244 39.2075 61.3174 39.6749 60.9036 40.0903C60.4897 40.4539 59.9723 40.6616 59.3515 40.6616C58.7307 40.6616 58.2134 40.4539 57.7995 40.0903C57.3856 39.7268 57.127 39.2595 57.127 38.6882C57.127 38.1689 57.3339 37.7015 57.7478 37.338C58.1616 36.9226 58.679 36.7148 59.2998 36.7148ZM57.5408 41.7002H61.007V53.7481H57.5408V41.7002Z"
        fill="currentColor"
      />
      <path
        d="M64.4746 41.7003H67.9408V42.9466C68.7168 42.3754 69.4411 41.9599 70.0619 41.7522C70.6827 41.5445 71.3553 41.3887 72.0278 41.3887C73.4247 41.3887 74.5628 41.8041 75.5458 42.635C76.3735 43.362 76.7356 44.4007 76.7356 45.8028V53.7482H73.2694V48.5032C73.2694 47.0491 73.2177 46.1144 73.0625 45.647C72.9073 45.1796 72.6486 44.8161 72.2865 44.5565C71.9244 44.2968 71.4587 44.1929 70.9414 44.1929C70.2171 44.1929 69.648 44.4007 69.1307 44.8161C68.6134 45.2316 68.303 45.8028 68.096 46.5298C67.9925 46.8933 67.9408 47.7242 67.9408 48.9706V53.8001H64.4746V41.7003Z"
        fill="currentColor"
      />
      <path
        d="M79.8887 41.7003H83.3549V42.9466C84.1309 42.3754 84.8552 41.9599 85.476 41.7522C86.0968 41.5445 86.7693 41.3887 87.4419 41.3887C88.8387 41.3887 89.9769 41.8041 90.9598 42.635C91.7876 43.362 92.1497 44.4007 92.1497 45.8028V53.7482H88.6835V48.5032C88.6835 47.0491 88.6318 46.1144 88.4766 45.647C88.3214 45.1796 88.0627 44.8161 87.7006 44.5565C87.3384 44.2968 86.8728 44.1929 86.3555 44.1929C85.6312 44.1929 85.0621 44.4007 84.5448 44.8161C84.0274 45.2316 83.717 45.8028 83.5101 46.5298C83.4066 46.8933 83.3549 47.7242 83.3549 48.9706V53.8001H79.8887V41.7003Z"
        fill="currentColor"
      />
      <path
        d="M101.412 41.3887C102.705 41.3887 103.947 41.6483 105.085 42.2196C106.223 42.7908 107.154 43.5698 107.775 44.5045C108.396 45.4912 108.758 46.5298 108.758 47.6723C108.758 48.8148 108.448 49.8534 107.775 50.8401C107.103 51.8268 106.223 52.6057 105.137 53.177C103.998 53.7482 102.757 54.0078 101.412 54.0078C99.4459 54.0078 97.7386 53.3847 96.3418 52.1903C94.945 50.9439 94.2207 49.4899 94.2207 47.7242C94.2207 45.8547 94.9967 44.2968 96.6005 43.0505C98.049 41.9599 99.6528 41.3887 101.412 41.3887ZM101.515 44.2449C100.429 44.2449 99.5493 44.5564 98.8251 45.2315C98.1008 45.8547 97.7386 46.6856 97.7386 47.7242C97.7386 48.7628 98.1008 49.5937 98.8251 50.2688C99.5493 50.892 100.429 51.2555 101.515 51.2555C102.602 51.2555 103.533 50.9439 104.257 50.2688C104.981 49.5937 105.344 48.7628 105.344 47.7762C105.344 46.7375 104.981 45.9066 104.257 45.2835C103.533 44.6084 102.602 44.2449 101.515 44.2449Z"
        fill="currentColor"
      />
      <path
        d="M123.606 44.141L120.968 45.5431C120.451 45.0238 119.985 44.7122 119.519 44.5045C119.054 44.2968 118.485 44.1929 117.812 44.1929C116.622 44.1929 115.691 44.5045 114.915 45.1796C114.191 45.8547 113.829 46.6856 113.829 47.7762C113.829 48.8148 114.191 49.6457 114.863 50.2688C115.588 50.892 116.467 51.2555 117.657 51.2555C119.054 51.2555 120.192 50.7881 120.968 49.9053L123.451 51.5152C122.106 53.177 120.192 54.0078 117.709 54.0078C115.484 54.0078 113.725 53.3847 112.484 52.1383C111.242 50.892 110.621 49.4379 110.621 47.7242C110.621 46.5817 110.932 45.4912 111.552 44.5045C112.173 43.5178 113.001 42.7389 114.087 42.2196C115.174 41.6483 116.415 41.3887 117.76 41.3887C119.002 41.3887 120.14 41.6483 121.123 42.1157C122.158 42.5831 122.986 43.2582 123.606 44.141Z"
        fill="currentColor"
      />
      <path
        d="M137.937 48.6078H127.746C127.901 49.4907 128.263 50.1658 128.936 50.6331C129.556 51.1525 130.384 51.4121 131.367 51.4121C132.557 51.4121 133.54 50.9967 134.419 50.2177L137.058 51.4121C136.385 52.2949 135.609 52.97 134.678 53.4374C133.747 53.8529 132.66 54.0606 131.367 54.0606C129.401 54.0606 127.797 53.4893 126.556 52.2949C125.314 51.1005 124.693 49.5945 124.693 47.8289C124.693 46.0113 125.314 44.4534 126.556 43.259C127.797 42.0646 129.349 41.4414 131.212 41.4414C133.178 41.4414 134.833 42.0646 136.075 43.259C137.317 44.4534 137.937 46.0632 137.937 48.0366V48.6078ZM134.782 46.219C134.575 45.5439 134.161 45.0246 133.54 44.6092C132.919 44.1937 132.195 43.986 131.419 43.986C130.539 43.986 129.763 44.1937 129.091 44.6611C128.677 44.9727 128.263 45.4401 127.953 46.1671H134.782V46.219Z"
        fill="currentColor"
      />
      <path
        d="M140.521 41.7003H143.988V42.9466C144.764 42.3754 145.488 41.9599 146.109 41.7522C146.73 41.5445 147.402 41.3887 148.075 41.3887C149.471 41.3887 150.61 41.8041 151.593 42.635C152.42 43.362 152.782 44.4007 152.782 45.8028V53.7482H149.316V48.5032C149.316 47.0491 149.265 46.1144 149.109 45.647C148.954 45.1796 148.695 44.8161 148.333 44.5565C147.971 44.2968 147.506 44.1929 146.988 44.1929C146.264 44.1929 145.695 44.4007 145.178 44.8161C144.66 45.2316 144.35 45.8028 144.143 46.5298C144.039 46.8933 143.988 47.7242 143.988 48.9706V53.8001H140.521V41.7003Z"
        fill="currentColor"
      />
      <path
        d="M156.355 37.2344H159.821V41.7004H161.89V44.297H159.821V53.7484H156.355V44.297H154.596V41.7004H156.355V37.2344Z"
        fill="currentColor"
      />
      <path
        d="M46.9229 17.9685C44.5432 14.9565 39.1111 15.32 37.3521 15.6316C37.3521 15.6316 34.1446 14.0218 29.0229 15.2681C23.8495 16.5664 15.8307 20.5651 14.796 25.4465C13.7613 30.2761 30.5232 26.3294 31.92 25.9659C33.3168 25.6023 49.3545 20.9805 46.9229 17.9685ZM31.144 23.629C27.8847 24.2002 18.4691 25.862 19.2968 23.7328C20.9006 19.7342 30.5749 18.7994 30.5749 18.7994C30.5749 18.7994 30.8336 18.9033 31.1957 19.4745C31.5579 20.0457 34.8689 19.5784 35.5414 19.3706C36.214 19.1629 36.99 18.0724 41.4391 18.9033C45.8365 19.7342 33.9894 23.1097 31.144 23.629Z"
        fill="currentColor"
      />
      <path
        d="M32.4364 45.0244C32.4364 45.0244 33.0055 40.818 30.7809 41.7527C28.6081 42.6875 28.815 47.7248 30.2118 48.8153C31.6087 49.9059 33.5228 48.8153 33.1607 46.9977C32.7986 45.2321 32.4364 45.0244 32.4364 45.0244Z"
        fill="currentColor"
      />
      <path
        d="M38.4882 43.987C38.4882 43.987 39.5746 42.533 38.0226 41.3905C36.4706 40.248 34.8151 43.0523 35.0737 44.8699C35.229 46.1162 36.9879 47.9338 38.2295 46.1162C38.7469 45.3892 38.0226 45.1295 38.4882 43.987Z"
        fill="currentColor"
      />
      <path
        d="M40.3515 31.0031C38.0235 30.2242 30.8841 36.2481 30.8841 36.2481C30.1081 33.1842 28.1939 33.34 28.1939 33.34C25.3486 33.2361 22.141 37.2348 22.141 37.2348C17.0193 42.6875 15.726 47.4132 15.726 47.4132C13.2945 53.7488 15.1052 58.0071 15.1052 58.0071C17.9506 63.6676 25.5038 63.7195 25.5038 63.7195C31.1945 64.1349 39.8859 59.6689 39.8859 59.6689C50.181 55.2029 49.0946 45.3879 49.0946 45.3879C48.4738 33.2361 40.3515 31.0031 40.3515 31.0031ZM41.0758 55.6702C34.0399 59.617 26.7971 59.9286 26.7971 59.9286C17.2263 60.1882 18.3644 52.5544 18.3644 52.5544C18.7783 46.5304 22.5032 41.9605 22.5032 41.9605C26.3315 36.7155 27.9353 36.9232 27.9353 36.9232C28.5044 36.8194 29.2804 37.2348 29.2804 37.2348C30.0046 37.7022 30.0564 37.1829 30.0564 37.1829C30.4703 36.5597 30.6772 36.9232 30.6772 36.9232C32.0223 38.585 33.4191 37.0271 33.4191 37.0271C34.2469 36.1443 36.6784 34.7421 36.6784 34.7421C38.9029 33.3919 41.231 35.7807 41.231 35.7807C45.318 39.1043 45.4215 46.842 45.4215 46.842C45.6801 53.4891 41.0758 55.6702 41.0758 55.6702Z"
        fill="currentColor"
      />
    </>
  ),
})

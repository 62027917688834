import {
  Modal,
  Input,
  Heading,
  Text,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Flex,
  BoxProps,
  FormControl,
  FormLabel,
  Button,
} from '@chakra-ui/react'
import { Trans, useTranslation } from 'next-i18next'
import { FC, useState } from 'react'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'

import { usePlatform } from '@miimosa/design-system'

interface Props {
  isOpen: boolean
  onClose: () => void
  title: string
  slug: string
}

type FormValues = {
  email: string
}

const WarnedModal: FC<Props & BoxProps> = ({ isOpen, onClose, slug, title }) => {
  const [teased, setTeased] = useState({ text: '', color: '' })
  const methods = useForm<FormValues>({
    reValidateMode: 'onBlur',
    shouldUnregister: true,
    defaultValues: {},
  })
  const { register, handleSubmit } = methods
  const { t } = useTranslation('common')
  const { platform } = usePlatform()

  const onSubmit: SubmitHandler<FormValues> = async ({ email }) => {
    try {
      const result = await fetch(`/nextapi/projects/teased`, {
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify({
          slug,
          email,
          platform_country: `mii_${platform}` || 'mii_fr',
        }),
      })

      if (result.status == 200 || result.status == 201) {
        setTeased({ text: t('warned_modal.subscribed'), color: 'main_green' })
      } else if (result.status == 204) {
        setTeased({ text: t('warned_modal.already_subscribed'), color: 'error' })
      }

      return result
    } catch (err) {}
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose(), setTeased({ text: '', color: '' })
        }}
      >
        <ModalOverlay />
        <ModalContent minWidth="650px">
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" p="10">
              <Heading textAlign="center" fontWeight="700" size="lg">
                {t('warned_modal.title')}
              </Heading>
              <Text size="md" mt="8">
                <Trans
                  t={t}
                  i18nKey="warned_modal.project"
                  values={{
                    title: title,
                  }}
                />
              </Text>
              <Text size="md" mt="2">
                {t('warned_modal.confirm')}
              </Text>
              <FormProvider {...methods}>
                <Flex as="form" mt="2" direction="column" align="center" onSubmit={handleSubmit(onSubmit)}>
                  <FormControl
                    alignItems="center"
                    width="auto"
                    justifyContent="center"
                    variant="floating"
                    mt="6"
                    isRequired
                  >
                    <Input
                      borderRadius="4px"
                      borderColor="dark_green"
                      _placeholder={{ color: 'border' }}
                      type="email"
                      placeholder="miimosa@mail.com"
                      height="58px"
                      width="320px"
                      px="4"
                      {...register('email', {
                        required: t('forms.required_fields'),
                        onChange: () => {
                          setTeased({ text: '', color: '' })
                        },
                      })}
                    />
                    <FormLabel>{t('forms.email')}</FormLabel>
                  </FormControl>
                  {teased.text ? (
                    <Text size="md" mt="4" color={teased.color}>
                      {teased.text}
                    </Text>
                  ) : (
                    <Button mt="4" alignSelf="center" variant="primary" size="md" type="submit">
                      {t('forms.subscribing')}
                    </Button>
                  )}
                </Flex>
              </FormProvider>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default WarnedModal

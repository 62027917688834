import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Attestation',
  viewBox: '0 0 55 65',
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.9053 0H3.64394C1.63141 0 0 1.61671 0 3.61107V61.3889C0 63.3833 1.63141 65 3.64394 65H27.3295H51.0152C53.0277 65 54.6591 63.3833 54.6591 61.3889V14.4445L41.9053 0ZM48.2229 12.6389L43.7273 7.54731V10.8333C43.7273 11.8305 44.543 12.6389 45.5492 12.6389H48.2229ZM40.0833 3.61107H3.64394V61.3889H51.0152V16.25H45.5492C42.5305 16.25 40.0833 13.8248 40.0833 10.8333V3.61107Z"
        fill="#4E81BA"
      />
      <path d="M12.5566 36.1931L23.6362 47.2727L42.8407 28.8068" stroke="#4E81BA" strokeWidth="4" fill="transparent" />
    </>
  ),
})

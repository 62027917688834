import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Simple',
  viewBox: '0 0 100 100',
  path: (
    <>
      <path
        d="M27.2 40.2H27.1L16.9 39.1C16.4 39 16 38.6 16 38C16.1 37.5 16.5 37 17.1 37.1L27.3 38.2C27.8 38.3 28.2 38.7 28.2 39.3C28.1 39.8 27.7 40.2 27.2 40.2Z"
        fill="#FF6C4A"
      />
      <path
        d="M39.5 28.1C39 28.1 38.6 27.7 38.5 27.2L37.6 17.3C37.6 16.8 38 16.3 38.5 16.2C39.1 16.1 39.5 16.6 39.6 17.1L40.5 27C40.5 27.5 40.1 28 39.5 28.1Z"
        fill="#FF6C4A"
      />
      <path
        d="M29 30.4C28.7 30.4 28.4 30.3 28.3 30.1L21.7 22.6C21.3 22.2 21.4 21.6 21.8 21.2C22.2 20.8 22.8 20.9 23.2 21.3L29.8 28.8C30.2 29.2 30.1 29.8 29.7 30.2C29.5 30.3 29.2 30.4 29 30.4Z"
        fill="#FF6C4A"
      />
      <path
        d="M74.7 83.6C74.2 83.6 73.8 83.4 73.4 83.1L61.1 70.7L53.3 78.6C52.8 79.1 52.2 79.2 51.6 79.1C51 79 50.5 78.5 50.3 77.9L37.6 40C37.4 39.3 37.5 38.6 38 38.1C38.5 37.6 39.2 37.4 39.9 37.7L77.8 50.3C78.4 50.5 78.9 51 79 51.6C79.1 52.2 79 52.9 78.5 53.3L70.6 61.1L83 73.5C83.3 73.8 83.5 74.3 83.5 74.8C83.5 75.3 83.3 75.7 83 76.1L76 83.1C75.6 83.4 75.1 83.6 74.7 83.6ZM61.1 68.7C61.6 68.7 62 68.9 62.4 69.2L74.7 81.5L81.4 74.8L69.1 62.5C68.8 62.2 68.6 61.7 68.6 61.2C68.6 60.7 68.8 60.3 69.1 59.9L76.9 52.1L39.6 39.7L52 77L59.8 69.2C60.1 68.9 60.6 68.7 61.1 68.7Z"
        fill="#2A2A2A"
      />
    </>
  ),
})

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'RoundedQuote',
  viewBox: '0 0 35 24',
  path: (
    <>
      <path
        d="M33.04 8.48C33.52 7.44 34.08 6.4 34.08 5.28C34.08 2.4 31.44 0 28.56 0C27.28 0 24.96 0.879997 23.92 2.72L17.28 14.72C16.8 15.76 16.32 16.8 16.32 17.92C16.32 20.8 18.88 23.2 21.76 23.2C23.04 23.2 25.36 22.32 26.32 20.48L33.04 8.48ZM16.72 8.48C17.2 7.44 17.76 6.4 17.76 5.28C17.76 2.4 15.12 0 12.24 0C10.96 0 8.64 0.879997 7.6 2.72L0.96 14.72C0.48 15.76 0 16.8 0 17.92C0 20.8 2.56 23.2 5.44 23.2C6.72 23.2 9.04 22.32 10 20.48L16.72 8.48Z"
        fill="currentColor"
      />
    </>
  ),
})

import { Flex, FlexProps } from '@chakra-ui/react'
import { forwardRef } from 'react'

const FullWidthContainer = forwardRef<HTMLDivElement, FlexProps>((props, ref) => {
  return <Flex w="100%" direction="column" align="center" ref={ref} {...props} />
})

FullWidthContainer.displayName = 'FullWidthContainer'

export default FullWidthContainer

import { BoxProps, Flex, type IconProps } from '@chakra-ui/react'
import { FC, useRef, useState } from 'react'
import Slider from 'react-slick'

import ConditionalWrapper from '../ConditionalWrapper'
import { BoxedContainer } from '../Containers'
import { ChevronLeftFillCircle, ChevronRightFillCircle } from '../icons'

import { default as Nav } from './Nav'

interface Props<T> {
  data?: T[]
  SlideComponent: FC<T>
  itemsPerPage?: number | undefined
  slidesToScroll?: number
  boxedControls?: boolean
  hasDots?: boolean
  centerMode?: boolean
  infinite?: boolean
  draggable?: boolean
  variableWidth?: boolean
  showControls?: boolean
  centerPadding?: string
  navBottom?: boolean
  centeredControls?: boolean
  ArrowLeft?: FC<IconProps>
  ArrowRight?: FC<IconProps>
  isDark?: boolean
  navColor?: string
  withArrow?: boolean
}

const Carousel = <T,>({
  data,
  hasDots,
  infinite = true,
  isDark,
  navColor,
  SlideComponent,
  itemsPerPage = 1,
  slidesToScroll = 1,
  centeredControls = false,
  boxedControls,
  draggable,
  centerMode = false,
  variableWidth = false,
  showControls = true,
  centerPadding = '50px',
  navBottom = false,
  ArrowLeft = ChevronLeftFillCircle,
  ArrowRight = ChevronRightFillCircle,
  withArrow = true,
  ...props
}: Props<T> & BoxProps) => {
  const sliderRef = useRef<Slider>(null)
  const noSlider = itemsPerPage >= (data?.length ?? 0)
  const [activeSlide, setActiveSlide] = useState(0)

  return (
    <Flex width="100%" justifyContent="center" direction={navBottom ? 'column-reverse' : 'column'} {...props}>
      {showControls && !noSlider && (
        <ConditionalWrapper
          condition={!!boxedControls}
          wrap={(children) => (
            // BoxedContainer positions itself to the center of the container
            <BoxedContainer
              alignSelf="center"
              alignItems={{ base: navBottom ? 'center' : 'flex-end', md: 'flex-end' }}
              py={undefined}
            >
              {children}
            </BoxedContainer>
          )}
        >
          <Nav
            sliderRef={sliderRef}
            centeredControls={centeredControls}
            ArrowLeft={ArrowLeft}
            ArrowRight={ArrowRight}
            isDark={isDark}
            navColor={navColor}
            activeSlide={activeSlide}
            totalSlide={data?.length}
            mb={navBottom ? 0 : 6}
            mt={navBottom ? 6 : 0}
            withArrow={withArrow}
          />
        </ConditionalWrapper>
      )}
      {noSlider ? (
        <Flex
          direction="row"
          alignItems="center"
          sx={{
            '>div': {
              flex: '1 1 0%',
            },
          }}
        >
          {data?.map((d: T, i: number) => (
            // data must fit with the required props of your SlideComponent
            <SlideComponent key={i} {...d} isLight />
          ))}
        </Flex>
      ) : (
        <Slider
          centerMode={centerMode}
          arrows={false}
          ref={sliderRef}
          infinite={infinite}
          dots={hasDots}
          slidesToShow={itemsPerPage}
          slidesToScroll={slidesToScroll || itemsPerPage}
          variableWidth={variableWidth}
          draggable={draggable}
          centerPadding={centerPadding}
          beforeChange={(current, next) => setActiveSlide(next)}
        >
          {data?.map((d: T, i: number) => (
            // data must fit with the required props of your SlideComponent
            <SlideComponent key={i} {...d} isLight />
          ))}
        </Slider>
      )}
    </Flex>
  )
}

export default Carousel

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'AgroAlimentaire',
  viewBox: '0 0 100 100',
  path: (
    <>
      <path
        d="M84.5 60.9C84.4001 60.2 84.1001 59.5 83.9001 58.8C83.8001 58.5 83.6001 58.1 83.4001 57.8C83.4001 57.7 83.3001 57.6 83.3001 57.6C83.3001 57.5 83.2 57.5 83.2 57.4C82.8 56.5 82.3 56.5 82.1 56.5C81.7 56.5 81.4 56.6 81.2 56.9C81 57.2 80.7001 57.5 80.5 57.8C80.3 58 80.1001 58.2 79.9001 58.4C79.4001 58.9 78.8 59.2 78.1 59.2C76.8 59.3 75.4 58.5 74.5 57.1C74.1 56.5 73.9 55.7 74.1 55.1C74.2 54.9 74.3001 54.7 74.4001 54.4C74.6001 53.9 74.4001 53.4 73.9001 53.2C73.5001 53 72.9 53.1 72.6 53.5C72.4 53.7 72.3001 54 72.2001 54.2C71.6 55 70.5 55.4 69.7001 55C68.6 54.6 68 53.3 68.3 52.2C68.5 51.7 68.2001 51.2 67.7001 51C67.2001 50.8 66.6001 51 66.4001 51.5C66.3001 51.8 66 52 65.6 52.2C64.9 52.4 64 52.3 63.3 51.9C62.6 51.5 62.1 50.9 61.8 50.3C61.6 49.7 61.5 49 61.6 48C61.7 46.6 61.8 46.1 61.8 45.9C61.9 45.5 61.7001 45.1 61.4001 44.9C60.3001 44.2 59.5 44.4 58.8 44.5C58.7 44.5 58.5001 44.6 58.4001 44.6C55.0001 45.2 52.2 46.4 50 48.2C49.4 48.7 48.9001 49.2 48.4001 49.7C47.7001 50.5 47.1 51.2 46.5 52.1V56.5C46.6 56.3 46.6001 56.1 46.7001 55.9C47.2001 54.8 47.8001 53.8 48.4001 52.9C49.2001 51.7 50.2 50.7 51.3 49.8C53.3 48.2 55.7 47.2 58.8 46.6C59 46.6 59.1 46.5 59.3 46.5C59.5 46.5 59.7 46.4 59.8 46.4C59.8 46.8 59.7001 47.2 59.7001 47.8C59.6 48.6 59.6 49.9 60.1 51C60.5 52 61.4001 52.9 62.4001 53.5C63.6001 54.2 65.1 54.4 66.3 54C66.4 54 66.4 54 66.5 53.9C66.9 55.2 67.8 56.3 69.1 56.8C70.1 57.2 71.3001 57.1 72.2001 56.6C72.3001 57.1 72.6 57.6 72.8 58C74 60 76.1 61.2 78.2001 61C79.3 60.9 80.4001 60.4 81.3001 59.6C81.5001 59.4 81.7001 59.2 81.9001 59C81.9001 59.1 82 59.2 82 59.3C82.2001 59.8 82.4001 60.4 82.5 61C82.7001 62.2 82.8 63.4 82.6 64.7C82.6 64.8 82.6 65 82.5 65.1C81.2001 73.3 74.8 80.3 67.1 81.8C60.1 83.2 52.4 80 48.3 74.3C48.1 74 47.9001 73.7 47.7001 73.4C47.2001 72.5 46.9 71.6 46.5 70.7V75C47.1 75.9 47.6 76.8 48.3 77.5C52.2 81.7 57.9001 84.2 63.7001 84.2C65 84.2 66.2001 84.1 67.5 83.8C76.1 82.1 83 74.6 84.5 65.4C84.5 65.2 84.5 65.1 84.6 64.9C84.9 63.8 84.8 62.3 84.5 60.9Z"
        fill="#FF6C4A"
      />
      <path
        d="M52.8 62.8C53.1 62.9 53.4001 62.9 53.7001 62.9C54.4001 62.9 55 62.8 55.5 62.5C56.5 62 57.2001 61.1 57.4001 60.1C57.6001 59 57.1 57.8 56.1 57.1C55.1 56.3 53.9 56.1 52.8 56.4C51.7 56.7 50.7001 57.6 50.2001 58.8C49.9001 59.7 49.9 60.6 50.3 61.3C50.7 62.1 51.6 62.6 52.8 62.8ZM52.1 59.5C52.4 58.9 52.8001 58.4 53.4001 58.3C53.9001 58.2 54.5 58.3 55 58.7C55.4 59 55.6 59.5 55.5 59.8C55.4 60.2 55.1 60.5 54.7001 60.7C54.2001 60.9 53.6 60.9 53.1 60.8C52.6 60.7 52.2 60.5 52.1 60.3C51.9 60.1 52 59.8 52.1 59.5Z"
        fill="#FF6C4A"
      />
      <path
        d="M56.2001 69.6C55.7001 69.8 55.2001 70.1 54.9001 70.5C54.5001 71 54.3001 71.6 54.4001 72.3C54.5001 73.3 55.2001 74.2 56.0001 74.6C56.1001 74.7 56.3001 74.7 56.4001 74.8C56.6001 74.9 56.8001 74.9 57.0001 74.9C57.7001 74.9 58.3001 74.7 58.9001 74.2C59.6001 73.6 60.0001 72.8 59.9001 71.9C59.8001 71.1 59.4001 70.3 58.7001 69.9C57.7001 69.2 56.6001 69.5 56.2001 69.6ZM57.6 71.5C57.8 71.6 57.9001 71.8 57.9001 72C57.9001 72.2 57.8 72.4 57.6 72.6C57.4 72.8 57.1 72.9 56.8 72.8C56.6 72.7 56.4001 72.4 56.3 72.1C56.3 72 56.3001 71.9 56.4001 71.8C56.5001 71.7 56.6001 71.6 56.7001 71.6C57.0001 71.4 57.4 71.4 57.6 71.5Z"
        fill="#FF6C4A"
      />
      <path
        d="M66.9001 67.7C68.0001 68.1 69.3 68.6 70.6 68.9C71.3 69.1 72 69.2 72.7001 69.2H72.9001C73.3001 69.2 73.8 69.3 74.3 69.3C74.4 69.3 74.6 69.3 74.7001 69.3C75.2001 69.3 75.7 69.1 76.1 68.8C76.5 68.5 76.9 67.9 77 67.3C77.1 66.8 77.1 66.3 77.1 65.8V65.5C77.1 64.1 76.4001 62.9 75.2001 62.5C74.1 62.1 73 62.4 72.2001 62.7C71.8001 62.8 71.4 62.9 71 62.9C70.9 62.9 70.7001 62.9 70.4001 62.8C70.1001 62.7 69.8 62.7 69.5 62.7H69.3C67.9 62.7 66.6001 63.4 65.9001 64.4C65.6001 64.9 65.4001 65.3 65.4001 65.8C65.4001 66.7 66.0001 67.4 66.9001 67.7ZM67.5 65.8C67.9 65.2 68.6001 64.9 69.4001 64.9H69.5C69.7001 64.9 69.8 64.9 70 65C70.3 65.1 70.6 65.1 71 65.1C71.6 65.1 72.2 64.9 72.8 64.8C73.4 64.6 74.1 64.4 74.6 64.6C75 64.8 75.2001 65.3 75.2001 65.7V66C75.2001 66.4 75.2 66.7 75.1 67C75.1 67.2 75.0001 67.3 74.9001 67.4C74.8001 67.4 74.7 67.5 74.6 67.5C74.2 67.5 73.6 67.5 73.1 67.4H72.9001C72.3001 67.3 71.7 67.2 71.1 67.1C70 66.9 69 66.5 67.7001 66C67.5 65.9 67.5 65.8 67.5 65.8Z"
        fill="#FF6C4A"
      />
      <path
        d="M70.9001 74.6C70.5001 74.5 70.2 74.4 69.8 74.3C68.5 74.1 67.4 74.5 66.5 75.2C66.4 75.3 66.4 75.3 66.3 75.4C65.9 76 65.2001 77 65.7001 78.1C66.1 78.9 67.3001 79.5 68.4001 79.5C68.7001 79.5 69 79.5 69.3 79.4C70.2 79.1 70.6 78.6 70.8 78.3L71.7001 77.2C71.9001 77 72.4 76.3 72 75.5C71.8 75 71.3001 74.8 70.9001 74.6ZM69.3 77.1C69.1 77.4 69 77.5 68.8 77.5C68.3 77.7 67.7 77.4 67.6 77.3C67.6 77.2 67.8001 76.9 67.9001 76.7C68.5001 76.3 69.2001 76.3 69.5 76.4C69.6 76.4 69.7001 76.4 69.9001 76.5L69.3 77.1Z"
        fill="#FF6C4A"
      />
      <path
        d="M44 28.8C44 28.8 43.9 28.8 44 28.8C42.2 27 41.1 24.6 41 22H41.9C43.3 22 44.4 20.8 44.4 19.4V12.6C44.4 11.2 43.3 10 41.9 10H21.6C20.2 10 19.1 11.2 19.1 12.6V19.5C19.1 20.9 20.2 22.1 21.6 22.1H22.5C22.3 24.6 21.3 27 19.5 28.8L19.4 28.9C16.6 31.7 15 35.7 15 39.9V79.9C15 85.5 19.4 90.1 24.7 90.1H38.7C44.1 90.1 48.4 85.5 48.4 79.9V77.8V74.6V53.1V49.9V39.9C48.5 35.7 46.8 31.7 44 28.8ZM46.5 75.3V79.9C46.5 84.4 43 88.1 38.8 88.1H24.8C20.5 88.1 17.1 84.4 17.1 79.9V39.9C17.1 36.3 18.5 32.7 21 30.2V30.1C23.3 27.8 24.6 24.6 24.6 21.3V21C24.6 20.4 24.2 20 23.6 20H21.6C21.3 20 21.1 19.7 21.1 19.4V12.6C21.1 12.3 21.3 12 21.6 12H41.9C42.2 12 42.4 12.3 42.4 12.6V19.5C42.4 19.8 42.2 20.1 41.9 20.1H39.9C39.3 20.1 38.9 20.5 38.9 21.1V21.4C38.9 24.7 40.2 27.9 42.5 30.2H42.6C45.1 32.7 46.5 36.2 46.5 39.8V52.4V56.8V71V75.3Z"
        fill="#2A2A2A"
      />
    </>
  ),
})

import { Flex, type FlexProps } from '@chakra-ui/react'
import { FC } from 'react'

type Props = Omit<FlexProps, 'minW' | 'maxW' | 'minWidth' | 'maxWidth'> & { minW?: string; maxW?: string }

const BoxedContainer: FC<Props> = ({ minW, maxW, ...restprops }) => {
  return (
    <Flex
      direction="column"
      align="center"
      px={{ base: 5, md: 'inherit' }}
      py={{ base: 12, md: 16 }}
      minW={['100%', `min(90%, ${minW ?? '1320px'})`]}
      maxW={['100%', `min(90%, ${maxW ?? '1320px'})`]}
      {...restprops}
    />
  )
}

export default BoxedContainer

import { Text, Flex, BoxProps, useDisclosure } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
// import NextLink from 'next/link'
import { FC } from 'react'

import { useMountedBreakpoints } from '@miimosa/design-system'

import Tag from '../Tag'

import { getTotalFilters } from '@components/projects/QueryFilter'

import FilterDrawer from './FilterDrawer'

type Filter = {
  label: string
  value: string
}

interface Props {
  filters: { [id: string]: string[] }
  filtersData: { id: string; title: string; filters: Filter[]; isRadio: boolean }[]
  totalResults?: number
  removeFilter: (cat: string, value: string) => void
  addFilter: (cat: string, value: string) => void
  addRadioFilter: (cat: string, value: string) => void
  clearFilters: () => void
  showResults?: boolean
}

const QueryFilter: FC<Props & BoxProps> = ({
  filtersData,
  filters,
  addFilter,
  addRadioFilter,
  removeFilter,
  clearFilters,
  totalResults = 0,
  showResults = true,
  ...rest
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { t } = useTranslation('common', { keyPrefix: 'queryFilter' })
  const isMobile = useMountedBreakpoints({ base: true, md: false }, false)
  const totalFilters = getTotalFilters(filters)
  const formattedTotalResults = new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 0 }).format(totalResults)

  return (
    <Flex align="center" width="100%" justify="space-between" {...rest}>
      <Flex align="center">
        <Tag
          title={t(isMobile ? 'filters_and_sort' : 'filters', { count: totalFilters })}
          onClick={onOpen}
          round
          {...(totalFilters && { onClear: clearFilters })}
          bgColor="green"
          textColor="white"
          textSize="sm"
        />
        {showResults && (
          <Text size="xs" ml="4">
            {t('results_other', { var: formattedTotalResults })}
          </Text>
        )}
      </Flex>

      <FilterDrawer
        filters={filters}
        filtersData={filtersData}
        isOpen={isOpen}
        onClose={onClose}
        addFilter={addFilter}
        addRadioFilter={addRadioFilter}
        removeFilter={removeFilter}
      />
    </Flex>
  )
}

export default QueryFilter

import { useRouter } from 'next/router'
import { useState } from 'react'

const useFilter = (formattedFilters: Record<string, string[]>) => {
  const [filters, setFilters] = useState<{
    [id: string]: string[]
  }>(formattedFilters)

  const { pathname, push } = useRouter()
  const url = { pathname }

  return {
    filters,
    addFilter: (categoryId: string, newFilter: string) => {
      setFilters({ ...filters, [categoryId]: [...filters[categoryId], newFilter] })
    },
    removeFilter: (categoryId: string, filter: string) =>
      setFilters({ ...filters, [categoryId]: filters[categoryId]?.filter((item) => item !== filter) }),
    addRadioFilter: (categoryId: string, newFilter: string) => {
      setFilters({ ...filters, [categoryId]: [newFilter] })
    },
    clearFilters: () => {
      const flushedParams = Object.entries(filters)
        .map(([catId]) => [catId, []])
        .reduce((acc, [cat, data]) => {
          return { ...acc, ...{ [cat as string]: data } }
        }, {})

      setFilters(flushedParams)
      push(url)
    },
  }
}

export const getTotalFilters = (filters: { [id: string]: string[] }): number =>
  Object.values(filters)?.reduce((acc, filter) => acc + filter?.length, 0)

export default useFilter

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Time',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d="M13.0001 2.82996H12.7401C12.6091 2.8375 12.4859 2.89425 12.395 2.98883C12.3041 3.0834 12.2524 3.20882 12.2501 3.33996C12.2513 3.40649 12.2659 3.4721 12.2928 3.53293C12.3198 3.59377 12.3586 3.64862 12.4071 3.69425C12.4555 3.73989 12.5125 3.7754 12.5749 3.7987C12.6372 3.822 12.7036 3.83263 12.7701 3.82996H13.0001C14.6138 3.83983 16.1884 4.32738 17.5254 5.23109C18.8623 6.1348 19.9017 7.41421 20.5124 8.90792C21.1231 10.4016 21.2778 12.0428 20.9569 13.6243C20.6361 15.2058 19.854 16.6569 18.7095 17.7945C17.5649 18.9321 16.1091 19.7052 14.5256 20.0164C12.9422 20.3276 11.3021 20.163 9.81209 19.5431C8.32213 18.9233 7.04909 17.8762 6.15355 16.5337C5.25801 15.1913 4.78009 13.6137 4.78006 12C4.78119 11.1508 4.91618 10.3071 5.18006 9.49996L6.27006 11.5C6.31237 11.5796 6.37584 11.646 6.45347 11.6919C6.53111 11.7377 6.61989 11.7613 6.71006 11.76C6.79066 11.7605 6.86997 11.7398 6.94006 11.7C7.00254 11.6707 7.05815 11.6286 7.10324 11.5764C7.14832 11.5242 7.18188 11.463 7.2017 11.3969C7.22152 11.3309 7.22717 11.2613 7.21827 11.1929C7.20937 11.1245 7.18613 11.0588 7.15006 11L5.60006 8.15996C5.56928 8.10145 5.52674 8.04993 5.47512 8.00863C5.42349 7.96733 5.36389 7.93714 5.30006 7.91996C5.24037 7.89289 5.17559 7.87888 5.11006 7.87888C5.04452 7.87888 4.97974 7.89289 4.92006 7.91996L2.09006 9.47996C1.97336 9.54361 1.88673 9.65101 1.84922 9.77853C1.81172 9.90606 1.8264 10.0433 1.89006 10.16C1.95371 10.2767 2.06111 10.3633 2.18864 10.4008C2.31616 10.4383 2.45336 10.4236 2.57006 10.36L4.12006 9.51996C3.89655 10.3277 3.7822 11.1618 3.78006 12C3.78008 13.8115 4.31666 15.5824 5.32208 17.0893C6.3275 18.5962 7.75671 19.7715 9.4294 20.467C11.1021 21.1625 12.9433 21.347 14.7207 20.9972C16.4982 20.6475 18.1322 19.779 19.4166 18.5016C20.701 17.2241 21.5783 15.5949 21.9377 13.8194C22.2972 12.0439 22.1227 10.2017 21.4363 8.52526C20.7499 6.84881 19.5823 5.41323 18.0809 4.39963C16.5795 3.38603 14.8116 2.83983 13.0001 2.82996Z"
        fill="currentColor"
      />
      <path
        d="M12.6801 13.42H18.1801C18.3127 13.42 18.4398 13.3673 18.5336 13.2735C18.6274 13.1797 18.6801 13.0526 18.6801 12.92C18.6775 12.7881 18.624 12.6625 18.5308 12.5692C18.4376 12.476 18.3119 12.4225 18.1801 12.42H13.1801V7.41995C13.1801 7.28734 13.1274 7.16017 13.0336 7.0664C12.9398 6.97263 12.8127 6.91995 12.6801 6.91995C12.5475 6.91995 12.4203 6.97263 12.3265 7.0664C12.2327 7.16017 12.1801 7.28734 12.1801 7.41995V12.91C12.1787 12.9764 12.1907 13.0425 12.2152 13.1044C12.2397 13.1662 12.2764 13.2225 12.3229 13.27C12.3695 13.3175 12.4251 13.3552 12.4864 13.381C12.5477 13.4067 12.6136 13.42 12.6801 13.42Z"
        fill="currentColor"
      />
    </>
  ),
})

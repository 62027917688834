import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'HeartFillNestle',
  viewBox: '0 0 20 19',
  path: (
    <>
      <path
        d="M5.36582 0C2.04051 0 0 2.90796 0 5.29337C0 10.451 5.86225 15.2383 10 18.2857C14.1379 15.2381 20 10.4505 20 5.29337C20 2.90816 17.9594 0 14.6342 0C12.7771 0 11.1905 1.48061 10.0001 2.88724C8.8097 1.48061 7.22306 0 5.36602 0H5.36582Z"
        fill="currentColor"
      />
    </>
  ),
})

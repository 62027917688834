import { Button, Checkbox, Flex, Heading, Input, Text, Spinner } from '@chakra-ui/react'
import { useState } from 'react'
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { StylesConfig } from 'react-select'

import { s3ImageURL } from '@miimosa/common'
import { Select } from '@miimosa/design-system/components'
import { useMountedBreakpoints } from '@miimosa/design-system/lib/hooks'

import Image from '@components/Image'

const REGIONS = [
  {
    value: '4',
    label: 'Auvergne-Rhône-Alpes',
  },
  {
    value: '5',
    label: 'Bourgogne Franche-Comté',
  },
  {
    value: '6',
    label: 'Bretagne',
  },
  {
    value: '7',
    label: 'Centre-Val de Loire',
  },
  {
    value: '8',
    label: 'Corse',
  },
  {
    value: '25',
    label: 'DROM-COM',
  },
  {
    value: '2',
    label: 'Grand Est',
  },
  {
    value: '1',
    label: 'Hauts-de-France',
  },
  {
    value: '11',
    label: 'Normandie',
  },
  {
    value: '3',
    label: 'Nouvelle-Aquitaine',
  },
  {
    value: '10',
    label: 'Occitanie',
  },
  {
    value: '13',
    label: 'Pays de la Loire',
  },
  {
    value: '12',
    label: "Provence-Alpes-Côte d'Azur",
  },
  {
    value: '9',
    label: 'Île-de-France',
  },
]

type TUseReactSelectStyles = () => StylesConfig<unknown, boolean>
const customStyles = {
  control: (style: TUseReactSelectStyles) => ({
    ...style,
    cursor: 'pointer',
    border: '0px',
    borderRadius: '4px',
    boxShadow: 'none',
    height: 'auto',
    minHeight: 'auto',
  }),
  menu: (style: TUseReactSelectStyles) => ({
    ...style,
    borderRadius: 0,
    padding: 0,
    margin: 0,
    boxShadow: 'none',
  }),
  input: (style: TUseReactSelectStyles) => ({
    ...style,
    paddingLeft: 0,
    paddingRight: 0,
  }),
  placeholder: (style: TUseReactSelectStyles) => ({
    ...style,
    color: '#2A2A2A99',
    fontWeight: '400',
  }),
  option: (style: TUseReactSelectStyles, { isSelected }: { isSelected: boolean }) => ({
    ...style,
    color: 'black',
    fontWeight: isSelected ? 'bold' : '400',
    textAlign: 'left',
    backgroundColor: 'transparent',
    '&:hover': {
      cursor: 'pointer',
      color: 'hover',
      backgroundColor: 'transparent',
    },
    label: {
      cursor: 'pointer',
    },
  }),
  valueContainer: (style: TUseReactSelectStyles) => ({
    ...style,
    padding: 0,
  }),
  menuList: (style: TUseReactSelectStyles) => ({
    ...style,
    marginTop: '8px',
    border: '1px solid black',
    borderRadius: '5px',
    boxShadow: 'none',
  }),
  singleValue: (style: TUseReactSelectStyles) => ({
    ...style,
    color: 'black',
    cursor: 'pointer',
  }),
}

type FormValues = {
  email: string
  firstname: string
  lastname: string
  region: { value: string; label: string }
  phone: string
  projectTotalBudget: string
  projectDescription: string
  cgu: boolean
}

const Form = () => {
  const isMobile = useMountedBreakpoints({ base: true, lg: false }, false)
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>()

  const [submittedText, setSubmittedText] = useState({ text: '', color: 'orange', success: false })
  const [spinner, setSpinner] = useState(false)

  const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
    setSpinner(true)
    const response = await fetch('/nextapi/leads', {
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify({
        email: data.email,
        firstname: data.firstname,
        lastname: data.lastname,
        region: data.region.value,
        phone: data.phone,
        projectTotalBudget: data.projectTotalBudget,
        projectDescription: data.projectDescription,
      }),
    })

    if (response.status === 200) {
      setSpinner(false)
      setSubmittedText({ text: 'Merci pour votre participation !', color: 'green', success: true })
    } else {
      setSpinner(false)
      setSubmittedText({
        text: 'Un problème est survenu, veuillez contacter notre service client.',
        color: 'orange',
        success: false,
      })
    }
  }
  const methods = useForm({ shouldUnregister: true })

  return (
    <Flex direction="row" w="full" columnGap={8}>
      {!isMobile && (
        <Flex w="50%" h="800px" position="relative">
          <Image src={s3ImageURL('form-carrot.png')} fill style={{ objectFit: 'cover' }} alt="smiling picture" />
        </Flex>
      )}
      <Flex
        direction="column"
        w={{ base: 'full', lg: '50%' }}
        bgColor="white"
        alignItems="flex-start"
        justifyContent="center"
        p={8}
      >
        <Flex w="full" bg="pink" h="209px" justifyContent="center" alignItems="center">
          <Flex w="534px" h="387px" position="relative">
            <Image src={s3ImageURL('CNA-logo.png')} fill style={{ objectFit: 'cover' }} alt="logo cultivons" />
          </Flex>
        </Flex>
        <FormProvider {...methods}>
          <Flex
            as="form"
            direction="column"
            w="full"
            alignItems="center"
            my="auto"
            onSubmit={handleSubmit(onSubmit)}
            rowGap={8}
          >
            <Heading size="sm">Proposez votre projet</Heading>
            <Flex
              direction={{ base: 'column', md: 'row' }}
              w="full"
              columnGap={{ base: 0, md: 8 }}
              rowGap={{ base: 6, md: 0 }}
            >
              <Flex
                direction="column"
                w="full"
                borderBottom="1px solid main_grey"
                pb={2}
                _focusWithin={{ borderBottom: '1px solid black' }}
              >
                <Input
                  type="text"
                  placeholder="Nom"
                  variant="unstyled"
                  _placeholder={{ color: 'main_grey' }}
                  {...register('lastname', { required: 'Ce champ est obligatoire' })}
                />
                <Text color="orange" size="xs">
                  {errors['lastname']?.message}
                </Text>
              </Flex>
              <Flex
                direction="column"
                w="full"
                borderBottom="1px solid main_grey"
                pb={2}
                _focusWithin={{ borderBottom: '1px solid black' }}
              >
                <Input
                  type="text"
                  placeholder="Prénom"
                  variant="unstyled"
                  _placeholder={{ color: 'main_grey' }}
                  {...register('firstname', { required: 'Ce champ est obligatoire' })}
                />
                <Text color="orange" size="xs">
                  {errors['firstname']?.message}
                </Text>
              </Flex>
            </Flex>
            <Flex
              direction={{ base: 'column', md: 'row' }}
              w="full"
              columnGap={{ base: 0, md: 8 }}
              rowGap={{ base: 6, md: 0 }}
            >
              <Flex
                direction="column"
                w="full"
                borderBottom="1px solid main_grey"
                pb={2}
                _focusWithin={{ borderBottom: '1px solid black' }}
              >
                <Input
                  type="tel"
                  placeholder="Téléphone"
                  variant="unstyled"
                  _placeholder={{ color: 'main_grey' }}
                  {...register('phone', {
                    required: 'Ce champ est obligatoire',
                    pattern: {
                      value: /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/,
                      message: 'Numéro de téléphone invalide',
                    },
                  })}
                />
                <Text color="orange" size="xs">
                  {errors['phone']?.message}
                </Text>
              </Flex>
              <Flex
                direction="column"
                w="full"
                borderBottom="1px solid main_grey"
                pb={2}
                _focusWithin={{ borderBottom: '1px solid black' }}
              >
                <Input
                  type="email"
                  placeholder="Email"
                  variant="unstyled"
                  _placeholder={{ color: 'main_grey' }}
                  {...register('email', { required: 'Ce champ est obligatoire' })}
                />
                <Text color="orange" size="xs">
                  {errors['email']?.message}
                </Text>
              </Flex>
            </Flex>
            <Flex
              direction={{ base: 'column', md: 'row' }}
              w="full"
              columnGap={{ base: 0, md: 8 }}
              rowGap={{ base: 6, md: 0 }}
            >
              <Flex
                direction="column"
                w="full"
                borderBottom="1px solid main_grey"
                _focusWithin={{ borderBottom: '1px solid black' }}
              >
                <Controller
                  name="region"
                  rules={{ required: 'Ce champ est obligatoire' }}
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        width="full"
                        customStyles={customStyles}
                        data={REGIONS}
                        placeholder="Votre région"
                      />
                    )
                  }}
                />
                <Text color="orange" size="xs">
                  {errors['region']?.message}
                </Text>
              </Flex>
              <Flex
                direction="column"
                w="full"
                borderBottom="1px solid main_grey"
                pb={2}
                _focusWithin={{ borderBottom: '1px solid black' }}
              >
                <Input
                  type="number"
                  placeholder="Votre besoin en financement (€)"
                  variant="unstyled"
                  _placeholder={{ color: 'main_grey' }}
                  {...register('projectTotalBudget', {
                    required: 'Ce champ est obligatoire',
                    min: { value: 1000, message: 'Le montant minimum nécessaire est de 1000 euros' },
                  })}
                />
                <Text color="orange" size="xs">
                  {errors['projectTotalBudget']?.message}
                </Text>
              </Flex>
            </Flex>
            <Flex
              direction="column"
              w="full"
              borderBottom="1px solid main_grey"
              pb={2}
              _focusWithin={{ borderBottom: '1px solid black' }}
            >
              <Input
                type="text"
                placeholder="Décrivez votre projet en quelques mots"
                w="full"
                variant="unstyled"
                _placeholder={{ color: 'main_grey' }}
                {...register('projectDescription', {
                  required: 'Ce champ est obligatoire',
                  maxLength: { value: 500, message: '500 caractères maximum' },
                })}
              />
              <Text color="orange" size="xs">
                {errors['projectDescription']?.message}
              </Text>
            </Flex>
            <Flex direction="column">
              <Flex direction="row">
                <Checkbox
                  colorScheme="checkbox"
                  borderColor="green"
                  mr={2}
                  defaultChecked
                  {...register('cgu', {
                    required: 'Ce champ est obligatoire',
                  })}
                />
                <Text size="sm" color="main_grey">
                  J’ai compris que l’obtention des avantages est conditionnée à la réussite de ma collecte de
                  financement participatif sur MiiMOSA et au fait d’être sélectionné projet lauréat ( voir FAQ pour plus
                  de détails).
                </Text>
              </Flex>
              <Text color="orange" size="xs">
                {errors['cgu']?.message}
              </Text>
            </Flex>
            {spinner ? (
              <Spinner />
            ) : (
              submittedText.success === false && (
                <Button variant="primary" size="md" type="submit" mx="auto" w={{ base: 'full', md: 'auto' }}>
                  Envoyer le projet
                </Button>
              )
            )}
          </Flex>
          <Flex direction="column" w="full" justifyContent="center" alignItems="center">
            <Text
              size="lg"
              w="full"
              textAlign="center"
              display={submittedText.text ? 'block' : 'none'}
              color={submittedText.color}
            >
              {submittedText.text}
            </Text>
          </Flex>
        </FormProvider>
      </Flex>
    </Flex>
  )
}

export default Form

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'TadaTopLeft',
  viewBox: '0 0 48 48',
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 8.99998C30.8 7.19998 29.8 5.59998 28.8 3.59998C30 4.99998 30.8 6.19998 31.6 7.59998C34 10.8 37.2 13.6 40.2 16.6C40.6 17.2 41.8 17.2 41.6 18.2C41.4 19.2 40.8 19.2 40.4 19.2C40.4 19.6 40.4 20.2 39.8 20C39 19.8 38.6 19 38 18.4C36.2 16.6 34.6 14.4 32.8 12.4C30.4 9.79997 28.6 6.79998 26.8 3.59998C28 3.59998 28 4.19998 28.2 4.79998C28.4 5.19998 28.8 5.39998 29 5.79998C29.4 6.19998 29.6 6.79998 30 7.19998C29.6 6.39998 29 5.59998 28.6 4.79998C28.4 4.59998 28.4 3.79998 28.6 4.19998C30 5.59998 30.8 7.39998 32 8.99998Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2 36.8C12.2 36 10.4 35.2 8.59998 34.2C10.2 34.6 11.8 35.2 13.2 35.8C17 37.2 21.2 37.8 25.2 38.6C25.8 38.8 26.8 38.2 27.2 39.2C27.6 40 27 40.4 26.8 40.6C27 40.8 27.2 41.4 26.8 41.6C26 41.8 25.2 41.4 24.6 41.2C22 40.6 19.6 39.8 17 39.2C13.6 38.4 10.4 37 7.19998 35.2C7.79998 34.6 8.19998 35.2 8.59998 35.6C8.99998 35.8 9.39998 35.8 9.79998 36C10.4 36.2 10.8 36.4 11.4 36.6C10.6 36.2 9.79998 35.8 8.79998 35.4C8.59998 35.4 7.99998 34.6 8.39998 34.8C10.6 35 12.4 36 14.2 36.8Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4 19C15.8 17.6 14.4 16.2 13 14.6C14.4 15.6 15.6 16.6 16.8 17.6C20.4 20.6 22.6 21.2 26.2 23.2C26.8 23.6 28 23.4 28 24.4C28 25.4 27.4 25.6 27.2 25.6C27.4 26 27.4 26.4 26.8 26.4C26 26.4 25.4 25.6 24.8 25.4C22.6 24 21.6 23.4 19.6 22C16.4 20 14 17.6 11.6 15C12.4 14.8 12.4 15.4 12.8 15.8C13.2 16.2 13.4 16.4 13.8 16.6C14.2 17 14.6 17.4 15.2 17.8C14.6 17.2 13.8 16.4 13.2 15.8C13 15.6 12.8 14.8 13 15.2C14.4 16.2 15.8 17.6 17.4 19Z"
        fill="currentColor"
      />
    </>
  ),
  defaultProps: {
    w: '48px',
    h: '48px',
    color: 'orange',
    top: '-13px',
    left: '-32px',
    position: 'absolute',
    zIndex: 1,
  },
})

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'OutreMer',
  viewBox: '0 0 48 48',
  path: (
    <>
      <path
        d="M19.2 25.4C19.2 25.4 19.2 25.2 19.2 25.4C19.4 25.2 19.4 25.2 19.6 25.2C20.8 24.6 20.8 22.8 22.2 22.2C24.6 21.2 28.4 22.2 29.4 24.6C29.6 25.2 29.8 25.8 30.2 26.2C32.6 27.6 32 30 31.6 32C31.2 34.4 28.8 33.8 27.6 33.6C24.8 33.2 21.8 32.4 20.6 29.4C20.2 28.6 20.2 27.6 19.6 27C19.4 26.8 19.4 26.8 19.2 26.6C19 26.4 19 26.2 19 25.8C19.2 25.6 19.2 25.4 19.2 25.4Z"
        fill="fill"
      />
      <path
        d="M18.6005 21C17.2005 20 15.6005 20.8 14.0005 21C12.4005 21.2 12.2005 20.6 12.8005 19C13.6005 16.8 15.2005 14.6 13.0005 11.8C11.4005 9.8 12.8005 5 15.2005 4.2C17.2005 3.4 19.0005 5.2 20.6005 6.2C21.6005 6.8 22.4005 7.8 23.4005 8.4C26.4005 10.6 26.4005 10.6 24.4005 13.8C23.6005 15.2 23.2005 17 22.4005 18.4C21.6005 20 20.6005 21 18.6005 21Z"
        fill="fill"
      />
      <path
        d="M12.9989 37.4C12.5989 36.2 12.5989 35.4 11.1989 35.2C8.59887 34.8 6.79887 33.4 6.79887 30.4C6.79887 29.6 6.39887 29.2 5.99887 28.8C5.19887 28 4.99887 27 5.59887 26.2C6.19887 25.4 7.19887 24.8 8.19887 25.2C11.7989 26 14.5989 28.8 15.5989 31.6C16.5989 34.2 19.1989 35.8 19.1989 38.8C19.1989 39.8 19.3989 40.8 18.7989 41.8C18.3989 42.2 18.3989 43 17.5989 42.8C16.7989 42.6 16.9989 42 17.1989 41.4C17.3989 40.6 16.7989 40.8 16.5989 40.6C14.9989 40.2 13.5989 40 11.9989 40C11.1989 40 10.3989 40.8 9.99887 39.6C9.79887 39 9.39887 38.6 9.79887 38C10.3989 37.2 11.1989 37.2 11.9989 37.6C12.1989 37.6 12.3989 37.4 12.9989 37.4Z"
        fill="fill"
      />
      <path
        d="M42.7988 32.2C42.5988 33.2 42.1988 33.6 41.5988 34.2C40.7988 35.2 39.9988 36.2 41.1988 37.4C41.7988 38 41.3988 38.4 40.9988 39C40.3988 40 39.1988 40.8 39.5988 42.4C39.5988 43 38.7988 44.2 37.5988 43.8C36.5988 43.6 35.7988 42.8 35.7988 41.8C35.7988 41 36.1988 40.6 37.3988 40.8C38.9988 41 38.1988 39.6 37.7988 39.2C36.1988 38 36.1988 36.2 35.9988 34.4C35.9988 33.6 35.9988 32.6 34.7988 32.4C33.5988 32.2 33.3988 31 33.5988 30C33.5988 29 34.7988 28.2 35.3988 27.8C36.1988 27.4 36.7988 28.6 37.1988 29.2C37.7988 30.4 38.5988 30.4 39.7988 30.4C40.9988 30.4 42.1988 31 42.7988 32.2Z"
        fill="fill"
      />
      <path
        d="M42.4007 12.6C39.6007 12.8 38.2007 15 35.8007 14C34.8007 13.6 34.4007 14.2 34.8007 15.2C35.0007 16 35.4007 16.6 35.4007 17.4C35.4007 18.8 34.6007 19.8 33.4007 20C32.2007 20.4 31.8007 19.2 31.6007 18.4C31.0007 16.8 30.4007 15 30.4007 13.2C30.2007 11.2 31.4007 11.2 33.0007 12C35.8007 13.2 36.2007 12.8 35.8007 9.6C35.8007 8.8 36.0007 8.4 36.4007 7.8C37.0007 7.4 37.8007 7.6 37.8007 8.2C37.8007 10.8 40.2007 11.2 42.4007 12.6Z"
        fill="fill"
      />
    </>
  ),
})

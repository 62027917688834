import { Link as ChakraLink, LinkProps } from '@chakra-ui/react'
import NextLink from 'next/link'

interface Props extends LinkProps {
  external?: boolean
  locale?: string
  href: string
}

const Link = ({ external, href, locale, children, target, ...props }: Props) => {
  const isHttp = href.startsWith('https://') || href.startsWith('http://')
  if (external || isHttp) {
    if (isHttp) target = target ?? '_blank'
    return (
      <ChakraLink href={href} target={target} {...props}>
        {children}
      </ChakraLink>
    )
  }
  return (
    <NextLink href={href} locale={locale} passHref legacyBehavior>
      <ChakraLink target={target} {...props}>
        {children}
      </ChakraLink>
    </NextLink>
  )
}

export default Link

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Invest',
  viewBox: '0 0 112 112',
  path: (
    <>
      <path
        d="M36.0642 73.6959H11.8722C11.5362 73.6959 11.2002 74.0319 11.2002 74.3679V100.128C11.2002 100.464 11.5362 100.8 11.8722 100.8H36.0642C36.4002 100.8 36.7362 100.464 36.7362 100.128V74.3679C36.8482 74.0319 36.5122 73.6959 36.0642 73.6959ZM34.6082 98.5599H13.4402V75.9359H34.6082V98.5599Z"
        fill="#2A2A2A"
      />
      <path
        d="M68.0962 60.2559H43.9042C43.5682 60.2559 43.2322 60.5919 43.2322 60.9279V100.128C43.2322 100.464 43.5682 100.8 43.9042 100.8H68.0962C68.4322 100.8 68.7682 100.464 68.7682 100.128V60.9279C68.7682 60.5919 68.4322 60.2559 68.0962 60.2559ZM45.4722 98.5599V62.4959H66.6402V98.5599H45.4722Z"
        fill="#2A2A2A"
      />
      <path
        d="M100.128 11.2H75.9362C75.6002 11.2 75.2642 11.536 75.2642 11.872V100.128C75.2642 100.464 75.6002 100.8 75.9362 100.8H100.128C100.464 100.8 100.8 100.464 100.8 100.128V11.872C100.8 11.536 100.464 11.2 100.128 11.2ZM98.5602 98.56H77.3922V13.44H98.5602V98.56Z"
        fill="#2A2A2A"
      />
      <path
        d="M32.1442 52.9759C20.6082 52.9759 11.3122 43.6799 11.3122 32.1439C11.3122 20.6079 20.6082 11.3119 32.1442 11.3119C43.6802 11.3119 52.9762 20.6079 52.9762 32.1439C52.9762 43.5679 43.5682 52.9759 32.1442 52.9759ZM32.1442 13.5519C21.8402 13.5519 13.5522 21.8399 13.5522 32.1439C13.5522 42.4479 21.8402 50.7359 32.1442 50.7359C42.4482 50.7359 50.7362 42.4479 50.7362 32.1439C50.7362 21.8399 42.3362 13.5519 32.1442 13.5519Z"
        fill="#2A2A2A"
      />
      <path
        d="M36.7361 24.528C35.8401 24.192 34.8321 23.968 33.8241 23.968C30.6881 23.968 27.8881 25.984 26.6561 28.784H25.2001C24.5281 28.784 24.0801 29.232 24.0801 29.904C24.0801 30.576 24.5281 31.024 25.2001 31.024H25.9841C25.9841 31.36 25.8721 31.808 25.8721 32.144C25.8721 32.48 25.8721 32.928 25.9841 33.264H25.2001C24.5281 33.264 24.0801 33.712 24.0801 34.384C24.0801 35.056 24.5281 35.504 25.2001 35.504H26.6561C27.8881 38.304 30.5761 40.32 33.8241 40.32C34.6081 40.32 35.3921 40.208 36.1761 39.984C36.7361 39.76 37.0721 39.2 36.8481 38.528C36.6241 37.968 36.0641 37.632 35.3921 37.856C34.8321 38.08 34.2721 38.08 33.7121 38.08C31.8081 38.08 30.1281 37.072 29.1201 35.504H31.4721C32.1441 35.504 32.5921 35.056 32.5921 34.384C32.5921 33.712 32.1441 33.264 31.4721 33.264H28.2241C28.1121 32.928 28.1121 32.48 28.1121 32.144C28.1121 31.808 28.1121 31.36 28.2241 31.024H31.4721C32.1441 31.024 32.5921 30.576 32.5921 29.904C32.5921 29.232 32.1441 28.784 31.4721 28.784H29.1201C30.1281 27.216 31.8081 26.208 33.7121 26.208C34.3841 26.208 35.0561 26.32 35.7281 26.656C36.2881 26.88 36.9601 26.656 37.1841 26.096C37.6321 25.424 37.2961 24.752 36.7361 24.528Z"
        fill="#FF6C4A"
      />
    </>
  ),
})

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Youtube',
  viewBox: '0 0 18 14',
  path: (
    <path
      d="M17.3848 2.86719C17.2851 2.49617 17.0898 2.15782 16.8184 1.88601C16.5469 1.61419 16.2088 1.41845 15.8379 1.31836C14.4727 0.951172 9 0.951172 9 0.951172C9 0.951172 3.52734 0.951172 2.16211 1.31641C1.79106 1.41617 1.45278 1.61181 1.18126 1.88367C0.90974 2.15554 0.71453 2.49406 0.615234 2.86523C0.25 4.23242 0.25 7.08398 0.25 7.08398C0.25 7.08398 0.25 9.93555 0.615234 11.3008C0.816406 12.0547 1.41016 12.6484 2.16211 12.8496C3.52734 13.2168 9 13.2168 9 13.2168C9 13.2168 14.4727 13.2168 15.8379 12.8496C16.5918 12.6484 17.1836 12.0547 17.3848 11.3008C17.75 9.93555 17.75 7.08398 17.75 7.08398C17.75 7.08398 17.75 4.23242 17.3848 2.86719ZM7.26172 9.70117V4.4668L11.793 7.06445L7.26172 9.70117Z"
      fill="currentColor"
    />
  ),
})
